import { action, computed, observable, toJS } from 'mobx';
import { UiService } from '../../../shared/services/ui-service';
import Catch from '../../../shared/decorators/catch-decorator';
import Loader from '../../../shared/decorators/loader-decorator';
import { errorHandler } from '../../../shared/handlers/error-handler';
import { AccountStore } from '../../account/stores/account-store';
import { AgGridService } from 'features/common/services/ag-grid-service';
import _ from 'lodash';
import { DateRange, CustomerReportResponse, EmailConfigurationResponse } from '../domains/model';
import { uiService } from 'shared/services/ui-service';
import { Customerreport } from '../domains/customer-report-col-def';
import { ReportMessage } from '../domains/enum';
import { CustomerReportDataService } from '../services/customerreport-data-service';
import moment from 'moment';
import { CommonUtils } from 'shared/services/common-utils';
import { localStorageService } from 'shared/services/local-storage-service';
import { reportticketStatus } from '../reports_constants';

export class CustomerReportStore {
    @observable customerReport: CustomerReportResponse[] = [];
    @observable emailConfiguration: EmailConfigurationResponse[] = [];
    consignorFilter: any[] = [];
    consigneeFilter: any[] = [];
    userID = 0;
    userName = '';
    startdate: any = '';
    enddate: any = '';
    startDate = moment()
        .subtract(6, 'days')
        .toISOString()
        .slice(0, 10);
    endDate = moment()
        .toISOString()
        .slice(0, 10);
    @observable dailyBackupReport: CustomerReportResponse[] = [];
    leaseoperator: any[] = [];
    consignee: any[] = [];
    leaseOperatorName: any[] = [];
    consigneeName: any[] = [];
    backupConsigneeDropdown: any[] = [];
    backupConsignorDropdown: any[] = [];
    consigneeChange: boolean = false;
    consignorChange: boolean = false;
    isMaster: boolean = false;
    fileName: any;
    data: any[] = [];
    usermail: any = '';
    @observable actVolSum: any = 0;
    actVolAvg: any = 0;
    @observable waitingTime: any = 0;
    @observable workingTime: any = 0;
    selectedOption: any = [{ value: 'all', label: 'All' }];
    selectLeaseOperator: any = [];
    selectConsignee: any = [];
    @observable clearSelection: boolean = false;
    @observable errorFlag: boolean = false;

    constructor(
        private customerReportDataService: CustomerReportDataService,
        public agGridService: AgGridService,
        public uiService: UiService,
        public accountStore: AccountStore
    ) {}

    init(): void {
        this.userID = this.accountStore.getUserID();
        this.usermail = this.accountStore.userName;
        this.userName = this.accountStore.displayName;
        this.getcustomerReport();
    }

    getColDef() {
        return Customerreport;
    }

    leaseOperatorFiltered(name) {
       this.consignorFilter = name;
       const selectedStatus = Array.from(new Set(this.selectedOption.map((item: any) => item.value)));
        if (name.length > 0) {
            let reports: any =[];
            if (selectedStatus[0] !== 'all') {
                reports = this.dailyBackupReport.filter(element => {
                    return name.includes(element.Origin) && selectedStatus.includes(element.TicketStatus);
                });
            } else {
                 reports = this.dailyBackupReport.filter(element => {
                    return name.includes(element.Origin);
                });
            }
            this.customerReport = reports;
            if (this.customerReport.length == 0) {
                uiService.toastService.info(ReportMessage.DAILY_NO_RECORDS);
            }
        } else {
            this.customerReport = this.dailyBackupReport;
        }
        this.sumavgReport(this.customerReport);
    }
    handleFiltered(consignorName,consigneeName) {
        this.consigneeFilter = Array.from(
            new Set(consigneeName.map((item: any) => item.value))
        );
        this.consignorFilter = Array.from(
            new Set(consignorName.map((item: any) => item.value))
        );
        const selectedStatus = Array.from(new Set(this.selectedOption.map((item: any) => item.value)));
        let reports: any =[];
        if (consignorName.length > 0 && consigneeName.length > 0) {
            reports = this.dailyBackupReport.filter(element => {
                if(this.consigneeChange) {
                    if(this.isMaster)
                    return this.consignorFilter.includes(element.Origin)|| this.consigneeFilter.includes(element.Destination) && ((selectedStatus[0] !== 'all') ? selectedStatus.includes(element.TicketStatus) : true);
                    else
                    return this.consignorFilter.includes(element.Origin) && this.consigneeFilter.includes(element.Destination) && ((selectedStatus[0] !== 'all') ? selectedStatus.includes(element.TicketStatus) : true);
                }
                if(this.consignorChange) {
                    if(this.isMaster)
                    return (this.consignorFilter.includes(element.Origin) || this.consigneeFilter.includes(element.Destination)) && ((selectedStatus[0] !== 'all') ? selectedStatus.includes(element.TicketStatus) : true);
                    else 
                    return (this.consignorFilter.includes(element.Origin) && this.consigneeFilter.includes(element.Destination)) && ((selectedStatus[0] !== 'all') ? selectedStatus.includes(element.TicketStatus) : true);
                }
            });
            this.customerReport = reports;
            if (this.customerReport.length == 0) {
                uiService.toastService.info(ReportMessage.DAILY_NO_RECORDS);
            }
        }
        else if (consigneeName.length > 0) {
             reports = this.dailyBackupReport.filter(element => {
                 return this.consigneeFilter.includes(element.Destination) && ((selectedStatus[0] !== 'all') ? selectedStatus.includes(element.TicketStatus) : true);
             });
             this.customerReport = reports;
             if (this.customerReport.length == 0) {
                 uiService.toastService.info(ReportMessage.DAILY_NO_RECORDS);
             }
         }
         else if (consignorName.length > 0) {
            reports = this.dailyBackupReport.filter(element => {
                return this.consignorFilter.includes(element.Origin) && ((selectedStatus[0] !== 'all') ? selectedStatus.includes(element.TicketStatus) : true);
            });
            this.customerReport = reports;
            if (this.customerReport.length == 0) {
                uiService.toastService.info(ReportMessage.DAILY_NO_RECORDS);
            }
        }
          else {
            reports = this.dailyBackupReport.filter(element => {
                return (selectedStatus[0] !== 'all') ? selectedStatus.includes(element.TicketStatus) : true;
            });
             this.customerReport = reports;
         }
         this.consigneeChange = false;
         this.consignorChange = false;
         this.isMaster = false;
         this.sumavgReport(this.customerReport);
     }
    statusFiltered(statusList, consignorName, consigneeName, clearFlag) {
        let reports: any = [];
        if(statusList.length == 1 && statusList.includes('all')){
            statusList = Array.from(new Set(reportticketStatus.map((item: any) => item.value)));
        }
        const selectedConsignorName = Array.from(new Set(consignorName.map((item: any) => item.value)));
        const selectedConsigneeName = Array.from(new Set(consigneeName.map((item: any) => item.value)));
        
        if (selectedConsignorName.length > 0 && selectedConsigneeName.length > 0) {
            reports = this.dailyBackupReport.filter(element => {
                return statusList.includes(element.TicketStatus) && selectedConsignorName.includes(element.Origin) && selectedConsigneeName.includes(element.Destination);
            });
        }
        else if (selectedConsignorName.length > 0) {
            reports = this.dailyBackupReport.filter(element => {
                return statusList.includes(element.TicketStatus) && selectedConsignorName.includes(element.Origin);
            });
        }
        else if (selectedConsigneeName.length > 0) {
            reports = this.dailyBackupReport.filter(element => {
                return statusList.includes(element.TicketStatus) && selectedConsigneeName.includes(element.Destination);
            });
        }
        else {
            reports = this.dailyBackupReport.filter(element => {
                return statusList.includes(element.TicketStatus);
            });
        }
        this.customerReport = reports;
        if (this.customerReport.length == 0 && clearFlag == false) {
            uiService.toastService.info(ReportMessage.DAILY_NO_RECORDS);
        }
        this.sumavgReport(this.customerReport);
    }

    filterFromDropdown(statusList, consignorName, consigneeName) {
        if(statusList.length == 1 && statusList.includes('all')){
            statusList = Array.from(new Set(reportticketStatus.map((item: any) => item.value)));
        }
        let reports: any = [];
        const selectedConsignorName = Array.from(new Set(consignorName.map((item: any) => item.value)));
        const selectedConsigneeName = Array.from(new Set(consigneeName.map((item: any) => item.value)));
        
        if (selectedConsignorName.length > 0 && selectedConsigneeName.length > 0) {
            reports = this.customerReport.filter(element => {
                return statusList.includes(element.TicketStatus) && selectedConsignorName.includes(element.Origin) && selectedConsigneeName.includes(element.Destination);
            });
        }
        else if (selectedConsignorName.length > 0) {
            reports = this.customerReport.filter(element => {
                return statusList.includes(element.TicketStatus) && selectedConsignorName.includes(element.Origin);
            });
        }
        else if (selectedConsigneeName.length > 0) {
            reports = this.customerReport.filter(element => {
                return statusList.includes(element.TicketStatus) && selectedConsigneeName.includes(element.Destination);
            });
        }
        else {
            reports = this.customerReport.filter(element => {
                return statusList.includes(element.TicketStatus);
            });
        }
        this.customerReport = reports;
        if (this.customerReport.length == 0 && this.errorFlag == false) {
            uiService.toastService.info(ReportMessage.DAILY_NO_RECORDS);
        }
        this.sumavgReport(this.customerReport);
    }

    @Loader
    @Catch(() => errorHandler(ReportMessage.CUSTOMER_ERROR_MESSAGE))
    async getcustomerReport() {
        let dropdown: any = localStorageService.get('CustomerReport_Dropdown')
        if(dropdown && dropdown.length > 0){
            this.selectedOption = dropdown[0].TicketStatus
            this.selectLeaseOperator = dropdown[0].Consignor
            this.selectConsignee = dropdown[0].Consignee
        } else {
            localStorageService.set('CustomerReport_Dropdown',[{'TicketStatus': [{ value: 'all', label: 'All' }], 'Consignor': [], 'Consignee': []}])
            this.selectedOption = [{ value: 'all', label: 'All' }];
            this.selectLeaseOperator = [];
            this.selectConsignee = [];
        }
        const customerReport = await this.customerReportDataService.getCustomerReport();
        this.leaseOperatorName = [];
        this.consigneeName = [];
        this.leaseoperator = Array.from(new Set(customerReport.map((item: any) => item.Origin)));
        this.consignee = Array.from(new Set(customerReport.map((item: any) => item.Destination)));
        this.leaseoperator.forEach(name => {
            this.leaseOperatorName.push({ value: name, label: name });
        });
        this.backupConsignorDropdown = this.leaseOperatorName;
        this.consignee.forEach(name => {
            this.consigneeName.push({ value: name, label: name });
        });
        this.backupConsigneeDropdown = this.consigneeName;
        this.customerReport = customerReport;
        this.startdate = '';
        this.enddate = '';
        this.setBackupReport(this.customerReport);
        this.sumavgReport(this.customerReport);
        if (this.customerReport.length == 0) {
            this.errorFlag = true;
            errorHandler(ReportMessage.NO_RECORDS);
        }
        this.filterFromDropdown(Array.from(new Set(this.selectedOption.map((item: any) => item.value))), this.selectLeaseOperator, this.selectConsignee);  
        this.errorFlag = false;
    }
    @computed
    get DuplicateList(): CustomerReportResponse[] {
        return toJS(this.customerReport);
    }

    get sumReport() {
        return this.actVolSum.toFixed(3);
    }

    get avgReport() {
        return this.actVolAvg.toFixed(3);
    }

    sumavgReport(data) {
        let report: any;
        let actVolSum = 0;
        let waitingTime = 0, workingTime = 0;
        for (report in data) {
            actVolSum += data[report].TicketType === 'Volume' ? data[report].VolumeDelivered : 0;
            waitingTime += data[report].WaitingTime;
            workingTime += data[report].WorkingTime;
        }
        this.actVolSum = actVolSum.toFixed(3);
        this.waitingTime = waitingTime.toFixed(2);
        this.workingTime = workingTime.toFixed(2);
    }

    @Loader
    @Catch(() => {
        errorHandler(ReportMessage.NO_RECORDS);
    })
    async getEmailConfiguration(reqbody): Promise<void> {
        const emailConfiguration = await this.customerReportDataService.getEmailConfiguration(reqbody);
        this.setEmailConfiguration(emailConfiguration);
    }
    @computed
    get EmailConfiguration(): EmailConfigurationResponse[] {
        return toJS(this.emailConfiguration);
    }

    @action
    setEmailConfiguration(data: EmailConfigurationResponse[]): void {
        this.emailConfiguration = data;
    }

    @Loader
    @Catch(() => {
        errorHandler(ReportMessage.CUSTOMER_ERROR_MESSAGE);
    })
    async getRangeResult(startDate, endDate) {
        let dropdown: any = localStorageService.get('CustomerReport_Dropdown')
        if(dropdown && dropdown.length > 0){
            this.selectedOption = dropdown[0].TicketStatus
            this.selectLeaseOperator = dropdown[0].Consignor
            this.selectConsignee = dropdown[0].Consignee
        } else {
            localStorageService.set('CustomerReport_Dropdown',[{'TicketStatus': [{ value: 'all', label: 'All' }], 'Consignor': [], 'Consignee': []}])
            this.selectedOption = [{ value: 'all', label: 'All' }];
            this.selectLeaseOperator = [];
            this.selectConsignee = [];
        }
        this.startdate = startDate;
        this.enddate = endDate;
        const reqbody: DateRange = {
            StartDate: startDate,
            EndDate: endDate
        };
        this.startDate = startDate;
        this.endDate = endDate;
        const customerReport = await this.customerReportDataService.getCustomerReportwithParams(reqbody);
        this.leaseOperatorName = [];
        this.consigneeName = [];
        this.leaseoperator = Array.from(new Set(customerReport.map((item: any) => item.Origin)));
        this.consignee = Array.from(new Set(customerReport.map((item: any) => item.Destination)));
        this.leaseoperator.forEach(name => {
            this.leaseOperatorName.push({ value: name, label: name });
        });
        this.backupConsignorDropdown = this.leaseOperatorName;
        this.consignee.forEach(name => {
            this.consigneeName.push({ value: name, label: name });
        });
        this.backupConsigneeDropdown = this.consigneeName;
        this.customerReport = customerReport;
        this.setBackupReport(this.customerReport);
            
        this.sumavgReport(this.customerReport);
        if (this.customerReport.length == 0) {
            this.errorFlag = true;
            errorHandler(ReportMessage.NO_RECORDS);
        }
        this.filterFromDropdown(Array.from(new Set(this.selectedOption.map((item: any) => item.value))), this.selectLeaseOperator, this.selectConsignee);
        this.errorFlag = false;
    }
    @action
    setBackupReport(reports: CustomerReportResponse[]): void {
        this.dailyBackupReport = reports;
    }

    isDisabled = (): boolean => {
        if (this.customerReport.length == 0) {
            return true;
        } else {
            return false;
        }
    };

    @Loader
    @Catch(() => {
        errorHandler(ReportMessage.UPLOAD_ERROR);
    })
    async sendreport(reqbody) {
        const sendreport = await this.customerReportDataService.sendReport(reqbody);
        uiService.toastService.success('Report sent');
    
    }

    @Loader
    @Catch(() => {
        errorHandler(ReportMessage.UPLOAD_ERROR);
    })
    async createCustomerReport(reqbody) {
        const sendreport = await this.customerReportDataService.createCustomerReport(reqbody);
        uiService.toastService.success('Report sent');
    }
}
