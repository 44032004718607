import { action, computed, observable, toJS } from 'mobx';
import { UiService } from '../../../shared/services/ui-service';
import Catch from '../../../shared/decorators/catch-decorator';
import Loader from '../../../shared/decorators/loader-decorator';
import { errorHandler } from '../../../shared/handlers/error-handler';
import { AccountStore } from '../../account/stores/account-store';
import { AllCarrierCompany, UserTypeDropdown } from '../model/model';
import { UserApprovalRequest, UserApprovalResponse } from '../model/userApprovalResponse';
import { userApprovalDataService, UserApprovalDataService } from '../services/user-approval-data-service';
import { UserApprovalFieldName, UserApprovalMessage } from '../domains/enum';
import { userInfoColDef } from '../user_approval_constant';
import { AgGridService } from 'features/common/services/ag-grid-service';
import _ from 'lodash';
import { dataStore } from 'features/common/stores';
import { ToastMessage } from 'shared/components/custom-toast/custom-toast';
import { userApprovalStore } from '.';
import moment from 'moment';
import {
    CARRIERCOMPANY,
    DISPATCHER,
    DRIVER,
    LEASEOPERATOR,
    OWNER,
    OWNEROPERATOR,
    PLAINS
} from 'features/account/account_constant';
import { CommonUtils } from 'shared/services/common-utils';
export class UserApprovalDataStore {
    @observable userApprovalInfo: UserApprovalResponse[] = [];

    @observable userInfo: UserApprovalResponse[] = [];
    backupUserInfo: UserApprovalResponse[] = [];
    @observable allCarrierCompany: AllCarrierCompany[] = [];
    @observable allFacilities = {};
    @observable allPSACarrier: AllCarrierCompany[] = [];
    @observable currentSelectedUser: any = {};
    @observable showModal = false;
    @observable showCarriers = false;
    @observable cellValueChangeMap = {};
    @observable isPSA = false;
    userTypeDropdown: any[] = [];
    @observable openTicketsData: any = [];
    @observable shouldShowOpenTicketPopup = false;
    userID = 0;
    @observable showADGroupAlert: string = '';
    @observable approvedRole: string = '';
    userName = '';
    filter = {};
    @observable userSelected: any = null;
    @observable dataToBeDeleted: any = [];
    emailId: string = '';
    constructor(
        private maintenanceDataService: UserApprovalDataService,
        public agGridService: AgGridService,
        public uiService: UiService,
        private accountStore: AccountStore
    ) {}

    init(): void {
        this.userID = this.accountStore.getUserID();
        this.emailId = this.accountStore.userName;
        this.userName = this.accountStore.displayName;
        this.loadViewModel();
        
    }

    getColDef() {
        return userInfoColDef;
    }

    @action
    setShowModal(isPSA: boolean): void {
        this.showModal = !this.showModal;
        this.isPSA = isPSA;
    }

    @action
    setCarriersModal(showCarriers: boolean): void {
        this.showCarriers = showCarriers;
        this.showModal = false;
    }

    updateCarrierCompany(item: UserApprovalResponse, value: any, key: any) {
        // console.log(item)
        // console.log("updated")
        // console.log(item,value,key)
        this.setValueInChangeMap(item.ID, key, item[key], value['Name']);
        item[key] = value['Name'];
        // console.log(item[key])
        this.updateRow(item);
        console.log(item.MappedCarrierName);
    }
    @action
    updateRow = (selectedRowData: UserApprovalResponse): void => {
        const data: any = [];

        this.userApprovalInfo.forEach(userApprovalInfo => {
            if (userApprovalInfo.ID === selectedRowData.ID) {
                if (!_.isEqual(userApprovalInfo, selectedRowData)) {
                    userApprovalInfo.MappedCarrierName = selectedRowData.MappedCarrierName;
                    userApprovalInfo.CarrierCompany = selectedRowData.CarrierCompany
                        ? selectedRowData.CarrierCompany
                        : [{ ID: selectedRowData.CarrierId, Name: selectedRowData.MappedCarrierName }];
                    userApprovalInfo.DriverType = selectedRowData.DriverType;
                    // this.mapEditableColumns(userApprovalInfo, selectedRowData);

                    console.log(userApprovalInfo);
                }
            }
        });
    };

    isSaveDisabled = (): boolean => {
        for (const row in this.cellValueChangeMap) {
            for (const col in this.cellValueChangeMap[row]) {
                const obj = this.cellValueChangeMap[row][col];
                if (obj.initValue !== obj.currentValue) {
                    return false;
                }
            }
        }
        return true;
    };

    setValueInChangeMap(row: number, col: string, initValue: string, newValue: string) {
        console.log(row, col, initValue, newValue);
        if (!(row in this.cellValueChangeMap)) {
            this.cellValueChangeMap[row] = {};
        }
        if (!(col in this.cellValueChangeMap[row] && this.cellValueChangeMap[row][col].initValue)) {
            this.cellValueChangeMap[row][col] = { initValue: initValue };
        } else {
            this.cellValueChangeMap[row][col]['currentValue'] = newValue;
        }
        console.log(this.cellValueChangeMap);
    }

    getUpdatedRowIDs(): string[] {
        // console.log(this.cellValueChangeMap)
        let updatedRowIDs: string[] = [];
        for (const row in this.cellValueChangeMap) {
            for (const col in this.cellValueChangeMap[row]) {
                const obj = this.cellValueChangeMap[row][col];
                if (obj.initValue !== obj.currentValue) {
                    updatedRowIDs = [...updatedRowIDs, row];
                    break;
                }
            }
        }
        console.log(updatedRowIDs);
        return updatedRowIDs;
    }
    @Loader
    @Catch(() => errorHandler(UserApprovalMessage.FETCH_ERROR_MESSAGE))
    async loadViewModel(): Promise<void> {
        let userID = this.accountStore.getUserID();
        let reqbody = this.accountStore.userRoles.includes('dispatcher') && !this.emailId.includes('@plains.com') ? {UserID: userID} : {}
        const {
            userApprovalInfo,
            facilities,
            carrierCompany,
            psaCarrier
        } = await this.maintenanceDataService.getUserApprovalViewModel(reqbody);

        const usersInfo: UserApprovalResponse[] = userApprovalInfo['Data'] ? userApprovalInfo['Data'] : [];
        const carrierInfo: AllCarrierCompany[] = carrierCompany['Data'];
        this.cellValueChangeMap = {};
        usersInfo.forEach(data => {
            data.IsSelected = false;
            if(data.CellPhoneNo != null)
            data.CellPhoneNo = data.CellPhoneNo.replace(CommonUtils.getCellPhoneRegex(), "($1) $2 $3");
        });
        this.setUserInfo(usersInfo);
        this.setBackupUserInfo(usersInfo);
        this.allCarrierCompany = carrierInfo;
        this.allFacilities = facilities['Data'];
        this.allPSACarrier = this.dictionaryToObj(psaCarrier['Data']);
        this.userApprovalInfo = usersInfo;
        this.userTypeDropdown = UserTypeDropdown;
        this.userSelected = null;
        this.addValuesInCellDropdowns();
    }
    @action
    setBackupUserInfo(userInfo: UserApprovalResponse[]): void {
        this.backupUserInfo = userInfo;
    }
    @action
    setUserInfo(userInfo: UserApprovalResponse[]): void {
        this.userApprovalInfo = userInfo;
    }
    @action
    resetUserInfo(): void {
        console.log(this.userApprovalInfo, this.backupUserInfo);
        this.setUserInfo(this.backupUserInfo);
        this.cellValueChangeMap = {};
    }

    updateUserDetailsRequest(updatedRows: UserApprovalResponse[]): any[] {
        let requestList: any[] = [];
        updatedRows.forEach(item => {
            console.log(item);
            const {
                ID,
                FirstName,
                LastName,
                LoginName,
                DriverType,
                MappedCarrierName,
                IsPlains,
                IsCarrierCompany,
                IsCarrierCompanyAdmin,
                IsCarrierCompanyScheduler,
                IsDispatcher,
                IsDriver,
                IsLeaseOperator,
                IsOwnerOperator,
                CreatedDate,
                ModifiedBy,
                ModifiedByUserName,
                ModifiedDate,
                UserType,
                CreatedBy,
                CarrierCompany,
                CarrierId
            } = item;
            if (
                dataStore.checkOperationAccess('UserInfoEdit') &&
                (this.cellValueChangeMap[item.ID]['DriverType'] || this.cellValueChangeMap[item.ID]['CarrierCompany'])
            ) {
                // item.ModifiedBy = this.userID;
                item.ModifiedDate = moment()
                    .toDate()
                    .toISOString();
            }
            requestList = [
                ...requestList,
                {
                    ID: ID,
                    CarrierCompany: CarrierCompany ? CarrierCompany : [{ ID: CarrierId, Name: MappedCarrierName }],
                    FirstName: FirstName,
                    LastName: LastName,
                    LoginName: LoginName,
                    DriverType: DriverType,
                    MappedCarrierName: null,
                    IsPlains: DriverType === PLAINS,
                    IsCarrierCompany: UserType === CARRIERCOMPANY,
                    IsCarrierCompanyAdmin: UserType === 'Carrier Company Admin',
                    IsCarrierCompanyScheduler: UserType === 'Carrier Company Scheduler',
                    IsDispatcher: UserType === DISPATCHER,
                    IsDriver: UserType === DRIVER || UserType === 'Owner/Driver',
                    IsLeaseOperator: UserType === LEASEOPERATOR,
                    IsOwnerOperator: UserType === OWNER || UserType === 'Owner/Driver',
                    CreatedDate: CreatedDate,
                    ModifiedBy: this.userID,
                    ModifiedByUserName: ModifiedByUserName,
                    ModifiedDate: item.ModifiedDate,
                    UserType: UserType,
                    CreatedBy: ModifiedBy ? ModifiedBy : this.userID
                }
            ];
        });
        console.log(requestList);
        return requestList;
        // return updatedRows
    }
    private addValuesInCellDropdowns(): void {
        console.log(this.userTypeDropdown);
        this.agGridService.updateOptionCellEditorValues(
            this.userTypeDropdown,
            UserApprovalFieldName.DriverType,
            'label'
        );
        this.accessCheck(UserApprovalFieldName.DriverType, 'UserInfoEdit');
    }
    accessCheck(columnKey: string, propertyName: string) {
        const saveAccess = dataStore.checkOperationAccess('Save');
        if (!saveAccess) {
            this.agGridService.updateEditableProperty(columnKey, saveAccess);
            return saveAccess;
        }
        const access = dataStore.checkOperationAccess(propertyName);
        this.agGridService.updateEditableProperty(columnKey, access);
        return access;
    }
    dictionaryToObj(dict: any) {
        const optionlist: AllCarrierCompany[] = [];
        for (const key in dict) {
            const obj = { ID: 0, CompanyName: '' };
            obj.ID = (key as unknown) as number;
            obj.CompanyName = dict[key];
            optionlist.push(obj);
        }
        return optionlist;
    }

    getFilter() {
        this.filter = this.agGridService.getAllFilters();
    }

    setFilter() {
        this.agGridService.setAllFilters(this.filter);
    }

    @computed
    get trailerMaintenanceList(): UserApprovalResponse[] {
        return toJS(this.userApprovalInfo);
    }
    mapEditableColumns(currentItem: UserApprovalResponse, updatedItem: UserApprovalResponse) {
        // if (currentItem) {
        currentItem.IsPlains = updatedItem.IsPlains;
        currentItem.MappedCarrierName = updatedItem.MappedCarrierName;
        currentItem.ModifiedByUserName = updatedItem.ModifiedByUserName;
        currentItem.ModifiedDate = updatedItem.ModifiedDate;
        // }
    }
    @Loader
    @action
    @Catch(() => errorHandler(UserApprovalMessage.FAILED_SUBMIT))
    async updateUserDetails(): Promise<void> {
        const toastService = new UiService();
        const updatedRowIDs = this.userApprovalInfo.filter(a => this.getUpdatedRowIDs().includes(a.ID.toString()));
        console.log(updatedRowIDs);
        let userID = this.accountStore.getUserID()
        if (!userID || userID === 0 ) {
            await this.accountStore.getLoggedInUserDetailsIfUserIdZero(this.accountStore.userName).then(() => {
                userID = this.accountStore.getUserID();
                this.userID = this.accountStore.getUserID();
            });
            if (!userID || userID === 0 ) {
                return;
            }
        }
        const requestBody = this.updateUserDetailsRequest(updatedRowIDs);
        const result = await this.maintenanceDataService.updateUserDetails(requestBody);
        if (result) {
            await this.loadViewModel();
            toastService.toastService.success('Record(s) Save successfully');
        } else {
            toastService.toastService.error('Record(s) Saved failed');
        }
    }

    @Loader
    @action
    @Catch(() => errorHandler(UserApprovalMessage.FAILED_SUBMIT))
    async createCarrierCompany(request): Promise<void> {
        const toastService = new UiService();
        const result = await this.maintenanceDataService.createCarrierCompanyInfo(request);
        if (result) {
            toastService.toastService.success('Record(s) Save successfully');
        } else {
            toastService.toastService.error('Record(s) Saved failed');
        }
    }

    @Loader
    @action
    @Catch(() => errorHandler(UserApprovalMessage.FAILED_SUBMIT))
    async updateUserADGroupDetails(updatedItem): Promise<void> {
        const toastService = new UiService();
            // item.ModifiedBy = this.userID;
        const modifiedDate = moment()
            .toDate()
            .toISOString();
        const triggertime = moment(new Date(Math.ceil(new Date().getTime()/(1000 * 60 * 5)) * (1000 * 60 * 5))).add(15, 'minutes').utc().format("HH:mm");
        const requestList: UserApprovalResponse = 
            { ...updatedItem,
                ID: updatedItem.ID,
                CarrierCompany: updatedItem.CarrierCompany ? updatedItem.CarrierCompany : [{ ID: updatedItem.CarrierId, Name: updatedItem.MappedCarrierName }],
                FirstName: updatedItem.FirstName,
                LastName: updatedItem.LastName,
                LoginName: updatedItem.LoginName,
                DriverType: updatedItem.DriverType,
                MappedCarrierName: null,
                IsPlains: updatedItem.DriverType === PLAINS,
                IsCarrierCompany: updatedItem.NewUserType === CARRIERCOMPANY,
                IsCarrierCompanyAdmin: updatedItem.NewUserType === 'Carrier Company Admin',
                IsCarrierCompanyScheduler: updatedItem.NewUserType === 'Carrier Company Scheduler',
                IsDispatcher: updatedItem.NewUserType === DISPATCHER,
                IsDriver: updatedItem.NewUserType === DRIVER || updatedItem.NewUserType === 'Owner/Driver',
                IsLeaseOperator: updatedItem.NewUserType === LEASEOPERATOR,
                IsOwnerOperator: updatedItem.NewUserType === OWNER || updatedItem.NewUserType === 'Owner/Driver',
                CreatedDate: updatedItem.CreatedDate,
                ModifiedBy: this.userID,
                ModifiedByUserName: this.userName,
                ModifiedDate: modifiedDate,
                UserType: updatedItem.UserType ? updatedItem.UserType : updatedItem.NewUserType, 
                NewUserType: updatedItem.UserType ? updatedItem.NewUserType : null,
                TriggerTime: triggertime
                // CreatedBy: updatedItem.ModifiedBy ? updatedItem.ModifiedBy : this.userID
            };
        // const requestBody = this.updateUserDetailsRequest(requestList);
        const result = await this.maintenanceDataService.updateUserInfo(requestList, false);
        if (result) {
            await this.loadViewModel();
            this.showADGroupAlert = 'update';
        } else {
            toastService.toastService.error('Record(s) Saved failed');
        }
    }

    @Loader
    @action
    @Catch(() => errorHandler(UserApprovalMessage.FAILED_SUBMIT))
    async deleteUserADGroupDetails(updatedItem, shouldShowSuccessMessage): Promise<void> {
        const toastService = new UiService();
            // item.ModifiedBy = this.userID;
        const modifiedDate = moment()
            .toDate()
            .toISOString();
        const requestList = 
            {
                ...updatedItem,
                ID: updatedItem.ID,
                CarrierCompany: updatedItem.CarrierCompany ? updatedItem.CarrierCompany : [{ ID: updatedItem.CarrierId, Name: updatedItem.MappedCarrierName }],
                FirstName: updatedItem.FirstName,
                LastName: updatedItem.LastName,
                LoginName: updatedItem.LoginName,
                DriverType: updatedItem.DriverType,
                MappedCarrierName: null,
                IsPlains: updatedItem.DriverType === PLAINS,
                IsCarrierCompany: updatedItem.UserType === CARRIERCOMPANY,
                IsCarrierCompanyAdmin: !shouldShowSuccessMessage ? updatedItem.UserType === 'Carrier Company Admin': false,
                IsCarrierCompanyScheduler: !shouldShowSuccessMessage ? updatedItem.UserType === 'Carrier Company Scheduler': false,
                IsDispatcher: !shouldShowSuccessMessage ? updatedItem.UserType === DISPATCHER : false,
                IsDriver: !shouldShowSuccessMessage ? updatedItem.UserType === DRIVER || updatedItem.UserType === 'Owner/Driver': false,
                IsLeaseOperator:  !shouldShowSuccessMessage ? updatedItem.UserType === LEASEOPERATOR : false,
                IsOwnerOperator:  !shouldShowSuccessMessage ? updatedItem.UserType === OWNER || updatedItem.UserType === 'Owner/Driver' : false,
                CreatedDate: updatedItem.CreatedDate,
                ModifiedBy: this.userID,
                ModifiedByUserName: this.userName,
                ModifiedDate: modifiedDate,
                UserType: updatedItem.UserType,
                NewUserType: updatedItem.NewUserType,
                IsRoleRemoved: shouldShowSuccessMessage ? true : false
                // CreatedBy: updatedItem.ModifiedBy ? updatedItem.ModifiedBy : this.userID
            };
        // const requestBody = this.updateUserDetailsRequest(requestList);
        if(shouldShowSuccessMessage){
            const result = await this.maintenanceDataService.removeUserRole(requestList);
            if (result) {
                    await this.loadViewModel();
                    toastService.toastService.success('Record(s) Save successfully');
            } else {
                toastService.toastService.error('Record(s) Saved failed');
            }
        } else {
            const result = await this.maintenanceDataService.disapproveUser(requestList,this.isPSA);
            if(result) {
                await this.loadViewModel()
            }
            else {
            toastService.toastService.error('Record(s) Saved failed');
            }
        }
    }

    @Loader
    @action
    @Catch(() => errorHandler(UserApprovalMessage.FAILED_SUBMIT))
    async updateUser(data: any): Promise<void> {
        this.getFilter();
        const toastService = new UiService();
        let userID = this.accountStore.getUserID()
        if (!userID || userID === 0 ) {
            await this.accountStore.getLoggedInUserDetailsIfUserIdZero(this.accountStore.userName).then(() => {
                userID = this.accountStore.getUserID();
                this.userID = this.accountStore.getUserID();
            });
            if (!userID || userID === 0 ) {
                return;
            }
        }
        const triggertime = moment(new Date(Math.ceil(new Date().getTime()/(1000 * 60 * 5)) * (1000 * 60 * 5))).add(15, 'minutes').utc().format("HH:mm");
        data.ModifiedBy = this.userID;
        data.TriggerTime = triggertime
        console.log(data);
        const result = await this.maintenanceDataService.updateUserInfo(data, this.isPSA);
        if (result) {
            await this.loadViewModel();
            if(result[1] === true)
            toastService.toastService.success(`Saved Successfully`);
            else
            this.showADGroupAlert = 'add';
        } else {
            toastService.toastService.error('Record(s) Saved failed');
        }
    }

    @Loader
    @action
    @Catch(() => errorHandler(UserApprovalMessage.FAILED_SUBMIT))
    async disapproveUser(data: any): Promise<void> {
        this.getFilter();
        const toastService = new UiService();
        let userID = this.accountStore.getUserID()
        if (!userID || userID === 0 ) {
            await this.accountStore.getLoggedInUserDetailsIfUserIdZero(this.accountStore.userName).then(() => {
                userID = this.accountStore.getUserID();
                this.userID = this.accountStore.getUserID();
            });
            if (!userID || userID === 0 ) {
                return;
            }
        }
        data.ModifiedBy = this.userID;
        if (!this.isPSA) {
            const triggertime = moment(new Date(Math.ceil(new Date().getTime()/(1000 * 60 * 5)) * (1000 * 60 * 5))).add(15, 'minutes').utc().format("HH:mm");
            data.TriggerTime = triggertime
        }
        const result = await this.maintenanceDataService.disapproveUser(data, this.isPSA);
        if (result) {
            await this.loadViewModel();
            toastService.toastService.success('Record(s) Save successfully');
        } else {
            toastService.toastService.error('Record(s) Saved failed');
        }
    }

    @Loader
    @action
    @Catch(() => errorHandler(UserApprovalMessage.FAILED_SUBMIT))
    async importPSAData(data: any): Promise<void> {
        this.getFilter();
        const toastService = new UiService();
        data.ModifiedBy = this.userID;
        data.ID = this.userID;
        const result = await this.maintenanceDataService.importPSAData(data);
        if (result) {
            await this.loadViewModel();
            toastService.toastService.success('Data imported successfully');
        } else {
            toastService.toastService.error('Failed to import data.');
        }
    }

    @Loader
    @action
    @Catch(() => errorHandler(UserApprovalMessage.FETCH_ERROR_MESSAGE))
    async getOpenTicketsForDriver(request): Promise<void> {
        this.getFilter();
        const toastService = new UiService();
        const result = await this.maintenanceDataService.getOpenTicketsForDriver(request);
        if (result) {
            const { data } = result;
            this.openTicketsData = data['Data'];
            if (this.openTicketsData && this.openTicketsData.length > 0) {
                this.shouldShowOpenTicketPopup = true;
            } else {
                this.shouldShowOpenTicketPopup = false;
            }
        } else {
            this.shouldShowOpenTicketPopup = false;
        }
    }
    selectUsersToUpdateADGroup(data, value, colName): void {
        this.userSelected = data
    }

    @Loader
    @action
    @Catch(() => errorHandler(UserApprovalMessage.FAILED_DELETE))
    async deleteTablet(deletedItems): Promise<void> {
        const Promises: any = [];
        
        if (!this.userID || this.userID === 0 ) {
            await this.accountStore.getLoggedInUserDetailsIfUserIdZero(this.accountStore.userName).then(() => {
                this.userID = this.accountStore.getUserID();
            });
            if (!this.userID || this.userID === 0 ) {
                return;
            }
        }
        const reqbody = [
            {
                LoginName: deletedItems.LoginName,
                ModifiedBy: this.userID
            }
        ]
        this.maintenanceDataService.deactivateUser(reqbody);
        Promise.all(Promises).then(
            () => {
                this.dataToBeDeleted = [];
                this.isDeleteEnabled();
                this.loadViewModel();
                this.uiService.toastService.success(UserApprovalMessage.TABLET_DELETE);
            },
            () => {
                this.uiService.toastService.error(UserApprovalMessage.FAILED_DELETE);
            }
        );
    }

    isDeleteEnabled = (): boolean => {
        return !this.userSelected || (this.userSelected?.PSAStatusCode == 'APPROVED' || this.userSelected?.StatusCode == 'APPROVED');
    };

}
