import { action, computed, observable, toJS } from 'mobx';
import { UiService } from '../../../shared/services/ui-service';
import Catch from '../../../shared/decorators/catch-decorator';
import Loader from '../../../shared/decorators/loader-decorator';
import { errorHandler } from '../../../shared/handlers/error-handler';
import { AccountStore } from '../../account/stores/account-store';
import { PrinterDataService } from '../services/printer-management/printer-data-service';
import { ToastMessage } from 'shared/components/custom-toast/custom-toast';
import { AgGridService } from 'features/common/services/ag-grid-service';
import { isEmpty } from 'lodash';
import _ from 'lodash';
import { CommonUtils } from 'shared/services/common-utils';
import { KeyfobsDataService } from '../services/keyfobs-data-service';
import { KeyfobsResponse } from '../domains/keyfobs-model';
import { KeyfobFieldName, KeyfobMessage } from '../domains/keyfobs.enum';
import { KeyFobColDef } from '../domains/keyfobs-col-def';
import moment from 'moment';

export class KeyfobsDataStore {
    @observable keyfobsInfo: KeyfobsResponse[] = [];
    backupkeyfobsList: KeyfobsResponse[] = [];
    @observable cellValueChangeMap = {};
    userID = 0;
    userName = '';
    @observable dataToBeDeleted: any[] = [];
    @observable dataToBeDeletedID: number[] = [];
    companyData: any = [];
    locationData: any = [];
    statusData: any = [{id: 1, value: 'Assigned'}, {id: 2, value: 'Unassigned'}];
    assignedDate: any = moment(new Date());
    @observable showModal = false;
    approvedCommentTicketModel: any;
    approvedColumn: any;
    @observable showCCModal = false; 
    @observable showLocModal = false;
;

    constructor(
        private printerDataService: KeyfobsDataService,
        public agGridService: AgGridService,
        public uiService: UiService,
        private accountStore: AccountStore,
        // private PrinterValidationService: PrinterValidationService
    ) { }

    init(): void {
        this.getPrintersData();
        this.userID = this.accountStore.getUserID();
        this.userName = this.accountStore.displayName;
    }

    getColDef() {
        return KeyFobColDef;
    }

    @Loader
    @Catch(() => errorHandler(KeyfobMessage.FETCH_ERROR_MESSAGE))
    async getPrintersData(): Promise<void> {
        const printers: KeyfobsResponse[] = await this.printerDataService.getKeyfobData();
        const companyData: any  = await this.printerDataService.getCarrierCompanyData();
        const locationData: any = await this.printerDataService.getOperator();
        this.companyData = companyData.filter(a => !a.IsDeleted && a.RACompanyName != null);
        this.companyData.map(data => {
            data.CompanyName = data.RACompanyName;
            data.AccountNumber = data.RAAccountNumber;
        })
        this.locationData = locationData.filter(a => a.SourceSystem == 'RA')
        this.setPrinterList(printers);
        this.setbackupPrinterList(printers);
        this.addValuesInCellDropdowns();
    }

    setValueInChangeMap(row: number, col: string, initValue: string, newValue: string) {
        if (!(row in this.cellValueChangeMap)) {
            this.cellValueChangeMap[row] = {};
        }
        if (!(col in this.cellValueChangeMap[row] && this.cellValueChangeMap[row][col].initValue))
            this.cellValueChangeMap[row][col] = { initValue: initValue };
        else
            this.cellValueChangeMap[row][col]['currentValue'] = newValue;
        console.log(this.cellValueChangeMap);
    }

    private addValuesInCellDropdowns(): void {
        this.agGridService.updateOptionCellEditorValues(this.companyData, KeyfobFieldName.CARRIERCOMPANY, 'CompanyName');
        this.agGridService.updateOptionCellEditorValues(this.locationData, KeyfobFieldName.LOCATION, 'Description');
        this.agGridService.updateOptionCellEditorValues(this.statusData, KeyfobFieldName.STATUS, 'value');
    }

    @action
    setAlphaNumeric(printer: KeyfobsResponse, value: string, colName: string): void {
        const row = printer.ID;
        const initValue = printer[colName];
        this.setValueInChangeMap(row, colName, initValue, value);
        switch (colName) {
            case KeyfobFieldName.KEYFOBNUMBER:
                printer.KeyFobNumber = value;
                break;
            default:
                break;
        }
        this.updateRow(printer);
    }

    setbackupPrinterList(Printers: KeyfobsResponse[]) {
        this.backupkeyfobsList = Printers;
    }

    @computed
    get printerList(): KeyfobsResponse[] {
        return toJS(this.keyfobsInfo);
    }

    @action
    setPrinterList(printerInfo: KeyfobsResponse[]): void {
        this.keyfobsInfo = printerInfo;
    }

    @action
    reset(): void {
        this.setPrinterList([]);
    }

    @action
    resetPrinterList(): void {
        this.setPrinterList(this.backupkeyfobsList);
        this.cellValueChangeMap = {};
    }

    @action
    updateRow = (selectedRowData: KeyfobsResponse): void => {
        const updatedPrinterIndex = this.keyfobsInfo.findIndex(a => a.ID == selectedRowData.ID);
        if (!_.isEqual(this.keyfobsInfo[updatedPrinterIndex], selectedRowData)) {
            this.updateIDFieldBasedOnName(selectedRowData)
            this.mapEditableColumns(this.keyfobsInfo[updatedPrinterIndex], selectedRowData);
        }
    };

    private updateIDFieldBasedOnName(keyfobs: KeyfobsResponse) {
        keyfobs.CarrierCompanyID = this.companyData.find(a => a.CompanyName == keyfobs.CarrierCompany)?.ID;
        keyfobs.LocationID = this.locationData.find(a => a.Description == keyfobs.Location)?.ID;
    }

    @action
    @Loader
    @Catch(() => errorHandler(KeyfobMessage.DELETEDERROR))
    async deleteRows(): Promise<void> {
        if (this.dataToBeDeletedID.length > 0) {
            const updateData = this.keyfobsInfo.filter(x => this.dataToBeDeleted.filter(y => y.ID === x.ID).length);
            updateData.filter(item => {
                item.IsDeleted = true;
            })
            const requestBody = {
                KeyFobData: this.updatePrinterRequest(updateData),
                CreatedBy: this.userID,
                ModifiedBy: this.userID
            }
            await this.printerDataService.updateKeyfobs(requestBody);
            this.getPrintersData();
            this.dataToBeDeleted = [];
            this.cellValueChangeMap = {};
            this.isDeleteEnabled();
            this.uiService.toastService.success(KeyfobMessage.DELETEDSUCCESS);
        }
        else
            errorHandler(KeyfobMessage.NO_ROW_SELECTED);
    }


    @action
    handleCheckboxClick(printer: KeyfobsResponse, value: any, colName: string): void {
        const row = printer.ID;
        const initValue = printer[colName];
        this.setValueInChangeMap(row, colName, initValue, value);
        switch (colName) {
            case KeyfobFieldName.DELETE:
                printer.IsDeleted = !value;
                break;
            default:
                break;
        }
        if (printer.IsDeleted) {
            this.dataToBeDeleted.push({ ID: printer.ID });
            this.dataToBeDeletedID.push(printer.ID);

        } else {
            this.dataToBeDeleted = this.dataToBeDeleted.filter(a => a.ID !== printer.ID);
            this.dataToBeDeletedID = this.dataToBeDeletedID.filter(a => a !== printer.ID);
        }
    }

    @action
    addRow(): void {
        const printer: KeyfobsResponse = {
            ID: 0,
            KeyFobNumber: '',
            CarrierCompany: '',
            Location: '',
            Status: '',
            CreatedBy: this.userID,
            CreatedDate: '',
            ModifiedByUser: '',
            AssignedDate: ''
        };
        let minID: number = this.keyfobsInfo[0]?.ID;
        if (minID <= 0) {
            printer.ID = minID - 1;
        }
        this.keyfobsInfo.unshift(printer);
    };

    @Loader
    @action
    @Catch(() => errorHandler(KeyfobMessage.FAILED_SUBMIT))
    async updatePrinters(): Promise<void> {
        let errorSummary: ToastMessage[] = [];
        console.log('update')
        const updatedRowIDs = this.keyfobsInfo.filter(a =>
            this.getUpdatedRowIDs().includes(a.ID.toString())
        );
        console.log(updatedRowIDs);
        if (!this.userID|| this.userID === 0 ) {
            await this.accountStore.getLoggedInUserDetailsIfUserIdZero(this.accountStore.userName).then(() => {
                this.userID = this.accountStore.getUserID();
            });
            if (!this.userID || this.userID === 0 ) {
                return;
            }
        }
        console.log('fewe',updatedRowIDs);
        const requestBody = {
            KeyFobData: this.updatePrinterRequest(updatedRowIDs),
            CreatedBy: this.userID,
            ModifiedBy: this.userID
        }
        // const requestBody = this.updatePrinterRequest(updatedRowIDs);
        console.log('requestBody',requestBody);
        // const validationMessage = this.PrinterValidationService.validateUpdateRequest(requestBody, this.backupPrinterList);
        // if (!isEmpty(requestBody)) {
        //     this.uiService.loaderService.hideLoader();
        //     // this.uiService.toastService.error(validationMessage);
        //     return;
        // }
        console.log(requestBody)
        const response = await this.printerDataService.updateKeyfobs(requestBody);
        if (!isEmpty(response)) {
            for (const key in response) {
                errorSummary = [...errorSummary, { id: key, description: response[key] }];
            }
            this.uiService.toastService.error('', {}, errorSummary);
        }
        this.cellValueChangeMap = {};
        this.getPrintersData();
        if (errorSummary.length == 0)
            this.uiService.toastService.success(KeyfobMessage.SAVE);
    }

    updatePrinterRequest(updatedRows: KeyfobsResponse[]): KeyfobsResponse[] {
        updatedRows.forEach(item => {
            item.ModifiedBy = this.userID;
            // item.ModifiedByUser = this.userName;
            item.KeyFobID = item.ID;
            
        });
        return updatedRows;
    }

    getUpdatedRowIDs(): string[] {
        let updatedRowIDs: string[] = [];
        //get updated rows id  here from changedMap
        for (const row in this.cellValueChangeMap) {
            for (const col in this.cellValueChangeMap[row]) {
                const obj = this.cellValueChangeMap[row][col];
                if (obj.initValue !== obj.currentValue) {
                    updatedRowIDs = [...updatedRowIDs, row];
                    break;
                }
            }
        }
        return updatedRowIDs;
    }

    isSaveDisabled = (): boolean => {
        for (const row in this.cellValueChangeMap) {
            for (const col in this.cellValueChangeMap[row]) {
                if (KeyfobFieldName.DELETE != col) {
                    const obj = this.cellValueChangeMap[row][col];
                    if (obj.initValue !== obj.currentValue) {
                        return false;
                    }
                }
            }
        }
        return true;
    };

    mapEditableColumns(currentItem: KeyfobsResponse, updatedItem: KeyfobsResponse) {
        currentItem.KeyFobNumber = updatedItem.KeyFobNumber;
        currentItem.CarrierCompany = updatedItem.CarrierCompany;
        currentItem.AssignedDate = updatedItem.AssignedDate;
        currentItem.Location = updatedItem.Location;
        currentItem.Status = updatedItem.Status;
        currentItem.CarrierCompanyID = updatedItem.CarrierCompanyID;
        currentItem.LocationID = updatedItem.LocationID;
        currentItem.IsDeleted = updatedItem.IsDeleted;
        
    }

    isDeleteEnabled = (): boolean => {
        const isEnabled = this.dataToBeDeleted.length > 0 ? false : true;
        return isEnabled;
    };

    @action
    showTicketApproverPopUp() {
        this.showModal = true;
    }

    @action
    showCarrierPopUp() {
        this.showCCModal = true;
    }
    @action
    showLocationPopUp() {
        this.showLocModal = true;
    }

    @action
    hideTicketApproverPopUp() {
        this.showModal = false;
    }

    @action
    hideCarrierPopUp() {
        this.showCCModal = false;
        this.showModal = false;
        this.showLocModal = false;
    }

    updatePlannedDateTime(approverComment: string) {
        if (!this.approvedCommentTicketModel) {
            return;
        }
        this.updatePlannedDateTimeInPopUp(this.approvedCommentTicketModel, approverComment, this.approvedColumn, true);
    }

    @action
    updatePlannedDateTimeInPopUp(item: KeyfobsResponse, value: string, key: string, popup?: boolean) {
        this.setValueInChangeMap(item.ID, key, item[key], value);
        item[key] = value;
        this.updateRow(item);
    }

    updateCarrierCompany(approverComment: string) {
        if (!this.approvedCommentTicketModel) {
            return;
        }
        this.updateCarrierCompanyInPopUp(this.approvedCommentTicketModel, approverComment, this.approvedColumn, true);
    }

    @action
    updateCarrierCompanyInPopUp(item: KeyfobsResponse, value: string, key: string, popup?: boolean) {
        this.setValueInChangeMap(item.ID, key, item[key], value);
        item[key] = value;
        this.updateRow(item);
    }

}
