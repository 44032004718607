/* eslint-disable @typescript-eslint/camelcase */
import React, { Component } from 'react';
import { render } from 'react-dom';
import moment from 'moment';
import { dataStore } from 'features/common/stores';
import { loadStatus, ShipperName, suppliersRoles, dataSources, productsData, commaFormat, loadDeletedFormat } from '../nomination-constants';
import {
    CellEditingStartedEvent,
    CellEditingStoppedEvent,
    FilterChangedEvent,
    GridOptions,
    RangeSelectionChangedEvent
} from 'ag-grid-community';
import { AgGridComponentParams } from 'features/common/domain/model';
import { agGridService } from 'features/common/services/ag-grid-service';
import { AgGridComponent } from 'features/common/components/ag-grid-component';
import { CustomButton, CustomButtonType } from 'shared/components/custom-button/custom-button';
import './_nomination-screen.scss';
import { NominationSelectionRadio } from './nomination-selection-radio';
import { AddCustomerButton } from './add-customer-button';
import { NominationStore } from '../stores/nominatation-data-store';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import { accountStore } from 'features/account/stores';
import Loader from '../../../../shared/decorators/loader-decorator';
import Catch from '../../../../shared/decorators/catch-decorator';
import { nominationDataService } from '../services/nomination-data-service';
import { observer } from 'mobx-react';
import _, { isEmpty } from 'lodash';
import { uiService } from 'shared/services/ui-service';
import { toJS, observable, action } from 'mobx';
import { NominationDataRequest } from '../models/model';
import { AppConstant } from 'app_constant';
import momenttimezone from 'moment-timezone';
import { errorHandler } from 'shared/handlers/error-handler';
import { ReportMessage } from 'features/reports/domains/enum';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { TextAreaCellRenderer } from 'shared/components/cell-renderers/text-area-cell-renderer';
import { CommonUtils } from 'shared/services/common-utils';
import { localStorageService } from 'shared/services/local-storage-service';
import { ClearButtonComponent } from 'features/common/components/clear-button-component';
import { dateUtils } from 'shared/services/date-utils';
import { Row, Col } from 'react-bootstrap';
import { PlainsVolumePopup } from './nomination-crudeRatio-popup';
import { NominationLoadsPopup } from './nomination-loads-popup';
import { dispatchPlanDataService } from 'features/dispatch/dispatch-plan-management/services/dispatchPlan-data-service';
import { UpdateDispatchPlanResponse } from 'features/dispatch/dispatch-plan-management/model/model';
import { ConfirmProps, ManualNominationFilterPopUp } from 'shared/components/confirm/manualNominationFilterPopup';
import { INVALID_USERID } from 'features/data_management/data_constant';
import { ConfirmWithOK, ConfirmWithOKProps } from 'shared/components/confirm/confirmWithOk';
import { locationDataService } from 'features/data_management/domains/location-management/location-data-service';
import { LocationMessage } from 'features/data_management/domains/location-management/enum';
import { EditNominationCellRenderer } from 'shared/components/cell-renderers/edit-nomination-renderer';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
/* eslint-disable */
const options = [
    { value: 'North-east', label: '1' },
    { value: 'North-west', label: '2' },
    { value: 'South-east', label: '3' },
    { value: 'South-west', label: '4' }
];

const remainingDays: any = (selectedYear,selectedMonth) => {
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();

    if(selectedMonth == currentMonth && selectedYear == currentYear) {
        let currentDate = new Date();
        let lastDate = new Date(selectedYear, selectedMonth + 1, 0);
        return lastDate.getDate() > currentDate.getDate() ? lastDate.getDate() - currentDate.getDate() : 1;
    } else if((selectedMonth > currentMonth && selectedYear == currentYear) || (selectedYear > currentYear)) {
        let lastDate = new Date(selectedYear, selectedMonth + 1, 0);
        return lastDate.getDate();
    } else return 1;
}

interface NominationDataProps {
    nominationDataStore: NominationStore;
    isPopupVisible?: boolean;
}
interface NominationDataStates {
    selectedMonth: any;
    selectedYear: any;
    datesOfMonth: any;
    count: any;
    currentMonth: any;
    currentYear: any;
    selectedRegion: any;
    selectedRegionByRole: any;
    selectedProduct: any;
}
@observer
export class NominationComponent extends React.Component<NominationDataProps, NominationDataStates> {
    gridApi: any;
    gridColumnApi: any;
    loadAdjusted: any;
    updatedNominationData: any;
    CrudePending: any = 0;
    CrudeDispatched: any = 0;
    CrudeCompleted: any = 0;
    excelResponse: any = '';
    maxRowsReached: boolean = false;
    aggFuncs = {
    'aggSum': params => {
        let sum = 0;
        params.forEach(value => {
            if(value && value > 0)
            sum = sum + parseFloat(value)
        });
        return this.roundOff(sum);
    },
    'aggSumCompleted%': params => {
        let CompletedSum: any = 0;
        let VTTSum: any = 0;
        let TOVSum: any = 0;
        params.forEach(value => {
            if (value && value.CompletedVolume && value.CompletedVolume > 0) {
                CompletedSum += parseFloat(value.CompletedVolume);
            }
            if (value && value.VolsToBeTrucked && value.VolsToBeTrucked > 0) {
                VTTSum += parseFloat(value.VolsToBeTrucked);
            }
            if (value && value.TotalOrderVolume && value.TotalOrderVolume > 0) {
                TOVSum += parseFloat(value.TotalOrderVolume);
            }
        });
        return CompletedSum === 0 ? '0.000' : VTTSum === 0 ? TOVSum === 0 ? '0.000' : this.roundOff((CompletedSum / TOVSum) * 100) : this.roundOff((CompletedSum / VTTSum) * 100)
    },
    'aggLoadAdjustments': params => {
        let sum = 0;
        params.forEach(value => {
            if(value && value.LoadAdjustmentFlag == 1)
            sum = sum + parseFloat(value)
            else if(value && value.LoadAdjustmentFlag == 0)
            sum = sum - parseFloat(value)
        });
        return sum < 0 ? {
            LoadAdjustmentFlag: 0,
            LoadAdjustmentVolume: loadDeletedFormat(this.roundOff(0-sum)),
            toString: () => loadDeletedFormat(this.roundOff(0-sum))
        } : {
            LoadAdjustmentFlag: 1,
            LoadAdjustmentVolume: commaFormat(this.roundOff(sum)),
            toString: () => commaFormat(this.roundOff(sum))
        }
    },
};
    values = {
        columnDefs: [
            //{
            // valueGetter: params => {
            //     if (!params.data['IsChild'] || params.data['customer'] !== 'add-customer') {
            //         if (params.data['IsExpanded']) {
            //             return '-';
            //         } else {
            //             return '+';
            //         }
            //     } else {
            //         return '';
            //     }
            // },
            //     cellRendererFramework: AddCustomerButton,
            //     maxWidth: 60,
            //     colSpan: function(params) {
            //         if (params.data.customer === 'add-customer') {
            //             return 10;
            //         } else {
            //             return 0;
            //         }
            //     },
            //     cellClass: 'fontSize'
            // },
            {
                field: '',
                colId: 'radio',
                width: 48,
                cellRendererFramework: NominationSelectionRadio,
                // cellClass: params => {
                //     if (
                //         (params.data['IsNewDestination'] && params.data['IsNewDestination'] === 1) ||
                //         (params.data['IsNewOrigin'] && params.data['IsNewOrigin'] === 1)
                //     ) {
                //         return 'trailer-ques-bgColor-red';
                //     }
                //     return '';
                // },
                hide: this.isSupplierEditable() //removing this as now we need to enable the option for NGL and Crude to view/save load screen
            },
            {
                headerName: 'Customer Name',
                field: 'CustomerName',
                rowGroup: true,
                hide: true,
                editable: params => {
                    if (!params.node.group && params.data['IsEdit']) {
                        return true;
                    } else return false;
                },
                cellClass: params => {

                    if (!params.node.group && params.data['IsEdit']) {
                        return 'cell-editable';
                    } else return 'false';
                }
            },
            {
                headerName: 'Contract Number',
                field: 'ContractNumber',
                width: 80,
                editable: params => {
                    if (!params.node.group && params.data['IsEdit']) {
                        return true;
                    } else return false;
                },
                cellClass: params => {
                    if (!params.node.group && !params.data['IsChild']) {
                        return 'cellColor';
                    }
                    if (!params.node.group && params.data['IsEdit']) {
                        return 'cell-editable';
                    } else return 'false';
                },
                valueFormatter: params => {
                    return !params.node.group
                        ? params.data.ContractNumber &&
                            (params.data.ContractNumber === 'Dummy' ||
                                params.data.ContractNumber.startsWith('Contract') ||
                                params.data.CustomerName === 'New Location')
                            ? ''
                            : params.data.ContractNumber
                        : '';
                }
            },
            {
                headerName: 'Product',
                field: 'Product',
                width: 75,
                editable: params => {
                    if (!params.node.group && params.data['IsEdit']) {
                        return true;
                    } else return false;
                },
                cellClass: params => {
                    if (!params.node.group && !params.data['IsChild']) {
                        return 'cellColor';
                    }
                    if (!params.node.group && params.data['IsEdit']) {
                        return 'cell-editable';
                    } else return 'false';
                },
                valueFormatter: params => {
                    return !params.node.group
                        ? params.data.CustomerName && params.data.CustomerName === 'New Location'
                            ? ''
                            : params.data.Product
                        : '';
                }
            },
            {
                headerName: 'Data Source',
                field: 'DataSource',
                cellEditor: 'agSelectCellEditor',
                width: 70,
                editable: params => {
                    if (!params.node.group && params.data['IsEdit']) {
                        return true;
                    } else return false;
                },
                cellClass: params => {
                    if (!params.node.group && !params.data['IsChild']) {
                        return 'cellColor';
                    }
                    if (!params.node.group && params.data['IsEdit']) {
                        return 'cell-editable';
                    } else return 'false';
                }
            },
            {
                headerName: 'Origin',
                field: 'AX_Origin',
                colId: 'AX_Origin',
                width: 65,
                cellEditor: 'agSelectCellEditor',
                editable: params => {
                    if (!params.node.group && params.data['IsEdit']) {
                        return true;
                    } else return false;
                },
                cellClass: params => {
                    if (
                        !params.node.group &&
                        (params.data['IsNewOrigin'] && params.data['IsNewOrigin'] === 1)
                    )
                        return 'trailer-ques-bgColor-red';
                    if (!params.node.group && !params.data['IsChild']) {
                        return 'cellColor';
                    }
                    if (!params.node.group && params.data['IsEdit']) {
                        return 'cell-editable';
                    } else return 'false';
                },
                cellRendererParams: {
                    onChange: (item, value) => {
                        this.props.nominationDataStore.updateLocation(item, value, 'AX_Origin');
                    }
                }
            },
            {
                headerName: 'Destination',
                field: 'AX_Destination',
                colId: 'AX_Destination',
                width: 95,
                cellEditor: 'agSelectCellEditor',
                editable: params => {
                    if (!params.node.group && params.data['IsEdit']) {
                        return true;
                    } else return false;
                },
                cellClass: params => {
                    if (
                        !params.node.group &&
                        ((params.data['IsNewDestination'] && params.data['IsNewDestination'] === 1))
                    )
                        return 'trailer-ques-bgColor-red';
                    if (!params.node.group && !params.data['IsChild']) {
                        return 'cellColor';
                    }
                    if (!params.node.group && params.data['IsEdit']) {
                        return 'cell-editable';
                    } else return 'false';
                },
                cellRendererParams: {
                    onChange: (item, value) => {
                        this.props.nominationDataStore.updateLocation(item, value, 'AX_Destination');
                    }
                }
            },
            {
                headerName: 'Nomination Board Type',
                field: 'BoardMapped',
                width: 70,
                
            },
            {
                headerName: 'Initial Nomination',
                field: 'NominationVolume',
                width: 95,
                aggFunc: "aggSum",
                cellClass: params => {
                    return 'decimal-format';
                },
                valueGetter: params => {
                    return !params.node.group
                        ? (params.data.NominationVolume && params.data.NominationVolume !== null) ||
                            params.data.NominationVolume === 0
                            ? this.roundOff(params.data.NominationVolume)
                            : '0.000'
                        : '';
                },
                valueFormatter: params => {
                    return commaFormat(params.value)
                }
            },
            {
                headerName: 'Current Nomination',
                field: 'CurrentNomination',
                width: 95,
                aggFunc: "aggSum",
                cellClass: params => {
                    return 'decimal-format';
                },
                valueGetter: params => {
                    return !params.node.group
                        ? (params.data.CurrentNomination && params.data.CurrentNomination !== null) ||
                            params.data.CurrentNomination === 0
                            ? this.roundOff(params.data.CurrentNomination)
                            : '0.000'
                        : '';
                },
                valueFormatter: params => {
                    return commaFormat(params.value)
                }
            },
            {
                headerName: 'Override Nomination',
                field: 'EditNomination',
                width: 95,
                cellEditorFramework: EditNominationCellRenderer,
                editable: params => {
                    if (!params.node.group && params.data['IsChild']) return true;
                    else return false;
                },
                cellEditorParams: {
                    isDisabled: (item, value) => (this.checkDispatcherRole() && item['IsChild'] ? false : true),
                    onChange: (item, value) =>
                        this.props.nominationDataStore.updateEditNomination(item, value, 'EditNomination')
                },
                cellClass: params => {
                    if (!params.node.group && params.data['IsChild'] && this.checkDispatcherRole())
                        return ['cell-editable', 'decimal-format'];
                    else return '';
                },
                aggFunc: "aggSum",
                valueGetter: params => {
                    return !params.node.group
                        ? (params.data.EditNomination && params.data.EditNomination !== null)
                            ? this.roundOff(parseFloat(params.data.EditNomination))
                            : '0.000'
                        : '';
                },
                valueFormatter: params => {
                    return commaFormat(params.value)
                }
            },
            {
                headerName: 'Apportionment Volume',
                field: 'ApportionmentVolume',
                width: 115,
                aggFunc: "aggSum",
                cellClass: params => {
                    return 'decimal-format';
                },
                valueGetter: params => {
                    return !params.node.group
                        ? params.data.ApportionmentVolume && params.data.ApportionmentVolume !== null
                            ? this.roundOff(parseFloat(params.data.ApportionmentVolume))
                            : '0.000'
                        : '';
                },
                valueFormatter: params => {
                    return commaFormat(params.value)
                }
            },
            {
                headerName: 'Load Adjustments',
                field: 'LoadAdjustmentVolume',
                width: 100,
                aggFunc: "aggLoadAdjustments",
                cellClass: params => {
                    return !params.node.group ? params.data.LoadAdjustmentFlag == 0 ? ['cell-negative', 'decimal-format'] : ['decimal-format'] :
                                params.value.LoadAdjustmentFlag == 0 ? ['cell-negative', 'decimal-format'] : ['decimal-format']
                },
                valueGetter: params => {
                    return !params.node.group ? { LoadAdjustmentFlag: params.data.LoadAdjustmentFlag,
                                LoadAdjustmentVolume: (this.roundOff(params.data.LoadAdjustmentVolume)),
                                toString: () => (this.roundOff(params.data.LoadAdjustmentVolume)) 
                                } : { LoadAdjustmentFlag: params.value.LoadAdjustmentFlag,
                                    LoadAdjustmentVolume: (this.roundOff(params.value.LoadAdjustmentVolume)),
                                    toString: () => (this.roundOff(params.value.LoadAdjustmentVolume)) }
                },
                valueFormatter: params => {
                    return !params.node.group ? params.data.LoadAdjustmentFlag == 0 ? loadDeletedFormat(params.value) : commaFormat(params.value) : commaFormat(params.value)
                }
            },
            {
                headerName: 'Vols to be Trucked',
                field: 'VolsToBeTrucked',
                width: 85,
                aggFunc: "aggSum",
                cellClass: params => {
                    return ['cell-volume','decimal-format']
                },
                valueGetter: params => {
                    return !params.node.group
                        ? (params.data.VolsToBeTrucked &&
                            params.data.VolsToBeTrucked !== null &&
                            params.data.VolsToBeTrucked > 0) ||
                            params.data.VolsToBeTrucked === 0
                            ? this.roundOff(params.data.VolsToBeTrucked)
                            : '0.000'
                        : '';
                },
                valueFormatter: params => {
                    return commaFormat(params.value)
                }
            },
            {
                headerName: 'Vols to be Trucked per day',
                field: 'VolsToBeTruckedPerDay',
                width: 120,
                aggFunc: "aggSum",
                cellClass: params => {
                    return 'decimal-format';
                },
                valueGetter: params => {
                    let completed: any = (params.data['CompletedVolume'] != null && params.data['CompletedVolume'] > 0) 
                                            ? this.roundOff(parseFloat(params.data['CompletedVolume'])) : 0.0;
                    let VTT: any = this.roundOff(params.data.VolsToBeTrucked);
                    let VTTPerDay = (VTT - completed)/remainingDays(this.state.selectedYear, this.state.selectedMonth);
                    return !params.node.group
                        ? (params.data.VolsToBeTrucked &&
                            params.data.VolsToBeTrucked !== null &&
                            params.data.VolsToBeTrucked > 0 && VTTPerDay > 0) ||
                            (params.data.VolsToBeTrucked === 0 && VTTPerDay > 0)
                            ? this.roundOff(VTTPerDay)
                            : '0.000'
                        : '';
                },
                valueFormatter: params => {
                    return commaFormat(params.value)
                }
            },
            {
                headerName: 'Planned',
                field: 'PlannedVolume',
                width: 75,
                aggFunc: "aggSum",
                cellClass: params => {
                    return 'decimal-format';
                },
                valueGetter: params => {
                    return !params.node.group
                        ? (params.data['PlannedVolume'] && params.data['PlannedVolume'] > 0)
                            ? this.roundOff(parseFloat(params.data['PlannedVolume']))
                            : '0.000'
                        : '';
                },
                valueFormatter: params => {
                    return commaFormat(params.value)
                }
            },
            {
                headerName: 'Pending',
                field: 'PendingVolume',
                width: 75,
                aggFunc: "aggSum",
                cellClass: params => {
                    return 'decimal-format';
                },
                valueGetter: params => {
                    return !params.node.group
                        ? (params.data['PendingVolume'] && params.data['PendingVolume'] > 0)
                            ? this.roundOff(parseFloat(params.data['PendingVolume']))
                            : '0.000'
                        : '';
                },
                valueFormatter: params => {
                    return commaFormat(params.value)
                }
            },
            {
                headerName: 'Dispatched',
                field: 'DispatchedVolume',
                width: 91,
                aggFunc: "aggSum",
                cellClass: params => {
                    return 'decimal-format';
                },
                valueGetter: params => {
                    return !params.node.group
                        ? (params.data['DispatchedVolume'] && params.data['DispatchedVolume'] > 0)
                            ? this.roundOff(parseFloat(params.data['DispatchedVolume']))
                            : '0.000'
                        : '';
                },
                valueFormatter: params => {
                    return commaFormat(params.value)
                }
            },
            {
                headerName: 'Completed',
                field: 'CompletedVolume',
                width: 90,
                aggFunc: "aggSum",
                cellClass: params => {
                    return ['cell-volume','decimal-format']
                },
                valueGetter: params => {
                    return !params.node.group
                        ? (params.data['CompletedVolume'] && params.data['CompletedVolume'] > 0)
                            ? this.roundOff(parseFloat(params.data['CompletedVolume']))
                            : '0.000'
                        : '';
                },
                valueFormatter: params => {
                    return commaFormat(params.value)
                }
            },
            {
                headerName: 'Total Order Volume',
                aggFunc: "aggSum",
                width: 90,
                cellClass: params => {
                    if(!params.node.group) {
                        let pending = (params.data['PendingVolume'] != null && params.data['PendingVolume'] > 0) ? params.data['PendingVolume'] : 0.0
                        let dispatched = (params.data['DispatchedVolume'] != null && params.data['DispatchedVolume'] > 0) ? params.data['DispatchedVolume'] : 0.0
                        let completed = (params.data['CompletedVolume'] != null && params.data['CompletedVolume'] > 0) ? params.data['CompletedVolume'] : 0.0
                        let tov = (pending + dispatched + completed <= 0) || (params.data['PendingVolume'] === null && params.data['DispatchedVolume'] === null && params.data['CompletedVolume'] === null)
                                ? '0.000' : this.roundOff(parseFloat( pending + dispatched + completed ))
                        if((params.data.VolsToBeTrucked) < tov) return ['cell-red','decimal-format']
                        } else {
                            let aggVTT = parseFloat(params.node.aggData.VolsToBeTrucked)
                            let aggTOV = parseFloat(params.node.aggData.PendingVolume) + parseFloat(params.node.aggData.DispatchedVolume) + parseFloat(params.node.aggData.CompletedVolume)
                            if(aggVTT < aggTOV) return ['cell-red','decimal-format']
                        }
                        return 'decimal-format';
                },
                valueGetter: params => {
                    let pending = (params.data['PendingVolume'] != null && params.data['PendingVolume'] > 0) ? params.data['PendingVolume'] : 0.0
                    let dispatched = (params.data['DispatchedVolume'] != null && params.data['DispatchedVolume'] > 0) ? params.data['DispatchedVolume'] : 0.0
                    let completed = (params.data['CompletedVolume'] != null && params.data['CompletedVolume'] > 0) ? params.data['CompletedVolume'] : 0.0
                    return !params.node.group
                        ? (pending + dispatched + completed <= 0) ||
                          (params.data['PendingVolume'] === null &&
                              params.data['DispatchedVolume'] === null &&
                              params.data['CompletedVolume'] === null)
                            ? '0.000'
                            : this.roundOff(
                                  parseFloat( pending + dispatched + completed )
                              )
                        : '';
                },
                valueFormatter: params => {
                    return commaFormat(params.value)
                }
            },
            {
                headerName: '% Completed',
                aggFunc: "aggSumCompleted%",
                width: 105,
                cellClass: params => {
                    return ['cell-volume','decimal-format']
                },
                valueGetter: params => {
                    if (!params.node.group) {

                        let pending = (params.data['PendingVolume'] != null && params.data['PendingVolume'] > 0) ? params.data['PendingVolume'] : 0.0
                        let dispatched = (params.data['DispatchedVolume'] != null && params.data['DispatchedVolume'] > 0) ? params.data['DispatchedVolume'] : 0.0
                        let completed = (params.data['CompletedVolume'] != null && params.data['CompletedVolume'] > 0) ? params.data['CompletedVolume'] : 0.0
                        let VTT = (params.data['VolsToBeTrucked'] != null && params.data['VolsToBeTrucked'] > 0) ? params.data['VolsToBeTrucked'] : 0.0
                        
                        if(params.data['VolsToBeTrucked'] + params.data['CompletedVolume'] === 0) {
                            return {
                                CompletedVolume: params.data['CompletedVolume'],
                                VolsToBeTrucked: params.data['VolsToBeTrucked'],
                                TotalOrderVolume: params.data['PendingVolume'] + params.data['DispatchedVolume'] + params.data['CompletedVolume'],
                                toString: () => '0.000'
                            }
                        }
                        else {
                            if (params.data['CompletedVolume'] === null || params.data['CompletedVolume'] <= 0) {
                                return {
                                    CompletedVolume: completed,
                                    VolsToBeTrucked: VTT,
                                    TotalOrderVolume: pending + dispatched + completed,
                                    toString: () => '0.000'
                                }
                            }
                            else {
                                if(params.data['VolsToBeTrucked'] <= 0 || params.data['VolsToBeTrucked'] === null) {
                                    if(pending + dispatched + completed === 0){
                                        return {
                                            CompletedVolume: completed,
                                            VolsToBeTrucked: VTT,
                                            TotalOrderVolume: pending + dispatched + completed,
                                            toString: () => '0.000'
                                        } 
                                    }  
                                else {
                                    return {
                                        CompletedVolume: completed,
                                        VolsToBeTrucked: VTT,
                                        TotalOrderVolume: pending + dispatched + completed,
                                        toString: () => this.roundOff((completed / (pending + dispatched + completed)) * 100),
                                    }  
                                }}
                                else return {
                                    CompletedVolume: completed,
                                    VolsToBeTrucked: VTT,
                                    TotalOrderVolume: pending + dispatched + completed,
                                    toString: () => this.roundOff((completed / VTT) * 100)
                                } 
                            }
                        }
                    } else return ''
                },
                valueFormatter: params => {
                    return commaFormat(params.value)
                }
            },
            {
                headerName: 'Nomination Comment',
                field: 'Comment',
                width: 95,
                editable: params => {
                    if (!params.node.group && params.data['IsChild'] && this.checkSupplierRole()) return true;
                    else return false;
                },
                cellClass: params => {
                    if (!params.node.group && params.data['IsChild'] && this.checkSupplierRole())
                        return 'cell-editable';
                    else return '';
                }
            },
            {
                headerName: 'Dispatcher Comment',
                field: 'DispatcherComment',
                width: 90,
                cellEditorFramework: TextAreaCellRenderer,
                editable: params => {
                    if (!params.node.group && params.data['IsChild']) return true;
                    else return false;
                },
                cellEditorParams: {
                    isDisabled: (item, value) => (this.checkDispatcherRole() && item['IsChild'] ? false : true),
                    onChange: (item, value) =>
                        this.props.nominationDataStore.updateDispatcherComment(item, value, 'DispatcherComment')
                },
                cellClass: params => {
                    if (!params.node.group && params.data['IsChild'] && this.checkDispatcherRole())
                        return 'cell-editable';
                    else return '';
                },
                valueGetter: params => {
                    if (!params.node.group && params.data['DispatcherComment'] === null) {
                        return '';
                    } else {
                        return !params.node.group ? params.data['DispatcherComment'] : '';
                    }
                }
            },
            {
                headerName: 'Shipper Name',
                field: 'ShipperName',
                width: 75,
                cellEditor: 'agSelectCellEditor',
                editable: params => {
                    if (!params.node.group && params.data['IsEdit']) {
                        return true;
                    } else return false;
                },
                cellClass: params => {
                    if (!params.node.group && params.data['IsEdit']) {
                        return 'cell-editable';
                    } else return 'false';
                }
            },
            {
                headerName: 'From Code',
                field: 'FromCode',
                width: 60,
                cellClass: params => {
                    return '';
                },
                valueGetter: params => {
                    return !params.node.group && params.data.FromCode && params.data.FromCode !== null
                        ? params.data.FromCode
                        : '';
                }
            },
            {
                headerName: 'From Facility',
                field: 'FromFacility',
                width: 70,
                cellClass: params => {
                    return '';
                },
                valueGetter: params => {
                    return !params.node.group && params.data.FromFacility && params.data.FromFacility !== null
                        ? params.data.FromFacility
                        : '';
                }
            },
            {
                headerName: 'Created By',
                field: 'CreatedBy',
                width: 75,
                cellClass: params => {
                    return '';
                }
            },
            {
                headerName: 'Created Date',
                field: 'CreatedDate',
                width: 75,
                cellClass: params => {
                    return '';
                },
                valueGetter: params => {
                    if (!params.node.group && params.data['CreatedDate'] === null) {
                        return '';
                    } else {
                        return !params.node.group
                            ? momenttimezone
                                .tz(moment.utc(params.data['CreatedDate']), AppConstant.MST_TIME_FORMATTER)
                                .format(AppConstant.DATE_TIME_FORMAT)
                            : '';
                    }
                }
            },
            {
                headerName: 'Modified By',
                field: 'ModifiedBy',
                width: 80,
                cellClass: params => {
                    return '';
                }
            },
            {
                headerName: 'Modified Date',
                field: 'ModifiedDate',
                width: 80,
                cellClass: params => {
                    return '';
                },
                valueGetter: params => {
                    if (!params.node.group && params.data['ModifiedDate'] === null) {
                        return '';
                    } else {
                        return !params.node.group
                            ? momenttimezone
                                .tz(moment.utc(params.data['ModifiedDate']), AppConstant.MST_TIME_FORMATTER)
                                .format(AppConstant.DATE_TIME_FORMAT)
                            : '';
                    }
                }
            }
        ]
    };
    nominationCSV: any;
    accountStore: any;
    locationDataSerivice: any;
    uiService: any;
    nominationData: any = [];
    nominationLoadData: any = [];
    checkeddata: any = [];
    isSupplier: any;
    operators: any[] = [];
    selectedProduct: any = 'All';
    isApplied = false;
    applied = false;
    selectedNomination: any = {};
    optionType: any = '';
    adjustmentVolume: any = 0;
    shipperType: any = [];
    plainsVolume = 0;
    nonplainsVolume = 0;
    allActiveDriverValues: any[] = [];
    companyInfo: any[] = [];
    baseValueDTO: any[] = [];
    userID: any;
    daysInMonth(month, year) {
        return new Date(year, month, 0).getDate();
    }
    rowData: any;
    startDate: any = moment().subtract(6, 'days').toISOString().slice(0, 10);;
    endDate: any = moment().toISOString().slice(0, 10);
    rowfield: any = {};
    nominaData: any[] = [];
    load = false;
    updateApportionmentCounter = 5;
    @observable VolumeSum: any = {
        VTTSum: 0,
        TOVSum: 0,
        NominationSum: 0,
        Completedpercentage: 0,
        CompletedSum: 0
    };
    constructor(props) {
        super(props);
        const datesOfMonth = Array.from(
            Array(this.daysInMonth(new Date().getMonth() + 1, new Date().getFullYear())),
            (_, i) => i + 1
        );
        this.state = {
            selectedMonth: new Date().getMonth(),
            currentMonth: new Date().getMonth(),
            currentYear: new Date().getFullYear(),
            selectedYear: new Date().getFullYear(),
            datesOfMonth: datesOfMonth,
            count: 0,
            selectedRegion: 'All',
            selectedRegionByRole: '',
            selectedProduct: 'All'
        };
        dataStore.setModuleName('Nomination');
    }
    roundOff(value) {
        return (Math.round((value + Number.EPSILON) * 1000) / 1000).toFixed(3);
    }
    getApportionmentVolume(data) {
        if (
            this.props.nominationDataStore.apportionmentData.length > 0 &&
            this.props.nominationDataStore.apportionmentData[0].IsApply &&
            data.IsChild &&
            data.DataSource === 'Crude'
        ) {
            const filterData = this.props.nominationDataStore.apportionmentData.filter(x => {
                return (
                    (x.FacilityCode == data.ToCode && x.Product === data.Product) ||
                    (x.ToFacilityDescription == data.Destination && x.Product === data.Product)
                );
            });
            if (filterData.length > 0) {
                if (
                    data.customer === 'add-customer' ||
                    data['PendingVolume'] + data['DispatchedVolume'] + data['CompletedVolume'] === 0
                ) {
                    return data['ApportionmentVolume'] ? data['ApportionmentVolume'] : null;
                } else if (filterData[0].Apportionment > 0) {
                    if (data['ApportionmentDate'] === null) {
                        return data.ShipperName === 'Plains Midstream Canada ULC'
                            ? parseFloat(
                                (
                                    data['PendingVolume'] +
                                    data['DispatchedVolume'] +
                                    data['CompletedVolume'] -
                                    (filterData[0].Apportionment / 100) *
                                    (data['PendingVolume'] + data['DispatchedVolume'] + data['CompletedVolume'])
                                ).toFixed(3)
                            )
                            : null;
                    } else {
                        return data['ApportionmentVolume'];
                    }
                } else {
                    return null;
                }
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    checkSupplierRole() {
        let access = false;
        const currentUserRoles = accountStore.userRoles;
        currentUserRoles.forEach(userRole => {
            if (!access) {
                if (suppliersRoles.includes(userRole)) {
                    access = true;
                }
            }
        });
        return access;
    }

    checkDispatcherRole() {
        let access = false;
        const currentUserRoles = accountStore.userRoles;
        if (currentUserRoles.includes('ptsdispatcher') || currentUserRoles.includes('ptstruckingmanager') || currentUserRoles.includes('ptssysadmin')) {
            access = true;
        }
        return access;
    }

    async componentDidMount() {
        this.props.nominationDataStore.selectedMonth = this.state.selectedMonth;
        this.props.nominationDataStore.selectedYear = this.state.selectedYear;
        this.props.nominationDataStore.selectedProduct = this.state.selectedProduct;
        const data = await this.props.nominationDataStore.getApportionmentData();
        this.nominationCSV = this.props.nominationDataStore.agGridService.getNodes();
        this.props.nominationDataStore.nominationGrid = this.nominationCSV;
        if (data) {
            this.getNominationData(
                this.state.selectedRegion,
                this.state.selectedProduct,
                this.state.selectedMonth,
                this.state.selectedYear
            );
            this.getOperators();
        }
        this.isSupplier = this.checkSupplierRole();
        if (this.isSupplier) {
            const filters: any = localStorageService.get('Nomination_filter');
            if (filters && Object.keys(filters) && Object.keys(filters).length > 0) {
                this.props.nominationDataStore.navigated = true;
            } else {
                this.props.nominationDataStore.navigated = false;
            }

            if (accountStore.userRoles.includes('ptssupplierbutanengl')) {
                this.selectedProduct = 'Butane';
                this.setState({
                    selectedRegionByRole: 'All',
                    selectedRegion: 'All',
                    selectedProduct: 'All'
                });
            } else if (accountStore.userRoles.includes('ptssupplierbutane')) {
                this.selectedProduct = 'Butane';
                this.setState({
                    selectedRegionByRole: 'All',
                    selectedRegion: 'All',
                    selectedProduct: 'Butane'
                });
            } else if (accountStore.userRoles.includes('ptssupplierngl')) {
                this.selectedProduct = 'NGL';
                this.setState({
                    selectedRegionByRole: 'All',
                    selectedRegion: 'All',
                    selectedProduct: 'NGL'
                });
            } else if (accountStore.userRoles.includes('ptssuppliercrude')) {
                this.selectedProduct = 'Crude';
                this.setState({
                    selectedRegionByRole: 'All',
                    selectedRegion: 'All',
                    selectedProduct: 'Crude'
                });
            } else if (accountStore.userRoles.includes('PTSSupplierBrine')) {
                this.selectedProduct = 'Brine';
                this.setState({
                    selectedRegionByRole: 'All',
                    selectedRegion: 'All',
                    selectedProduct: 'Brine'
                });
            }
        }

        this.props.nominationDataStore.selectedMonth = this.state.selectedMonth;
        this.props.nominationDataStore.selectedYear = this.state.selectedYear;
        this.props.nominationDataStore.selectedProduct = this.selectedProduct;

    }

    async getOperators() {
        this.operators = await nominationDataService.getOperator();
        if (!this.load) this.props.nominationDataStore.addValuesInCellDropdowns();
    }

    async updateApportionment(nominationData) {
        const filteredCrude = nominationData.filter(x => {
            return (
                x.DataSource === 'Crude' &&
                x.ShipperName === 'Plains Midstream Canada ULC' &&
                x.ApportionmentDate === null
            );
        });
        if (filteredCrude.length > 0 && this.updateApportionmentCounter > 0) {
            const res = await this.props.nominationDataStore.updateApportionmentValue(filteredCrude);
            this.updateApportionmentCounter -= 1;
            this.getNominationData(
                this.state.selectedRegion,
                this.state.selectedProduct,
                this.state.selectedMonth,
                this.state.selectedYear
            );
        }
    }

    async updateApportionmentValue(filteredCrude) {
        let userID = accountStore.getUserID();
        if (!userID || userID === 0) {
            await accountStore.getLoggedInUserDetailsIfUserIdZero(accountStore.userName).then(() => {
                userID = accountStore.getUserID();
            });
            if (!userID || userID === 0) {
                return;
            }
        }
        const requestBody = {
            NominationMonth: this.state.selectedMonth + 1,
            NominationYear: this.state.selectedYear,
            CreatedBy: userID,
            NominationData: filteredCrude,
            ModifiedBy: userID
        };
        uiService.loaderService.showLoader();
        nominationDataService
            .createUpdateNominationData(requestBody)
            .then(response => {
                uiService.loaderService.hideLoader();
                this.getNominationData(
                    this.state.selectedRegion,
                    this.state.selectedProduct,
                    this.state.selectedMonth,
                    this.state.selectedYear
                );
                if(response['StatusCode'] === 200) {
                    uiService.toastService.success('Saved Successfully');
                }
                this.props.nominationDataStore.cellValueChangeMap = {};
            })
            .catch(error => {
                uiService.loaderService.hideLoader();
                this.getNominationData(
                    this.state.selectedRegion,
                    this.state.selectedProduct,
                    this.state.selectedMonth,
                    this.state.selectedYear
                );
                this.props.nominationDataStore.cellValueChangeMap = {};
            });
    }

    @Loader
    @Catch(() => errorHandler('Could not load Nomination Data'))
    async getNominationData(
        // nominationStore: NominationStore,
        selectedRegion,
        selectedProduct,
        selectedMonth,
        selectedYear
    ) {
        const reqbody: NominationDataRequest = {
            NominationMonth: selectedMonth + 1,
            NominationYear: selectedYear
        };
        // this.props.nominationDataStore.nominaData = [];
        this.nominationData = [];
        const response = await nominationDataService.getNominationData(reqbody);
        this.props.nominationDataStore.setfullNomination(response.data['Data']);
        if (accountStore.userRoles.includes('ptssupplierbutanengl')) {
            this.nominationData = response.data['Data'].filter(element => {
                return element.DataSource === 'Butane' || element.DataSource === 'NGL';
            });
        } else if (accountStore.userRoles.includes('ptssupplierbutane')) {
            this.nominationData = response.data['Data'].filter(element => {
                return element.DataSource === 'Butane';
            });
        } else if (accountStore.userRoles.includes('ptssupplierngl')) {
            this.nominationData = response.data['Data'].filter(element => {
                return element.DataSource === 'NGL';
            });
        } else if (accountStore.userRoles.includes('ptssuppliercrude')) {
            this.nominationData = response.data['Data'].filter(element => {
                return element.DataSource === 'Crude' && element.ShipperName === 'Plains Midstream Canada ULC';
            });
        } else if (accountStore.userRoles.includes('PTSSupplierBrine')) {
            this.nominationData = response.data['Data'].filter(element => {
                return element.DataSource === 'Brine';
            });
        } else {
            this.nominationData = response.data['Data'];
        }

        const crudeData = Array.from(
            new Set(
                this.nominationData.filter(x => {
                    return x.DataSource === 'Crude' && x.ShipperName === 'Plains Midstream Canada ULC';
                })
            )
        );
        const apportionmentdata: any = Array.from(
            new Set(
                this.nominationData.filter(x => {
                    return (
                        x.DataSource === 'Crude' &&
                        x.ShipperName === 'Plains Midstream Canada ULC' &&
                        x.ApportionmentDate !== null &&
                        !x.IsApportioned
                    );
                })
            )
        );
        const data: any = Array.from(
            new Set(
                this.nominationData.filter(x => {
                    return (
                        x.DataSource === 'Crude' &&
                        x.ShipperName === 'Plains Midstream Canada ULC' &&
                        x.ApportionmentDate === null &&
                        !x.IsApportioned
                    );
                })
            )
        );
        if (
            this.props.nominationDataStore.apportionmentData &&
            this.props.nominationDataStore.apportionmentData.length > 0
        ) {
            this.isApplied = this.props.nominationDataStore.apportionmentData[0]?.IsApply && crudeData.length > 0;
            this.applied =
                this.props.nominationDataStore.apportionmentData[0]?.IsApply && apportionmentdata.length === 0;
        }
        // if (
        //     this.props.nominationDataStore.apportionmentData[0].IsApply &&
        //     crudeData.length > 0 &&
        //     data.length === crudeData.length
        // ) {
        //     this.isApplied = false;
        //     uiService.toastService.error('Error occured on Applying Apportionment');
        // }
        this.nominationData.forEach(data => {
            data.IsChild = true;
            data.IsSelected = false;
            if (data.DataSource !== 'Crude') {
                data.PendingVolume = data.PendingVolume ? data.PendingVolume : 0.0;
                data.DispatchedVolume = data.DispatchedVolume ? data.DispatchedVolume : 0.0;
                data.CompletedVolume = data.CompletedVolume ? data.CompletedVolume : 0.0;
                data.ApportionmentVolume = data.ApportionmentVolume ? data.ApportionmentVolume : null;
                data.VolsToBeTrucked = data.VolsToBeTrucked ? data.VolsToBeTrucked : null;
            }
            // this.props.nominationDataStore.apportionmentData[0].IsApply && dateUtils.isDateSameAsCurrentDate(this.props.nominationDataStore.apportionmentData[0].ModifiedDate)
            //     ? this.getApportionmentVolume(data)
            //     : data.ApportionmentVolume;
        });

        if (accountStore.userRoles.includes('ptssuppliercrude')) {
            this.nominationData = this.nominationData.filter(product => {
                return (
                    product.DataSource === 'Crude' &&
                    product.ShipperName === 'Plains Midstream Canada ULC' &&
                    product.VolumeRatio !== 0
                );
            });
        }

        let filteredProduct = this.nominationData;
        // if (this.props.nominationDataStore.apportionmentData[0].IsApply && dateUtils.isDateSameAsCurrentDate(this.props.nominationDataStore.apportionmentData[0].ModifiedDate))
        //     await this.updateApportionment(this.nominationData);
        if (this.state.selectedProduct === 'All') {
            // filteredProduct = this.nominationData.filter(product => {
            //     return product.DataSource === 'Butane' || product.DataSource === 'NGL' || product.DataSource === null;
            // });
            if (this.state.selectedRegion === 'All') {
            } else {
                filteredProduct = filteredProduct.filter(product => {
                    return product.Region === this.state.selectedRegion;
                });
            }
        } else {
            if (accountStore.userRoles.includes('ptssupplierbutanengl')) {
                if (this.state.selectedProduct !== 'All') {
                    filteredProduct = this.nominationData.filter(product => {
                        return product.DataSource === this.state.selectedProduct || product.DataSource === null;
                    });
                }
                if (this.state.selectedRegion === 'All') {
                } else {
                    filteredProduct = filteredProduct.filter(product => {
                        return product.Region === this.state.selectedRegion;
                    });
                }
            } else {
                filteredProduct = this.nominationData.filter(product => {
                    return product.DataSource === this.state.selectedProduct || product.DataSource === null;
                });
                if (this.state.selectedRegion === 'All') {
                } else {
                    filteredProduct = filteredProduct.filter(product => {
                        return product.Region === this.state.selectedRegion;
                    });
                }
            }
        }
        uiService.loaderService.hideLoader();
        this.props.nominationDataStore.setBackupnomination(filteredProduct);
        this.props.nominationDataStore.nomiData(filteredProduct);
    }
    isSaveDisabled = (): any => {
        if (
            (this.state.currentMonth > this.state.selectedMonth && this.state.currentYear == this.state.selectedYear) ||
            this.state.currentYear > this.state.selectedYear
        ) {
            return true;
        } else {
            return false;
        }
    };

    isDisabled = (): any => {
        if (
            (this.state.currentMonth > this.state.selectedMonth && this.state.currentYear == this.state.selectedYear) ||
            this.state.currentYear > this.state.selectedYear
        ) {
            return true;
        } else {
            return false;
        }
    };

    isSupplierEditable() {
        if (accountStore.userRoles.includes('ptssuppliercrude')) {
            // restricting loa screen access only to crude
            return true;
        } else {
            return false;
        }
    }

    handleChange = selectedOption => {
        // this.setState({ selectedOption });
    };

    getFormattedDate(date) {
        date = new Date(date.toISOString());
        const year = date.getFullYear();

        let month = (1 + date.getMonth()).toString();
        month = month.length > 1 ? month : '0' + month;

        let day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;

        return month + '/' + day + '/' + year;
    }
    isValidDate(date) {
        return new Date(date) instanceof Date && !isNaN(date);
    }

    isValidFormat(date) {
        return moment(new Date(Math.round((parseInt(date) - 25569) * 86400 * 1000)), 'DD/MM/YYYY', true).isValid();
    }

    @Catch(() => errorHandler('Error uploading file'))
    handleImport = async () => {
        this.maxRowsReached = false;
        this.props.nominationDataStore.errorMessage = [];
        const confirmService = new ConfirmService();
        confirmService.showExcelPopup(async consignor => {

            let userID = accountStore.getUserID();
            if (!userID || userID === 0) {
                await accountStore.getLoggedInUserDetailsIfUserIdZero(accountStore.userName).then(() => {
                    userID = accountStore.getUserID();
                });
                if (!userID || userID === 0) {
                    return;
                }
            }
            const reqbody = {
                CreatedBy: userID,
                ModifiedBy: userID,
                ReportJson: consignor
            };
            consignor.forEach((data, index) => {
                if (data.Date == null)
                    this.props.nominationDataStore.errorMessage.push(`Import file is missing Date on row ${index + 1}.`)
                else if (data.Date && (parseInt(data.Date) - 25569 < 0 || !this.isValidFormat(data.Date)))
                    this.props.nominationDataStore.errorMessage.push(`Incorrect Date format on row ${index + 1}. Date format is dd/mm/yyyy.`)
                else if (data.Date && this.isValidDate(data.Date))
                    data.Date = this.getFormattedDate(new Date(Math.round((parseInt(data.Date) - 25569) * 86400 * 1000)))
            }
            );
            if (consignor.length > 50 || consignor.length === 0) this.maxRowsReached = true;
            confirmService.showExcelValidatorPopup(() => {
            }, this.props.nominationDataStore.responseData, false, this.maxRowsReached, consignor.length, this.props.nominationDataStore.errorMessage)

            if (this.maxRowsReached === false && this.props.nominationDataStore.errorMessage.length === 0) {
                uiService.loaderService.showLoader();
                const response = await this.props.nominationDataStore.nominationDataService.uploadExcel(reqbody);
                uiService.loaderService.hideLoader();
                this.props.nominationDataStore.responseData = response.data['Data'];

                confirmService.showExcelValidatorPopup(() => {
                }, this.props.nominationDataStore.responseData, true, this.maxRowsReached, consignor.length, this.props.nominationDataStore.errorMessage)
                this.getNominationData(
                    this.state.selectedRegion,
                    this.state.selectedProduct,
                    this.state.selectedMonth,
                    this.state.selectedYear
                );
            }
        });
    };

    handleChangeMonth = async month => {
        this.setState({
            selectedMonth: moment.months().indexOf(month),
            datesOfMonth: Array.from(
                Array(this.daysInMonth(moment.months().indexOf(month) + 1, this.state.selectedYear)),
                (_, i) => i + 1
            )
        });
        this.props.nominationDataStore.selectedMonth = moment.months().indexOf(month);
        this.updateApportionmentCounter = 5;
        const data = await this.props.nominationDataStore.getApportionmentData();
        if (data) {
            this.getNominationData(
                this.state.selectedRegion,
                this.state.selectedProduct,
                moment.months().indexOf(month),
                this.state.selectedYear
            );
        }
    };

    handleChangeYear = async year => {
        this.setState({
            selectedYear: year,
            datesOfMonth: Array.from(Array(this.daysInMonth(this.state.selectedMonth + 1, year)), (_, i) => i + 1)
        });
        this.props.nominationDataStore.selectedYear = year;
        this.updateApportionmentCounter = 5;
        const data = await this.props.nominationDataStore.getApportionmentData();
        if (data) {
            this.getNominationData(
                this.state.selectedRegion,
                this.state.selectedProduct,
                this.state.selectedMonth,
                year
            );
        }
    };
    handleProductChange = event => {
        // const filteredProduct = this.nominationData.filter(product => {
        //     return product.DataSource === event.currentTarget.value;
        // });
        this.setState({
            selectedProduct: event.currentTarget.value
        });
        this.props.nominationDataStore.selectedProduct = event.currentTarget.value;
        let filteredProduct = this.nominationData;
        if (event.currentTarget.value === 'All') {
            // filteredProduct = this.nominationData.filter(product => {
            //     return product.DataSource === 'Butane' || product.DataSource === 'NGL' || product.DataSource === null;
            // });
            if (this.state.selectedRegion === 'All') {
            } else {
                filteredProduct = filteredProduct.filter(product => {
                    return product.Region === this.state.selectedRegion;
                });
            }
        } else {
            if (accountStore.userRoles.includes('ptssupplierbutanengl')) {
                if (event.currentTarget.value !== 'All') {
                    filteredProduct = this.nominationData.filter(product => {
                        return product.DataSource === event.currentTarget.value || product.DataSource === null;
                    });
                }
                if (this.state.selectedRegion === 'All') {
                } else {
                    filteredProduct = filteredProduct.filter(product => {
                        return product.Region === this.state.selectedRegion;
                    });
                }
            } else {
                filteredProduct = this.nominationData.filter(product => {
                    return product.DataSource === event.currentTarget.value || product.DataSource === null;
                });
                if (this.state.selectedRegion === 'All') {
                } else {
                    filteredProduct = filteredProduct.filter(product => {
                        return product.Region === this.state.selectedRegion;
                    });
                }
            }
        }
        this.props.nominationDataStore.nomiData(filteredProduct);
    };

    handleRegionChange = event => {
        this.setState({
            selectedRegion: event.currentTarget.value
        });
        let filteredProduct = this.nominationData;
        if (this.state.selectedProduct === 'All') {
            // filteredProduct = this.nominationData.filter(product => {
            //     return product.DataSource === 'Butane' || product.DataSource === 'NGL' || product.DataSource === null;
            // });
            if (event.currentTarget.value === 'All') {
            } else {
                filteredProduct = filteredProduct.filter(product => {
                    return product.Region === event.currentTarget.value;
                });
            }
        } else {
            if (accountStore.userRoles.includes('ptssupplierbutanengl')) {
                if (this.state.selectedProduct !== 'All') {
                    filteredProduct = this.nominationData.filter(product => {
                        return product.DataSource === this.state.selectedProduct || product.DataSource === null;
                    });
                }
                if (event.currentTarget.value === 'All') {
                } else {
                    filteredProduct = filteredProduct.filter(product => {
                        return product.Region === event.currentTarget.value;
                    });
                }
            } else {
                filteredProduct = this.nominationData.filter(product => {
                    return product.DataSource === this.state.selectedProduct || product.DataSource === null;
                });
                if (event.currentTarget.value === 'All') {
                } else {
                    filteredProduct = filteredProduct.filter(product => {
                        return product.Region === event.currentTarget.value;
                    });
                }
            }
        }
        this.props.nominationDataStore.selectedRegion = event.currentTarget.value;
        this.props.nominationDataStore.nomiData(filteredProduct);
    };
    handleRoleChange = (): boolean => {
        if (this.isSupplier && !accountStore.userRoles.includes('ptssupplierbutanengl')) {
            return true;
        } else return false;
    };

    handlePlainsVolumeVisibility = () => {
        this.props.nominationDataStore.hidePlainsRatioPopUp();
    };

    handlePlainsVolumeSubmission = (plainsRatio: string) => {
        this.props.nominationDataStore.updatePlainsRatioPopup(plainsRatio);
    };

    render() {
        const selectedValue: any = {};
        const monthNames = moment.months();
        const getYear = new Date().getFullYear();
        const years = Array.from(new Array(25), (val, index) => getYear + (index - 5));
        const region = [
            { value: 'All', ID: '0' },
            { value: 'North East', ID: '1' },
            { value: 'North West', ID: '2' },
            { value: 'South East', ID: '3' },
            { value: 'South West', ID: '4' }
        ];
        const allproduct = [
            { value: 'All', ID: '0' },
            { value: 'NGL', ID: '1' },
            { value: 'Crude', ID: '2' },
            { value: 'Butane', ID: '3' },
            { value: 'Brine', ID: '4' }
        ];

        const supplierButanengl = [
            { value: 'All', ID: '0' },
            { value: 'NGL', ID: '1' },
            { value: 'Butane', ID: '3' }
        ];

        const product = accountStore.userRoles.includes('ptssupplierbutanengl') ? supplierButanengl : allproduct;

        const { selectedMonth, selectedYear, selectedRegionByRole, selectedProduct } = this.state;
        this.load = this.props.nominationDataStore.isLoadPopupVisible;
        if (this.props.nominationDataStore.isLoadPopupVisible) {
            dataStore.setModuleName('NominationLoad');
        }
        return (
            <>
                {this.props.nominationDataStore.isLoadPopupVisible &&
                    <NominationLoadsPopup
                        nominationDataStore={this.props.nominationDataStore}
                        getLoadGridConfig={this.getLoadGridConfig()}
                        selectButtonText={this.props.nominationDataStore.selectButtonText}
                        checkSupplierRole={this.checkSupplierRole()}
                        selectedMonth={monthNames[selectedMonth]}
                        selectedYear={this.state.selectedYear}
                        selectedRegion={this.state.selectedRegion}
                        activeDrivers={this.allActiveDriverValues}
                        NominationSum={this.VolumeSum.NominationSum}
                        VTTSum={this.VolumeSum.VTTSum}
                        TOVSum={this.VolumeSum.TOVSum}
                        CompletedSum={this.VolumeSum.CompletedSum}
                        Completedpercentage={this.VolumeSum.Completedpercentage}
                        isDisabled={this.isDisabled()}
                        isSaveDisabled={this.isSaveDisabled()}
                        planLoadsDisabled={this.props.nominationDataStore.isDeleteDisabled() || this.props.nominationDataStore.isNglLoad() || this.isDisabled()}
                        selectAllDisabled={!this.props.nominationDataStore.nominationLoadData.some(item => item.IsDeleted === false)}
                        handleNewRecord={this.handleNewRecord}
                        handleRegister={this.handleRegister}
                        handleReset={this.handleReset}
                        handleEdit={this.handleEdit}
                        handleSelectAll={this.handleSelectAll}
                        exportExcel={this.exportExcel}
                        handleCancel={this.handleCancel}
                    />}
                <PlainsVolumePopup
                    onPlainsVolumeSubmission={this.handlePlainsVolumeSubmission}
                    onPlainsVolumeVisibility={this.handlePlainsVolumeVisibility}
                    showPopup={this.props.nominationDataStore.isApproverPopUpVisible}
                />
                <Col>
                    {this.isApplied && !this.applied && (
                        <div
                            className="mt-sm-0"
                            style={{ fontSize: 16, textAlign: 'center', padding: '5px', color: 'red' }}
                        >
                            Apportionment calculation is in progress.{' '}
                        </div>
                    )}
                    {/* {this.isApplied && this.applied && (
                            <div className="mt-3 mt-sm-0" style={{ fontSize: 16, textAlign: 'left', padding: '5px' }}>
                                Apportionment has been applied.{' '}
                            </div>
                        )} */}
                </Col>
                <div className="react-select-container">
                    <div className="react-select-inner-container">
                        <b>Select: </b> <p>Month</p>
                        <select
                            name="month"
                            value={monthNames[selectedMonth]}
                            onChange={e => this.handleChangeMonth(e.currentTarget.value)}
                            disabled={this.load}
                            className="react-select-style"
                        >
                            {monthNames.map((month, i) => (
                                <option key={month} value={month}>
                                    {month}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="react-select-inner-container">
                        <p>Year</p>
                        <select
                            name="year"
                            value={selectedYear}
                            className="react-select-style"
                            disabled={this.load}
                            onChange={e => this.handleChangeYear(parseInt(e.currentTarget.value, 10))}
                        >
                            {years.map(yearData => (
                                <option key={yearData} value={yearData}>
                                    {yearData}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="react-select-inner-container">
                        <p>Product</p>
                        <select
                            name="product"
                            value={this.state.selectedProduct}
                            className="react-select-style"
                            onChange={this.handleProductChange}
                            disabled={this.handleRoleChange() || this.load}
                        >
                            {product.map(product => (
                                <option key={product.ID} value={product.value}>
                                    {product.value}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="react-select-inner-container">
                        <p>Region</p>
                        <select
                            name="region"
                            value={this.state.selectedRegion}
                            className="react-select-style"
                            disabled={this.load}
                            onChange={this.handleRegionChange}
                        >
                            {region.map(region => (
                                <option key={region.ID} value={region.value}>
                                    {region.value}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                {/* <div className="container"> */}
                <div className="container-header">
                    <p>
                        <b>
                            {monthNames[selectedMonth]} {this.state.selectedYear}
                        </b>
                        - {this.state.selectedRegion} Region
                    </p>
                    <div>
                        {dataStore.checkOperationAccess('Save') &&
                            <>
                                <CustomButton
                                    type={CustomButtonType.Submit}
                                    onClick={this.handleNewRecord}
                                    disabled={this.props.nominationDataStore.isNewDisabled() || this.isDisabled()}
                                >
                                    New
                                </CustomButton>{' '}
                                <CustomButton
                                    type={CustomButtonType.Submit}
                                    onClick={this.handleRegister}
                                    disabled={this.props.nominationDataStore.isSaveDisabled() || this.isSaveDisabled()}
                                >
                                    Save
                                </CustomButton>{' '}
                                <CustomButton
                                    type={CustomButtonType.Submit}
                                    onClick={this.handleReset}
                                    disabled={this.props.nominationDataStore.isSaveDisabled() || this.isSaveDisabled()}
                                >
                                    Discard
                                </CustomButton>{' '}
                                <CustomButton
                                    type={CustomButtonType.Submit}
                                    onClick={this.handleImport}
                                    disabled={this.checkSupplierRole() || this.isDisabled()}
                                >
                                    Import
                                </CustomButton>{' '}</>}
                        {/* <ClearButtonComponent></ClearButtonComponent>{' '} */}
                        <CustomButton type={CustomButtonType.Submit} onClick={this.handleClear} disabled={this.props.nominationDataStore.nominaData.length === 0}>
                            Clear Filters
                        </CustomButton>
                        <CustomButton type={CustomButtonType.Submit} onClick={this.handleRefresh}>
                            Refresh
                        </CustomButton>
                        <CustomButton type={CustomButtonType.Submit} onClick={this.exportExcel} disabled={this.props.nominationDataStore.nominaData.length === 0}>
                            Export to excel
                        </CustomButton>{' '}
                    </div>
                </div>
                {!this.checkSupplierRole() && !this.isDisabled() && dataStore.checkOperationAccess('Save') && (
                    <div
                        className="add-customer-button-div"
                        onClick={() => this.addNewCustomer()}
                    >
                        <button className="add-customer-button">
                            <span style={{ fontSize: '15px' }}>+</span>
                        </button>
                        <p className="add-customer-button-text">Add Customer</p>
                    </div>
                )}

                {/* <AgGridComponent gridConfig={this.getMessagesGridConfig()} /> */}
                <AgGridComponent gridConfig={this.getMessagesGridConfig()} />
                <div className="customer-report">
                    <Row>
                        <Col className="mt-3 mt-sm-0">
                            Current Nomination Sum : {commaFormat(this.props.nominationDataStore.VolumeSum.NominationSum)}
                        </Col>
                        <Col className="mt-3 mt-sm-0">
                            Vols to be Trucked Sum : {commaFormat(this.props.nominationDataStore.VolumeSum.VTTSum)}
                        </Col>
                        <Col className="mt-3 mt-sm-0">
                            Total Order Volume Sum : {commaFormat(this.props.nominationDataStore.VolumeSum.TOVSum)}
                        </Col>
                        <Col className="mt-3 mt-sm-0">
                            Completed Volume Sum : {commaFormat(this.props.nominationDataStore.VolumeSum.CompletedSum)}
                        </Col>
                        <Col className="mt-3 mt-sm-0">
                            Completed Volume % : {commaFormat(this.props.nominationDataStore.VolumeSum.Completedpercentage)}
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
    handleClear = () => {
        this.nominationCSV?.rowModel.gridApi.setFilterModel(null);
    }
    handleRegister = () => {
        this.updateDrivers(false);
        this.setState({ count: 2 });
    };

    handleReset = () => {
        if (!this.load) {
            const filteredData = this.props.nominationDataStore.nominationData.filter(data => {
                return data.CustomerName !== '' && data.Id > 0;
            });
            if (this.props.nominationDataStore.selectedProduct === 'Crude') {
                this.props.nominationDataStore.fullNominationData = this.props.nominationDataStore.fullNominationData.filter(
                    data => {
                        return data.CustomerName !== '' && data.Id > 0;
                    }
                );
            }
            this.props.nominationDataStore.isCustomerAddButton = false;
            this.nominationData.forEach((data, ind) => {
                if (data.CustomerName === '' || data.IsEdit) {
                    this.nominationData.splice(ind, 1);
                }
            });
            this.props.nominationDataStore.nomiData(filteredData);
        } else {
            const filteredData = this.props.nominationDataStore.nominationLoadData.filter(data => {
                return data.LoadNumber !== '' && data.Id > 0;
            });
            this.props.nominationDataStore.nominationLoadData = filteredData;
            this.props.nominationDataStore.resetNominationLoad();
            this.props.nominationDataStore.dataToBeDeleted = [];
            this.props.nominationDataStore.selectButtonText = 'Select All';
        }
        this.props.nominationDataStore.cellValueChangeMap = {};
    };

    createHeaderCell(col) {
        let headerCell: any = {};

        let headerName: any = col.colDef.headerName;

        headerCell.text = headerName;
        headerCell.colId = col.getColId();
        headerCell['style'] = 'tableHeader';
        return headerCell;
    }

    createTableCell(cellValue, colId) {
        const tableCell = {
            text: cellValue !== undefined ? cellValue : '',
            style: 'tableCell'
        };
        return tableCell.text;
    }

    exportExcel = () => {
        if (!this.load) {
            let filteredProduct = this.nominationData;

            if (this.state.selectedProduct === 'All') {
                if (this.state.selectedRegion === 'All') {
                } else {
                    filteredProduct = this.nominationData.filter(product => {
                        return product.Region === this.state.selectedRegion;
                    });
                }
            } else {
                if (accountStore.userRoles.includes('ptssupplierbutanengl')) {
                    filteredProduct = this.nominationData.filter(product => {
                        return product.DataSource === this.state.selectedProduct;
                    });
                    if (this.state.selectedRegion === 'All') {
                    } else {
                        filteredProduct = filteredProduct.filter(product => {
                            return product.Region === this.state.selectedRegion;
                        });
                    }
                } else {
                    filteredProduct = this.nominationData.filter(product => {
                        return product.DataSource === this.state.selectedProduct;
                    });
                    if (this.state.selectedRegion === 'All') {
                    } else {
                        filteredProduct = filteredProduct.filter(product => {
                            return product.Region === this.state.selectedRegion;
                        });
                    }
                }
            }

            const filteredData: any = JSON.parse(
                JSON.stringify(this.props.nominationDataStore.calculateCrudeWithCustomerName(filteredProduct))
            );
            const data: any = this.nominationCSV?.rowModel.gridApi;
            const columns: any = this.nominationCSV?.rowModel.gridApi.columnController.columnApi;
            let columnsToExport: any = [];
            let columnHeader: any = [];
            let rowsToExport: any = [];
            columns?.getAllDisplayedColumns().forEach(col => {
                if (!['radio'].includes(col.getColId())) {
                    let headerCell: any = this.createHeaderCell(col);
                    columnsToExport.push(headerCell);
                    columnHeader.push(headerCell.text);
                }
            });


            data.forEachNodeAfterFilterAndSort(node => {
                const field = {};
                columnsToExport.forEach(({ colId, text }) => {
                    let colsToBeFormatted: any = ['Initial Nomination', 'Current Nomination', 'Apportionment Volume', 'Load Adjustments', 'Vols to be Trucked', 'Vols to be Trucked per day', 'Pending', 'Dispatched', 'Completed', 'Total Order Volume', '% Completed']
                    let cellValue = colsToBeFormatted.includes(text) ? commaFormat(data.getValue(colId, node)) : data.getValue(colId, node);
                    let tableCell = this.createTableCell(cellValue, colId);
                    field[text] = tableCell;
                    return field;
                });
                rowsToExport.push(field);
            });
            const excelrowdata = rowsToExport.filter(x => {
                return x.Origin !== '' && x.Destination !== '' && x.Product !== '' && x.DataSource !== ''
            })
            console.log(excelrowdata);
            const ws = XLSX.utils.json_to_sheet(excelrowdata);
            const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const exceldata: Blob = new Blob([excelBuffer], {
                type: EXCEL_TYPE
            });
            FileSaver.saveAs(exceldata, dataStore.getCurrentModuleName());
        } else if (this.load) {
            const allColumns: any = this.props.nominationDataStore.agGridService.getColumns()?.getAllDisplayedColumns();
            allColumns.map((col, index) => {
                if (col.getColId() === 'DELETE' || col.getColId() === 'SELECTED') {
                    allColumns.splice(index, 1);
                }
            });
            this.props.nominationDataStore.agGridService
                .getNodes()
                ?.exportDataAsExcel({ fileName: dataStore.getCurrentModuleName(), columnKeys: allColumns });
        }
    };

    handleRefresh = () => {
        const { nominationDataStore } = this.props;
        if (Object.keys(nominationDataStore.cellValueChangeMap).length === 0) {
            this.getNominationData(
                this.state.selectedRegion,
                this.state.selectedProduct,
                this.state.selectedMonth,
                this.state.selectedYear
            );
            nominationDataStore.cellValueChangeMap = {}
        }
        else {
            const confirmService = new ConfirmService();
            confirmService.showConfirmDialog(
                () => {
                    this.getNominationData(
                        this.state.selectedRegion,
                        this.state.selectedProduct,
                        this.state.selectedMonth,
                        this.state.selectedYear
                    );
                    nominationDataStore.cellValueChangeMap = {}
                },
                '',
                AppConstant.REFRESH_CONFIRMATION
            );
        }
    }

    handleSelectAll = () => {
        const csvData: any = this.props.nominationDataStore.agGridService.getNodes();
        const data: any = [];
        csvData?.rowModel.rowsToDisplay.forEach(x => {
            data.push(toJS(x.data));
        });
        this.props.nominationDataStore.handleSelectAllData(this.props.nominationDataStore.nominationLoadData, data);
        this.props.nominationDataStore.agGridService.refreshGrid();
    };

    handleDelete = async () => {
        this.checkeddata = toJS(this.props.nominationDataStore.dataToBeDeleted);
        const manualloadData: any = Array.from(
            new Set(
                this.checkeddata.filter(x => {
                    return x.Type === 'Manual Load';
                })
            )
        );

        if (manualloadData.length == 0) {
            this.deleteLoads(this.checkeddata);
        } else {
            const confirmService = new ConfirmService();
            confirmService.showConfirmDialog(
                () => {
                    this.deleteLoads(this.checkeddata);
                },
                '',
                'Manual Load is Selected. Are you sure to delete the loads ?'
            );
        }
    };

    async deleteLoads(deleteLoads) {
        const data: any = Array.from(new Set(deleteLoads.map((item: any) => item.Load)));
        let userID = accountStore.getUserID();
        if (!userID || userID === 0) {
            await accountStore.getLoggedInUserDetailsIfUserIdZero(accountStore.userName).then(() => {
                userID = accountStore.getUserID();
            });
            if (!userID || userID === 0) {
                return;
            }
        }
        const requestBody = {
            CreatedBy: userID,
            DeleteRecords: data,
            NominationLoads: [],
            IsApportionmentDeleted: false,
            ModifiedBy: userID,
            OptionType: '',
            NominationDataID: this.props.nominationDataStore.selectedData.Id,
            AdjustmentVolume: 0,
            DataSource: this.props.nominationDataStore.selectedData.DataSource
        };
        const response = await nominationDataService.createUpdateNominationLoadData(requestBody);
        this.load = true;
        this.getNominationLoadData(this.props.nominationDataStore.selectedData);
        this.getNominationData(
            this.state.selectedRegion,
            this.state.selectedProduct,
            this.state.selectedMonth,
            this.state.selectedYear
        );
        this.props.nominationDataStore.cellValueChangeMap = {};
        this.props.nominationDataStore.dataToBeDeleted = [];
        this.setState({ count: 0 });
    }

    handleEdit = async () => {
        this.props.nominationDataStore.selectedData = this.selectedNomination;
        let userID = accountStore.getUserID();
        if (!userID || userID === 0) {
            await accountStore.getLoggedInUserDetailsIfUserIdZero(accountStore.userName).then(() => {
                userID = accountStore.getUserID();
            });
            if (!userID || userID === 0) {
                return;
            }
        }
        if (this.props.nominationDataStore.selectedData.IsChild && !this.load) {
            this.load = true;
            this.props.nominationDataStore.navigated = true;
            dataStore.setModuleName('NominationLoad');
            this.props.nominationDataStore.cellValueChangeMap = {};
            this.getNominationLoadData(this.props.nominationDataStore.selectedData);
            // this.setState({ count: 0 });
        } else if (this.load) {
            const date = new Date(this.state.selectedYear, this.state.selectedMonth, 1);
            const month = date.toLocaleString('en-US', { month: 'long' });
            const reqbody = {
                StartDate: moment(new Date(this.state.selectedYear, this.state.selectedMonth, 1)).format('YYYY-MM-DD'),
                EndDate: moment(new Date(this.state.selectedYear, this.state.selectedMonth + 1, 0)).format('YYYY-MM-DD'),
                RegionID: 'All',
                Month: month,
                Year: this.state.selectedYear
            };
            uiService.loaderService.showLoader();
            const driverData: any = await dispatchPlanDataService.getDriverResponse(reqbody);
            const { companyData } = await nominationDataService.getCompanyViewModel();
            const raConfig: any = await nominationDataService.getRAConfig();
            const configMonth = raConfig.filter((item: any) => item.Code === 'RAIntegrationMonth');
        const configYear = raConfig.filter((item: any) => item.Code === 'RAIntegrationYear');
            uiService.loaderService.hideLoader();
            this.allActiveDriverValues = driverData['Data'];
            if((this.props.nominationDataStore.selectedMonth +1 >= parseInt(configMonth[0]?.Value) && this.props.nominationDataStore.selectedYear === parseInt(configYear[0]?.Value)) || 
        this.props.nominationDataStore.selectedYear > parseInt(configYear[0]?.Value))
        {
            this.companyInfo = companyData['Data'].filter(a => !a.IsDeleted && a.RACompanyName != null);
            console.log(this.companyInfo);
            this.companyInfo.map(data => {
                data.CompanyName = data.RACompanyName;
                data.AccountNumber = data.RAAccountNumber;
            })
            console.log(this.companyInfo, 'RA')
        } else {
            this.companyInfo = companyData['Data'].filter(a => !a.IsDeleted);
            console.log(this.companyInfo, 'AX')
        }
            const confirmService = new ConfirmService();
            this.checkeddata = toJS(this.props.nominationDataStore.dataToBeDeleted);
            const data: any = Array.from(new Set(this.checkeddata.map((item: any) => item.ID)));
            // data.forEach((x, ind) => {
            //     this.nominationLoadData.filter(element => {
            //         if (x === element.Id) {
            //             this.props.nominationDataStore.setValueInChangeMap(
            //                 element.Id,
            //                 'PlannedDateTime',
            //                 element.PlannedDateTime ? element.PlannedDateTime : '',
            //                 ''
            //             );
            //         }
            //     });
            // });
            confirmService.showLoadPopup(
                async (recurrence, driver, company, date, selectedLoads, dispatchType) => {
                    let count = 0;
                    let dailycount = 0;
                    let flag = 1;
                    console.log('planneddatetime', date)
                    const endDate = new Date(this.state.selectedYear, this.state.selectedMonth + 1, 0, 23, 59);
                    const selectDate = new Date(date.slice(0, 10));
                    const diffDate = endDate.getTime() - selectDate.getTime();
                    const numDays = Math.ceil(diffDate / (1000 * 60 * 60 * 24));
                    let requestList: UpdateDispatchPlanResponse[] = [];
                    if (recurrence.value == 'daily') {
                        count = 1;
                        if (numDays < data.length) dailycount = Math.ceil(data.length / numDays);
                        else dailycount = 1;
                        flag = 1;
                    } else if (recurrence.value == 'adhoc') {
                        flag = 1;
                    }
                    if (flag == 1 && (driver || company) && recurrence.value !== 'adhoc') {
                        selectedLoads.forEach(async (item, ind) => {
                            console.log(item, "ITEM IN FOR EACH")
                            let mod = 1;
                            if (recurrence.value == 'daily' && numDays < data.length) {
                                mod = numDays / data.length;
                            }
                            requestList = [
                                ...requestList,
                                {
                                    DriverID: driver,
                                    PlannedDateTime: moment.utc(date)
                                        .add(Math.floor(ind * mod * count), 'days')
                                        .format('YYYY-MM-DDTHH:mm:ss'),
                                    Priority: 3,
                                    LoadNumber: item.Load,
                                    DispatcherComments: item.DispatcherComments ? item.DispatcherComments : '',
                                    DispatcherNotes: '',
                                    TrailerNumber: ' ',
                                    CarrierID: company,
                                    ButtonName: driver ? 'DispatchLoad' : 'CarrierDispatchLoad',
                                    DispatchType: dispatchType
                                }
                            ]
                        })
                        uiService.loaderService.showLoader();
                        const response = await dispatchPlanDataService.updateDispatchPlanStatus({
                            ModifiedBy: userID,
                            IsAdhoc: false,
                            dispatchPlanRequest: requestList
                        });
                        uiService.loaderService.hideLoader();
                        if (response['StatusCode'] === 422) {
                            uiService.toastService.error(response['Message']);
                        }
                    }
                    if (flag == 1 && recurrence.value === 'adhoc') {
                        date.forEach(loads => {
                            for (let i = count; i < count + loads.loads; i++) {
                                if (loads.driverID != '' || loads.companyID != '')
                                    requestList = [
                                        ...requestList,
                                        {
                                            DriverID: loads.driverID,
                                            CarrierID: loads.companyID,
                                            ButtonName: loads.driverID ? 'DispatchLoad' : 'CarrierDispatchLoad',
                                            DispatchType: dispatchType,
                                            PlannedDateTime: moment.utc(loads.date).format('YYYY-MM-DDTHH:mm:ss'),
                                            Priority: 3,
                                            LoadNumber: selectedLoads[i].Load,
                                            DispatcherComments: selectedLoads[i].DispatcherComments ? selectedLoads[i].DispatcherComments : '',
                                            DispatcherNotes: '',
                                            TrailerNumber: ' ',
                                        }
                                    ]
                            }
                            count = count + loads.loads;
                        });
                        uiService.loaderService.showLoader();
                        const response = await dispatchPlanDataService.updateDispatchPlanStatus({
                            ModifiedBy: userID,
                            IsAdhoc: false,
                            dispatchPlanRequest: requestList
                        });
                        uiService.loaderService.hideLoader();
                        if (response['StatusCode'] === 422) {
                            uiService.toastService.error(response['Message']);
                        }
                    }
                    if (flag == 1 && recurrence.value !== 'adhoc') {
                        this.nominationLoadData = toJS(this.props.nominationDataStore.nominationLoadData);
                        let days = 0;
                        data.forEach((x, ind: any) => {
                            let mod = 0;
                            this.nominationLoadData.forEach(element => {
                                if (x === element.Id) {
                                    if (recurrence.value == 'daily' && numDays < data.length) {
                                        // mod = numDays / data.length;
                                        this.props.nominationDataStore.setValueInChangeMap(
                                            element.Id,
                                            'PlannedDateTime',
                                            element.PlannedDateTime ? element.PlannedDateTime : '',
                                            moment.utc(date)
                                                .add(Math.floor(days), 'days')
                                                .format('YYYY-MM-DDTHH:mm:ss')
                                        );
                                        this.props.nominationDataStore.cellValueChangeMap[element.Id]['PlannedDateTime'][
                                            'currentValue'
                                        ] = moment.utc(date)
                                            .add(Math.floor(days), 'days')
                                            .format('YYYY-MM-DDTHH:mm:ss');
                                        element.PlannedDateTime = moment.utc(date)
                                            .add(Math.floor(days), 'days')
                                            .format('YYYY-MM-DDTHH:mm:ss');
                                        if(moment.utc(element.PlannedDateTime).format('YYYY-MM-DDTHH:mm:ss') == moment.utc(endDate).format('YYYY-MM-DDTHH:mm:ss')) {
                                            days = -1;
                                        }
                                    } else {
                                        mod = 1;
                                    this.props.nominationDataStore.setValueInChangeMap(
                                        element.Id,
                                        'PlannedDateTime',
                                        element.PlannedDateTime ? element.PlannedDateTime : '',
                                        moment.utc(date)
                                            .add(Math.floor(ind * mod * count), 'days')
                                            .format('YYYY-MM-DDTHH:mm:ss')
                                    );
                                    this.props.nominationDataStore.cellValueChangeMap[element.Id]['PlannedDateTime'][
                                        'currentValue'
                                    ] = moment.utc(date)
                                        .add(Math.floor(ind * mod * count), 'days')
                                        .format('YYYY-MM-DDTHH:mm:ss');
                                    element.PlannedDateTime = moment.utc(date)
                                        .add(Math.floor(ind * mod * count), 'days')
                                        .format('YYYY-MM-DDTHH:mm:ss');
                                }
                                    element.DELETE = false;

                                    this.props.nominationDataStore.updateLoadRow(element);
                                }
                            });
                            days = days + 1;
                        });
                        this.props.nominationDataStore.nominationLoadData = this.nominationLoadData;
                        this.props.nominationDataStore.dataToBeDeleted = [];
                        this.props.nominationDataStore.selectButtonText = 'Select All';
                        this.loadAdjusted = true;
                        this.updateDrivers(true);
                    } else if (flag == 1 && recurrence.value == 'adhoc') {
                        let count = 0;
                        this.nominationLoadData = toJS(this.props.nominationDataStore.nominationLoadData);
                        date.forEach(loads => {
                            for (let i = count; i < count + loads.loads; i++) {
                                this.nominationLoadData.forEach(element => {
                                    if (data[i] === element.Id) {
                                        this.props.nominationDataStore.setValueInChangeMap(
                                            element.Id,
                                            'PlannedDateTime',
                                            element.PlannedDateTime ? element.PlannedDateTime : '',
                                            moment.utc(loads.date).format('YYYY-MM-DDTHH:mm:ss')
                                        );
                                        this.props.nominationDataStore.cellValueChangeMap[element.Id][
                                            'PlannedDateTime'
                                        ]['currentValue'] = moment.utc(loads.date).format('YYYY-MM-DDTHH:mm:ss');
                                        element.PlannedDateTime = moment.utc(loads.date).format('YYYY-MM-DDTHH:mm:ss');
                                        this.props.nominationDataStore.updateLoadRow(element);
                                    }
                                    element.DELETE = false;
                                });
                            }
                            count = count + loads.loads;
                        });
                        this.props.nominationDataStore.nominationLoadData = this.nominationLoadData;
                        this.props.nominationDataStore.dataToBeDeleted = [];
                        this.props.nominationDataStore.selectButtonText = 'Select All';
                        this.loadAdjusted = true;
                        this.updateDrivers(true);
                    }
                },
                this.state.currentMonth === this.state.selectedMonth
                    ? new Date(this.state.selectedYear, this.state.selectedMonth, new Date().getDate() - 1, 23, 59)
                    : new Date(this.state.selectedYear, this.state.selectedMonth, 0, 23, 59),
                new Date(this.state.selectedYear, this.state.selectedMonth + 1, 0, 23, 59),
                data.length,
                this.allActiveDriverValues,
                this.companyInfo,
                this.checkeddata
            );
        }
    };
    getCrudeSum(data) {
        let count = 0;
        if (data.length > 0) {
            data.forEach(element => {
                count += element['PendingVolume'] + element['DispatchedVolume'] + element['CompletedVolume'];
            });
        }
        return count;
    }
    @Loader
    @Catch(() => errorHandler('Could not load NominationLoad Data'))
    async getNominationLoadData(data) {
        const reqbody = {
            CustomerName: data.CustomerName,
            ContractNumber: data.ContractNumber,
            OriginID: data.OriginID,
            DestinationID: data.DestinationID,
            NominationMonth: data.NominationMonth,
            NominationYear: data.NominationYear,
            AX_Origin: data.OriginID === null ? data.AX_Origin : '',
            AX_Destination: data.DestinationID === null ? data.AX_Destination : '',
            ToCode: data.ToCode,
            Product: data.Product,
            DataSource: data.DataSource
        };
        const response = await nominationDataService.getNominationLoadData(reqbody);

        this.nominationLoadData = response.data['Data'].filter(element => {
            return element.CustomerName == data.CustomerName;
        });
        let count = 0;
        const loadDeleted: any = [];
        data.Pending = this.getSum(this.nominationLoadData, 'Pending');
        data.Dispatched = this.getSum(this.nominationLoadData, 'Dispatched');
        data.Completed = this.getSum(this.nominationLoadData, 'Completed');
        count = data.ApportionmentVolume ? parseInt(data.ApportionmentVolume) : 0;
        this.nominationLoadData.forEach((loaddata, ind) => {
            loaddata.IsChild = true;
            loaddata.IsSelected = false;
            loaddata.IsLoad = true;
            loaddata.IsInvalid = false;
            loaddata.CurrentNomination = null;
            loaddata.DataSource = data.DataSource;
            loaddata.Id = parseInt(ind) + 1;
            loaddata.Pending = loaddata.Pending ? loaddata.Pending : 0.0;
            loaddata.Dispatched = loaddata.Dispatched ? loaddata.Dispatched : 0.0;
            loaddata.Completed = loaddata.Completed ? loaddata.Completed : 0.0;
        });
        const load = toJS(data);
        this.nominationLoadData.unshift(load);
        this.nominationLoadData = this.props.nominationDataStore.orderedLoadDataBasedOnStatus(this.nominationLoadData);
        this.props.nominationDataStore.nominationLoadData = this.nominationLoadData;
        this.props.nominationDataStore.setBackupnominationData(this.nominationLoadData);
        this.props.nominationDataStore.agGridService.setAllFilters(localStorageService.get('NominationLoad_filter'));
        if (this.props.nominationDataStore.selectedData.DataSource !== 'Crude') {
            this.VolumeSum.NominationSum =
            this.props.nominationDataStore.nominationLoadData[0].CurrentNomination !== null
                ? this.props.nominationDataStore.nominationLoadData[0].CurrentNomination.toFixed(3)
                : '0.000';
        this.VolumeSum.VTTSum =
            (this.props.nominationDataStore.nominationLoadData[0].VolsToBeTrucked !== null &&
                this.props.nominationDataStore.nominationLoadData[0].VolsToBeTrucked > 0)
                ? this.props.nominationDataStore.nominationLoadData[0].VolsToBeTrucked.toFixed(3)
                : '0.000';
        this.VolumeSum.TOVSum = this.getSumTOV(this.props.nominationDataStore.nominationLoadData);
        this.VolumeSum.CompletedSum = this.getSumLoad(
            this.props.nominationDataStore.nominationLoadData,
            'Completed'
        ).toFixed(3);
        this.VolumeSum.Completedpercentage = this.VolumeSum.VTTSum !== '0.000' ? ((this.VolumeSum.CompletedSum * 100) / this.VolumeSum.VTTSum).toFixed(3) : '0.000';
    } }
    async apportionmentDeletion(data) {
        let userID = accountStore.getUserID();
        if (!userID || userID === 0) {
            await accountStore.getLoggedInUserDetailsIfUserIdZero(accountStore.userName).then(() => {
                userID = accountStore.getUserID();
            });
            if (!userID || userID === 0) {
                return;
            }
        }
        const requestBody = {
            CreatedBy: userID,
            DeleteRecords: data,
            NominationLoads: [],
            IsApportionmentDeleted: true,
            ModifiedBy: userID,
            OptionType: '',
            NominationDataID: this.props.nominationDataStore.selectedData.Id,
            AdjustmentVolume: 0,
            DataSource: this.props.nominationDataStore.selectedData.DataSource
        };
        const response = await nominationDataService.createUpdateNominationLoadData(requestBody);
        this.load = true;
        this.getNominationData(
            this.state.selectedRegion,
            this.state.selectedProduct,
            this.state.selectedMonth,
            this.state.selectedYear
        );
        this.getNominationLoadData(this.props.nominationDataStore.selectedData);
        this.props.nominationDataStore.cellValueChangeMap = {};
        this.props.nominationDataStore.dataToBeDeleted = [];
    }

    getSum(data, column) {
        let count = 0;
        data.forEach(x => {
            if (x[column] && !x['IsDeleted'] && !x['IsApportionmentDeleted'] && !x['IsAdjustDeleted']) {
                count += x[column];
            }
        });
        if (count !== 0) {
            return count;
        } else {
            return 0.0;
        }
    }

    getSumLoad(data, column) {
        let count = 0;
        data.forEach(x => {
            if (x[column] && x['IsLoad'] && !x['IsDeleted'] && !x['IsApportionmentDeleted'] && !x['IsAdjustDeleted']) {
                if(x[column] > 0)
                count += x[column];
            }
        });
        if (count !== 0) {
            return count;
        } else {
            return 0.0;
        }
    }

    getSumTOV(data) {
        let count = 0;
        data.forEach(x => {
            if (x['IsLoad'] && !x['IsDeleted'] && !x['IsApportionmentDeleted'] && !x['IsAdjustDeleted']) {
                let pending = x['Pending'] > 0 ? x['Pending'] : 0.0
                let dispatched = x['Dispatched'] > 0 ? x['Dispatched'] : 0.0
                let completed = x['Completed'] > 0 ? x['Completed'] : 0.0
                count += pending + dispatched + completed;
            }
        });
        if (count !== 0) {
            return count.toFixed(3);
        } else {
            return 0.0;
        }
    }

    getCompletedPercentage(data) {
        let count = 0;
        let pendingVolume = 0;
        let dispatchedVolume = 0;
        let completedVolume = 0;

        data.forEach(x => {
            pendingVolume = isNaN(x['Pending']) ? 0 : x['Pending'];
            dispatchedVolume = isNaN(x['Dispatched']) ? 0 : x['Dispatched'];
            completedVolume = isNaN(x['Completed']) ? 0 : x['Completed'];
            // console.log(pendingVolume + '-' + dispatchedVolume + '-' +completedVolume + '-' + x['VolsToBeTrucked'])
            console.log(this.VolumeSum.CompletedSum + '-' + x['VolsToBeTrucked']);
        });

        if (this.nominationLoadData.length > 0) {
            let totalVTT = this.nominationLoadData[0]['VolsToBeTrucked'];
            count = (this.VolumeSum.CompletedSum * 100) / totalVTT;
        }

        if (count !== 0) {
            return count.toFixed(3);
        } else {
            return 0.0;
        }
    }

    componentDidUpdate() {
        if (!this.load || (this.props.nominationDataStore.selected && !this.load)) {
            const filters: any = localStorageService.get('Nomination_filter');
            console.log(filters);
            this.props.nominationDataStore.agGridService.setAllFilters(filters);
        }
    }

    validateUpdateRequest(updatedList) {
        let ErrorMessage = '';
        updatedList.forEach(item => {
            if (CommonUtils.checkIfFieldIsNullOrEmpty(item['CustomerName'])) {
                ErrorMessage = `Customer Name  is required`;
                return;
            }
            if (CommonUtils.checkIfFieldIsNullOrEmpty(item['AX_Origin'])) {
                ErrorMessage = `Origin Location is required`;
                return;
            }

            if (CommonUtils.checkIfFieldIsNullOrEmpty(item['Destination'])) {
                ErrorMessage = `Destination Location is required`;
                return;
            }
            if (CommonUtils.checkIfFieldIsNullOrEmpty(item['DataSource'])) {
                ErrorMessage = `Data Source is required`;
                return;
            }
            if (item['CustomerName'] != 'New Location' && CommonUtils.checkIfFieldIsNullOrEmpty(item['Product'])) {
                ErrorMessage = `Product is required`;
                return;
            }
            if (
                !this.checkSupplierRole() &&
                item['DataSource'] === 'Butane' &&
                CommonUtils.checkIfFieldIsNullOrEmpty(item['ContractNumber'])
            ) {
                ErrorMessage = `ContractNumber is required for Butane`;
                return;
            }
            if (
                !this.checkSupplierRole() &&
                item['DataSource'] === 'Crude' &&
                item['IsEdit'] &&
                CommonUtils.checkIfFieldIsNullOrEmpty(item['ShipperName'])
            ) {
                ErrorMessage = `ShipperName is required`;
                return;
            }
            if (this.checkIfNominationsAreSame(updatedList)) {
                ErrorMessage = `Creating Duplicate O/D Pairing nomination. Please verify.`;
                return;
            }
        });
        return ErrorMessage;
    }

    checkIfNominationsAreSame = updatedList => {
        const data: any = Array.from(
            new Set(
                updatedList.map(
                    (item: any) =>
                        item.CustomerName +
                        '_' +
                        item.AX_Origin +
                        '_' +
                        item.Destination +
                        '_' +
                        item.DataSource +
                        '_' +
                        item.Product
                )
            )
        );
        const existingRows = this.props.nominationDataStore.nominationData.filter(
            a => !this.props.nominationDataStore.getUpdatedRowIDs().includes(a.Id.toString())
        );

        const filteredExistingRows = existingRows.filter(item => {
            return updatedList.find(item1 => {
                return (item1['CustomerName'] === item['CustomerName'] &&
                    item1['AX_Origin'] === item['AX_Origin'] &&
                    item1['AX_Destination'] === item['AX_Destination'] &&
                    item1['DataSource'] === item['DataSource']);
            });
        });

        if (data.length < updatedList.length || filteredExistingRows.length > 0) {
            return true;
        }
        return false;
    };

    validateLoadUpdateRequest(updatedList) {
        let ErrorMessage = '';
        updatedList.forEach(item => {
            if (
                !this.checkSupplierRole() &&
                item.IsEdit &&
                item['DataSource'] === 'Crude' &&
                CommonUtils.checkIfFieldIsNullOrEmpty(item['ShipperName'])
            ) {
                ErrorMessage = `ShipperName is required`;
                return;
            }
        });
        return ErrorMessage;
    }

    @Loader
    async updateDrivers(validateReq: boolean): Promise<void> {
        let userID = accountStore.getUserID();
        if (!userID || userID === 0) {
            await accountStore.getLoggedInUserDetailsIfUserIdZero(accountStore.userName).then(() => {
                userID = accountStore.getUserID();
            });
            if (!userID || userID === 0) {
                return;
            }
        }
        if (!this.load) {
            const updatedRowIDs = this.props.nominationDataStore.nominationData.filter(a =>
                this.props.nominationDataStore.getUpdatedRowIDs().includes(a.Id.toString())
            );
            updatedRowIDs.forEach(data => {
                let updatedRow: any = this.props.nominationDataStore.nominaData.filter(row => row.Id === data.Id)[0];
                data.ContractNumber =
                    CommonUtils.checkIfFieldIsNullOrEmpty(data.ContractNumber) &&
                        (data.DataSource === 'NGL' || data.DataSource === 'Brine')
                        ? 'Dummy'
                        : data.ContractNumber;
                if (data.DataSource === 'Crude' && !CommonUtils.checkIfFieldIsNullOrEmpty(data.ContractNumber)) {
                    data.ContractNumber = null;
                    uiService.toastService.info('Contract number for crude is not considered. It will always be blank');
                }
                data.EditNomination = updatedRow.EditNomination;
                data.DispatcherComment = updatedRow.DispatcherComment;
            });
            const validationMessage = this.validateUpdateRequest(updatedRowIDs);
            if (!isEmpty(validationMessage) && validateReq === true) {
                uiService.loaderService.hideLoader();
                uiService.toastService.error(validationMessage);
                return;
            }
            const requestBody = {
                NominationMonth: this.state.selectedMonth + 1,
                NominationYear: this.state.selectedYear,
                CreatedBy: userID,
                NominationData: updatedRowIDs,
                ModifiedBy: userID
            };

            const response = await nominationDataService.createUpdateNominationData(requestBody);
            if(response['StatusCode'] === 200) {
                uiService.toastService.success('Saved Successfully');
            }
            await this.getNominationData(
                this.state.selectedRegion,
                this.state.selectedProduct,
                this.state.selectedMonth,
                this.state.selectedYear
            );
            this.props.nominationDataStore.cellValueChangeMap = {};
            this.props.nominationDataStore.isCustomerAddButton = false;
        } else {
            const nominationLoadData = toJS(this.props.nominationDataStore.nominationLoadData);
            const updatedRows = toJS(this.props.nominationDataStore.cellValueChangeMap);
            const updatedRowIDs = nominationLoadData.filter(a => {
                if (this.props.nominationDataStore.getUpdatedRowIDs().includes(a.Id.toString())) {
                    if (!updatedRows[a.Id].DispatcherComments) {
                        delete a.DispatcherComments;
                    } else {
                        a.DispatcherComments = updatedRows[a.Id].DispatcherComments.currentValue;
                    }
                    return a;
                }
            });
            updatedRowIDs.forEach(async data => {
                data.AX_Origin = this.props.nominationDataStore.selectedData.AX_Origin;
                data.AX_Destination = this.props.nominationDataStore.selectedData.AX_Destination;
                data.DataSource = this.props.nominationDataStore.selectedData.DataSource;
                data.NominationID = this.props.nominationDataStore.selectedData.Id;
                data.ToCode = this.props.nominationDataStore.selectedData.ToCode;
                const driverName = this.allActiveDriverValues.findIndex(a => a.DriverName == data.DriverName);
            });
            if (this.props.nominationDataStore.selectedData.DataSource === 'Crude') {
                const validationMessage = this.validateLoadUpdateRequest(updatedRowIDs);
                if (!isEmpty(validationMessage)) {
                    uiService.loaderService.hideLoader();
                    uiService.toastService.error(validationMessage);
                    return;
                }
            }
            const requestBody = {
                CreatedBy: userID,
                ModifiedBy: userID,
                DeleteRecords: [],
                IsApportionmentDeleted: false,
                NominationLoads: updatedRowIDs,
                OptionType: this.optionType,
                NominationDataID: this.props.nominationDataStore.selectedData.Id,
                AdjustmentVolume: this.adjustmentVolume,
                DataSource: this.props.nominationDataStore.selectedData.DataSource,
                ShipperType: this.shipperType,
                PlainsVolume: this.plainsVolume,
                OthersVolume: this.nonplainsVolume
            };

            const response = await nominationDataService
                .createUpdateNominationLoadData(requestBody)
                .then(response => {
                    this.load = true;
                    // this.getNominationData(
                    //     this.state.selectedRegion,
                    //     this.state.selectedProduct,
                    //     this.state.selectedMonth,
                    //     this.state.selectedYear
                    // );
                    this.getNominationLoadData(this.props.nominationDataStore.selectedData);
                    this.props.nominationDataStore.cellValueChangeMap = {};
                    this.setState({ count: 0 });
                })
                .catch(error => {
                    this.load = true;
                    errorHandler('Error updating the Load values');
                    this.getNominationData(
                        this.state.selectedRegion,
                        this.state.selectedProduct,
                        this.state.selectedMonth,
                        this.state.selectedYear
                    );
                });
            this.props.nominationDataStore.isLoadRowEdited = false;
            if (this.loadAdjusted === true) {
                const reqbody: NominationDataRequest = {
                    NominationMonth: this.state.selectedMonth + 1,
                    NominationYear: this.state.selectedYear
                };
                uiService.loaderService.showLoader();
                const nominations: any = await nominationDataService.getNominationData(reqbody);
                uiService.loaderService.hideLoader();
                const filteredData: any = this.props.nominationDataStore.nominationLoadData;
                const filters: any = agGridService.getAllFilters();
                const dataLoad = filteredData.filter(dat =>
                    Object.keys(filters).every(k => {
                        if (k === 'loadNmae') return filters[k].values.includes(dat['LoadNumber']);
                        else return filters[k].values.includes(dat[k]);
                    })
                );
                if (this.props.nominationDataStore.selectedData.DataSource === 'Crude') {
                    this.props.nominationDataStore.selectedData.VolsToBeTrucked = 0;
                    this.VolumeSum.TOVSum = 0;
                    this.CrudePending = 0;
                    this.CrudeDispatched = 0;
                    this.CrudeCompleted = 0;
                    let plannedVolume = 0;
                    this.updatedNominationData = nominations.data['Data'].filter((nomination) =>
                        nomination.CustomerName === this.props.nominationDataStore.selectedData.CustomerName &&
                        nomination.Product === this.props.nominationDataStore.selectedData.Product &&
                        nomination.OriginID === this.props.nominationDataStore.selectedData.OriginID &&
                        nomination.DestinationID === this.props.nominationDataStore.selectedData.DestinationID
                    )
                    this.updatedNominationData.forEach((nomination) => {
                        this.props.nominationDataStore.selectedData.VolsToBeTrucked += nomination.VolsToBeTrucked;
                        this.CrudePending += nomination.PendingVolume;
                        this.CrudeDispatched += nomination.DispatchedVolume;
                        this.CrudeCompleted += nomination.CompletedVolume;
                        plannedVolume += nomination.PlannedVolume;
                    })
                    this.VolumeSum.TOVSum = this.CrudePending + this.CrudeDispatched + this.CrudeCompleted
                    this.VolumeSum.TOVSum = this.VolumeSum.TOVSum.toFixed(3)
                    this.props.nominationDataStore.selectedData.PendingVolume = this.CrudePending
                    this.props.nominationDataStore.selectedData.DispatchedVolume = this.CrudeDispatched
                    this.props.nominationDataStore.selectedData.CompletedVolume = this.CrudeCompleted
                    this.props.nominationDataStore.selectedData.PlannedVolume = plannedVolume;
                }
                else {
                    this.updatedNominationData = nominations.data['Data'].filter((nomination) =>
                        nomination.Id === this.props.nominationDataStore.selectedData.Id)
                    this.props.nominationDataStore.selectedData.VolsToBeTrucked = this.updatedNominationData[0].VolsToBeTrucked;
                    this.VolumeSum.TOVSum = (this.props.nominationDataStore.selectedData.Pending + this.props.nominationDataStore.selectedData.Dispatched + this.props.nominationDataStore.selectedData.Completed).toFixed(3)
                    this.props.nominationDataStore.selectedData.PlannedVolume = this.updatedNominationData[0].PlannedVolume;
                }
                this.VolumeSum.NominationSum =
                this.props.nominationDataStore.selectedData?.CurrentNomination !== null
                    ? this.props.nominationDataStore.selectedData?.CurrentNomination.toFixed(3)
                    : '0.000';
            this.VolumeSum.VTTSum =
                (this.props.nominationDataStore.selectedData?.VolsToBeTrucked !== null && this.props.nominationDataStore.selectedData?.VolsToBeTrucked > 0)
                    ? this.props.nominationDataStore.selectedData?.VolsToBeTrucked.toFixed(3)
                    : '0.000';
            this.VolumeSum.CompletedSum = this.getSumLoad(dataLoad, 'Completed').toFixed(3);
            this.VolumeSum.Completedpercentage = this.VolumeSum.VTTSum !== '0.000' ? ((this.VolumeSum.CompletedSum * 100) / this.VolumeSum.VTTSum).toFixed(3) : '0.000';
            } 
        }
    }

    handleCancel = () => {
        this.load = false;
        this.props.nominationDataStore.navigated = true;
        dataStore.setModuleName('Nomination');
        // this.props.nominationDataStore.selectedData = {};
        this.props.nominationDataStore.CustomerName = undefined;
        this.props.nominationDataStore.cellValueChangeMap = {};
        this.props.nominationDataStore.dataToBeDeleted = [];
        this.props.nominationDataStore.selectButtonText = 'Select All';
        this.props.nominationDataStore.isLoadPopupVisible = false;
        this.props.nominationDataStore.selected = false;
        if (this.props.nominationDataStore.selectedData.DataSource !== 'Crude') {
            this.props.nominationDataStore.selectedData.PendingVolume = this.props.nominationDataStore.selectedData.Pending;
            this.props.nominationDataStore.selectedData.DispatchedVolume = this.props.nominationDataStore.selectedData.Dispatched;
            this.props.nominationDataStore.selectedData.CompletedVolume = this.props.nominationDataStore.selectedData.Completed;
        }
        this.VolumeSum.VTTSum = 0;
        this.VolumeSum.TOVSum = 0;
        this.VolumeSum.NominationSum = 0;
        this.VolumeSum.Completedpercentage = 0;
        this.VolumeSum.CompletedSum = 0;
        this.loadAdjusted = false;
        this.props.nominationDataStore.agGridService.setGridApi(this.nominationCSV?.rowModel.gridApi)
        localStorageService.set('NominationLoad_filter', {})
        this.nominationCSV?.rowModel.gridApi.refreshCells();
    };

    async addNewCustomer() {
        const confirmService = new ConfirmService();
        const raConfig = await nominationDataService.getRAConfig();
                    console.log(raConfig);
        confirmService.showConfirmWithCustomComponent(
            (filters) => {
                this.updateManualNomination()
            },
            ManualNominationFilterPopUp,
            { originData: this.operators, destinationData: this.operators, dataSourceData: dataSources, shipperNameData: ShipperName, productsData: productsData, selectedMonth: this.state.selectedMonth, selectedYear: this.state.selectedYear,raConfig: raConfig  } as ConfirmProps,
            'confirmPopup'
        );
    }

    handleNewRecord = async () => {
        if (!this.load) {
            const raConfig = await nominationDataService.getRAConfig();
            const confirmService = new ConfirmService();
            confirmService.showConfirmWithCustomComponent(
                (filters) => {
                    this.updateManualNomination()
                },
                ManualNominationFilterPopUp,
                { customerName: this.props.nominationDataStore.CustomerName, originData: this.operators, destinationData: this.operators, dataSourceData: dataSources, shipperNameData: ShipperName, productsData: productsData, selectedMonth: this.state.selectedMonth, selectedYear: this.state.selectedYear,raConfig: raConfig } as ConfirmProps,
                'confirmPopup'
            );
        } else if (this.load) {
            this.optionType = '';
            this.adjustmentVolume = 0;
            this.nominationLoadData = toJS(this.props.nominationDataStore.nominationLoadData);
            if (false) {
                const load = {
                    LoadNumber: '',
                    Id: 0,
                    Pending: '',
                    PlannedDateTime: null,
                    DispatchedDate: null,
                    DispatcherComments: '',
                    Dispatched: '',
                    CustomerName: this.nominationLoadData[0].CustomerName,
                    ContractNumber: this.nominationLoadData[0].ContractNumber,
                    Product: this.nominationLoadData[0].Product,
                    OriginID: this.nominationLoadData[0].OriginID,
                    DataSource: this.nominationLoadData[0].DataSource,
                    DestinationID: this.nominationLoadData[0].DestinationID,
                    NominationMonth: this.nominationLoadData[0].NominationMonth,
                    NominationYear: this.nominationLoadData[0].NominationYear,
                    CurrentNomination: null,
                    IsChild: true,
                    IsEdit: true,
                    IsSelected: false,
                    IsLoad: true,
                    Completed: '',
                    LoadStatus: null
                };
                this.props.nominationDataStore.agGridService.updateOptionCellEditorValues(
                    ShipperName,
                    'ShipperName',
                    'Label'
                );
                const minID: number = this.nominationLoadData[this.nominationLoadData.length - 1].Id;
                if (minID <= 0) {
                    load.Id = minID - 1;
                }
                this.nominationLoadData.push(load);
                this.props.nominationDataStore.nominationLoadData = this.nominationLoadData;
            } else {
                const confirmService = new ConfirmService();
                confirmService.showGenerateLoadPopup(
                    (option, volume, loadType, manualType) => {
                        let flag = 0;
                        if (option === 'opt1') {
                            this.optionType = 'AdjustLoadsWithNewLoadSize';
                            if (
                                this.highestVolume(this.nominationLoadData) ===
                                this.nominationLoadData[0].CurrentLoadsize
                            ) {
                                uiService.toastService.info(
                                    'There is no change in LoadSize, hence no need of adjusting volumes'
                                );
                            } else {
                                this.adjustmentVolume = this.nominationLoadData[0].Pending;
                                if (this.adjustmentVolume > 0) {
                                    this.loadAdjusted = true;
                                    this.updateDrivers(true);
                                }
                            }
                        } else if (option === 'opt2') {
                            let msg = '';
                            if (this.nominationLoadData[0].DataSource === 'Crude') {
                                this.adjustmentVolume = volume;
                                if (manualType === 'Plains') this.shipperType = ['Plains'];
                                else if (manualType === 'Others') this.shipperType = ['Others'];
                                else this.shipperType = ['Plains', 'Others'];
                            } else this.adjustmentVolume = volume;
                            if (loadType === 'ADD') {
                                this.optionType = 'AddNewManualLoads';
                            } else {
                                this.optionType = 'DeleteManualLoads';
                                if (
                                    this.nominationLoadData[0].DataSource === 'Crude' &&
                                    manualType !== 'Plains/Others'
                                ) {
                                    if (
                                        volume >
                                        this.crudeShipperCount(this.nominationLoadData, 'Manual Load', manualType)
                                    ) {
                                        flag = 1;
                                        msg = ' for ShipperType ' + manualType;
                                    }
                                } else if (
                                    this.nominationLoadData[0].DataSource === 'Crude' &&
                                    manualType === 'Plains/Others'
                                ) {
                                    console.log(this.nominationLoadData[0].VolumeRatio);
                                    const ratio =
                                        this.nominationLoadData[0].ShipperType == 'Plains Midstream Canada ULC'
                                            ? this.nominationLoadData[0].VolumeRatio
                                            : this.nominationLoadData[0].TotalRatio -
                                            this.nominationLoadData[0].VolumeRatio;
                                    if (
                                        volume * ratio >
                                        this.crudeShipperCount(this.nominationLoadData, 'Manual Load', 'Plains') ||
                                        volume * (this.nominationLoadData[0].TotalRatio - ratio) >
                                        this.crudeShipperCount(this.nominationLoadData, 'Manual Load', 'Others')
                                    ) {
                                        flag = 1;
                                        msg = ' for ShipperType ' + manualType;
                                    }
                                } else {
                                    if (
                                        volume > this.loadPendingCount(this.nominationLoadData, 'Manual Load', option)
                                    ) {
                                        flag = 1;
                                    }
                                }
                            }
                            if (flag == 0) {
                                this.loadAdjusted = true;
                                this.updateDrivers(true);
                            } else if (flag == 1) {
                                uiService.toastService.error(
                                    'There are no enough manual load pending volumes to be deleted' + msg
                                );
                            }
                        } else if (option === 'opt3') {
                            this.adjustmentVolume = volume;
                            if (loadType === 'ADD') {
                                this.optionType = 'AddAdjustLoads';
                            } else {
                                this.optionType = 'DeleteAdjustLoads';
                                if (volume > this.loadPendingCount(this.nominationLoadData, 'System Load', option)) {
                                    flag = 1;
                                }
                            }
                            if (flag == 0) {
                                this.loadAdjusted = true;
                                this.updateDrivers(true);
                            }
                            else if (flag == 1) {
                                uiService.toastService.error(
                                    'There are no enough adjusted load pending volumes to be deleted'
                                );
                            }
                        } else if (option === 'opt4') {
                            this.optionType = 'DeleteSystemLoads';
                            this.adjustmentVolume = volume;
                            if (volume > this.loadPendingCount(this.nominationLoadData, 'System Load', option)) {
                                flag = 1;
                            }
                            if (flag == 0) {
                                this.loadAdjusted = true;
                                this.updateDrivers(true);
                            }
                            else if (flag == 1) {
                                uiService.toastService.error(
                                    'Entered Load Volume is greater than Total System Pending Volume'
                                );
                            }
                        }
                    },
                    this.nominationLoadData[0],
                    this.props.nominationDataStore.apportionmentData[0]?.IsApply
                );
            }
        }
    };

    updateManualNomination = async () => {
        await this.getNominationData(
            this.state.selectedRegion,
            this.state.selectedProduct,
            this.state.selectedMonth,
            this.state.selectedYear
        );
        this.props.nominationDataStore.cellValueChangeMap = {};
        this.props.nominationDataStore.isCustomerAddButton = false;
        this.props.nominationDataStore.CustomerName = undefined;
    }

    highestVolume(array) {
        let count = 0;
        array.forEach((load, ind) => {
            if (load.Pending > count && ind != 0 && !load.IsDeleted && !load.IsAdjustDeleted) {
                count = load.Pending;
            }
        });

        return count;
    }

    crudeShipperCount(array, loadType, option) {
        let count = 0;
        array.forEach((load, ind) => {
            let isPending = (load.PlannedDateTime != null && load.LoadStatus !== 'Pending') || (load.PlannedDateTime === null && load.LoadStatus === 'Pending');
            if (
                load.Pending &&
                ind != 0 &&
                !load.IsDeleted &&
                !load.IsAdjustDeleted &&
                !load.IsApportionmentDeleted &&
                load.LoadType == loadType &&
                !['Dispatch_Pending','Carrier Accepted','Carrier Assigned'].includes(load.LoadStatus) &&
                isPending &&
                (option === 'Others' || option === 'Plains'
                    ? option === 'Others'
                        ? load.ShipperName === 'Others'
                        : load.ShipperName === 'Plains Midstream Canada ULC'
                    : true)
            ) {
                count += load.Pending;
            }
        });
        return count;
    }

    loadPendingCount(array, loadType, option) {
        let count = 0;
        array.forEach((load, ind) => {
            let isPending = (load.PlannedDateTime != null && load.LoadStatus !== 'Pending') || (load.PlannedDateTime === null && load.LoadStatus === 'Pending');
            if (option == 'opt3') {
                if (
                    load.Pending &&
                    ind != 0 &&
                    !load.IsDeleted &&
                    !load.IsAdjustDeleted &&
                    !['Dispatch_Pending','Carrier Accepted','Carrier Assigned'].includes(load.LoadStatus) &&
                    isPending &&
                    (load.LoadType == loadType || load.LoadType == 'Adjusted Load')
                ) {
                    count += load.Pending;
                }
            } else {
                if (load.Pending && ind != 0 && !load.IsDeleted && !load.IsAdjustDeleted && load.LoadType == loadType && !['Dispatch_Pending','Carrier Accepted','Carrier Assigned'].includes(load.LoadStatus) &&
                    isPending) {
                    count += load.Pending;
                }
            }
        });

        return count;
    }

    @Loader
    async getRegionData(): Promise<void> {
        const Region = localStorageService.get('region');
        try {
            await locationDataService
                .getRegionTypes()
                .then(response => {
                    let responseData = response.data['Data'];
                    let sortedDataForNorthRegion = responseData.filter((item) => item.Name.includes('North')).sort((a, b) => a.ID > b.ID);
                    let sortedDataForSouthRegion = responseData.filter((item) => item.Name.includes('South')).sort((a, b) => a.ID > b.ID)
                    this.baseValueDTO = [...sortedDataForNorthRegion, ...sortedDataForSouthRegion]
                    localStorageService.set('region', this.baseValueDTO);
                })
                .catch(function (error) {
                    console.log('error in getting Region', error);
                });
        }
        catch (e) {
            localStorageService.remove('region');
            console.log('error in getting Region', e);
        }
    }

    onCellclicked = async event => {
        if (!this.load && !event.node.group && !event.data.IsEdit) {
            let filteredProduct = this.nominationData;
            if (this.props.nominationDataStore.isCustomerAddButton) {
                filteredProduct = this.props.nominationDataStore.nominationData;
            }

            if (this.state.selectedProduct === 'All') {
                // filteredProduct = filteredProduct.filter(product => {
                //     return (
                //         product.DataSource === 'Butane' || product.DataSource === 'NGL' || product.DataSource === null
                //     );
                // });
                if (this.state.selectedRegion === 'All') {
                } else {
                    filteredProduct = filteredProduct.filter(product => {
                        return product.Region === this.state.selectedRegion;
                    });
                }
            } else {
                if (accountStore.userRoles.includes('ptssupplierbutanengl')) {
                    if (this.state.selectedProduct !== 'All') {
                        filteredProduct = filteredProduct.filter(product => {
                            return product.DataSource === this.state.selectedProduct || product.DataSource === null;
                        });
                    }
                    if (this.state.selectedRegion === 'All') {
                    } else {
                        filteredProduct = filteredProduct.filter(product => {
                            return product.Region === this.state.selectedRegion;
                        });
                    }
                } else {
                    filteredProduct = filteredProduct.filter(product => {
                        return product.DataSource === this.state.selectedProduct || product.DataSource === null;
                    });
                    if (this.state.selectedRegion === 'All') {
                    } else {
                        filteredProduct = filteredProduct.filter(product => {
                            return product.Region === this.state.selectedRegion;
                        });
                    }
                }
            }
            if (event.column.colId == 0) {
                if (event.data.customer === 'add-customer') {
                    event.data.IsExpanded = true;
                }
                if (!event.data.IsExpanded) {
                    event.data.IsExpanded = !event.data.IsExpanded;
                    filteredProduct.forEach(element => {
                        if (element.CustomerName == event.data.name) {
                            element.IsSelected = true;
                        }
                    });
                } else {
                    event.data.IsExpanded = !event.data.IsExpanded;
                    filteredProduct.forEach(element => {
                        if (element.CustomerName == event.data.name) {
                            element.IsSelected = false;
                        }
                    });
                }
                this.props.nominationDataStore.selectedData = {};
                this.props.nominationDataStore.nomiData(filteredProduct);
                this.setState({ count: 0 });
            } else if (event.column.colId == 'radio') {
                if (!event.data.IsSelected) {
                    event.data.IsSelected = !event.data.IsSelected;
                } else {
                    event.data.IsSelected = !event.data.IsSelected;
                }
                this.props.nominationDataStore.selectedData = event.data;
                this.selectedNomination = event.data;
                this.setState({ count: 0 });
            }
            if (((event.column.getColId() === 'AX_Origin' && event.data['IsNewOrigin']) || (event.column.getColId() === 'AX_Destination' && event.data['IsNewDestination'])) && dataStore.checkOperationAccess('Save')) {
                // this.props.nominationDataStore.showAXPopUp(event.data);
                const confirmService = new ConfirmService();
                uiService.loaderService.showLoader();
                const broadcastReceiver: any = await nominationDataService.getLocations();
                let locationsArray: any = [], raFlag = '';
                const raConfig: any = await nominationDataService.getRAConfig();
                const configMonth = raConfig.filter((item: any) => item.Code === 'RAIntegrationMonth');
                const configYear = raConfig.filter((item: any) => item.Code === 'RAIntegrationYear');
                if((this.props.nominationDataStore.selectedMonth + 1 < parseInt(configMonth[0].Value) && this.props.nominationDataStore.selectedYear === parseInt(configYear[0].Value)) || 
                    this.props.nominationDataStore.selectedYear < parseInt(configYear[0].Value)){
                        locationsArray = broadcastReceiver.filter(item => item.SourceSystem === null)
                        raFlag = 'AX'
                 } else {
                    locationsArray = broadcastReceiver.filter(item => item.SourceSystem === 'RA')
                    raFlag = 'RA'
                 }
                const locationData = await locationDataService.getLocationData();
                uiService.loaderService.hideLoader();

                //console.log(event.coldef, event,event[0], event.colDef?.field)
                confirmService.showLocationAXPopup(async location => {
                    const reqbody = [{
                        Id: null,
                        NominationLocation: event.data[event.colDef.field],
                        AX_Location: location,
                        TruckedByLocations: true,
                        IsAXorRA: raFlag
                    }];
                    //console.log(event.coldef, event, event.coldef.field)
                    uiService.loaderService.showLoader();
                     const result: any = await nominationDataService.sendLocationMapping({ locationMappingRequest: reqbody, DeleteRecords: [], ModifiedBy: accountStore.getUserID() });
                    uiService.loaderService.hideLoader();
                    // console.log(result);
                     if (result.StatusCode === 200) {

                        const filterData = locationData.filter(a => a.Consignor === (event.colDef.field === 'AX_Origin' ? location : event.data['AX_Origin'])
                            && a.Consignee === (event.colDef.field === 'AX_Destination' ? location : event.data['AX_Destination']))
                            console.log(filterData);
                            const confirmService = new ConfirmService();
                        confirmService.showConfirmDialog(
                            async () => {
                                console.log(filterData);
                                console.log('welcome');
                                await this.getNominationData(
                                    this.state.selectedRegion,
                                    this.state.selectedProduct,
                                    this.state.selectedMonth,
                                    this.state.selectedYear
                                );
                                const confirmService = new ConfirmService();
                                uiService.loaderService.showLoader();
                                let reqbody = {
                                    AxOrRAData : 'All'
                                }
                                const location: any = await locationDataService.getOperator(reqbody);
                                await this.getRegionData();
                                uiService.loaderService.hideLoader();
                                confirmService.showLocationPopup(async (consginor, consignee, consignorRA, consigneeRA, loadSize, region) => {
                                    let userID = accountStore.getUserID()
                                    if (!userID || userID === 0) {
                                        await accountStore.getLoggedInUserDetailsIfUserIdZero(accountStore.userName).then(() => {
                                            userID = accountStore.getUserID();
                                            this.userID = accountStore.getUserID();
                                        });
                                        if (!userID || userID === 0) {
                                            return;
                                        }
                                    }
                                    const latestData = this.props.nominationDataStore.nominaData.filter(a => a.ID === event.data.Id)
                                    console.log(latestData, JSON.stringify(latestData));
                                    const reqbody = {
                                        ID: filterData[0]?.ID ? filterData[0].ID : 0,
                                        CreatedBy: accountStore.getUserID(),
                                        Consignor: consginor,
                                        Consignee: consignee,
                                        RAConsignee: consigneeRA,
                                        RAConsignor: consignorRA,
                                        DeletedRecords: [],
                                        CreatedDate: moment(new Date().toISOString()).format('YYYY-MM-DDTHH:mm:ss'),
                                        ModifiedBy: userID,
                                        CurrentLoadSize: filterData[0]?.ID ? filterData[0]?.CurrentLoadsize : parseInt(loadSize),
                                        PlannedLoadsize: parseInt(loadSize),
                                        RegionID: parseInt(region)
                                    }
                                    uiService.loaderService.showLoader();
                                    const response: any = await locationDataService.sendOperator([reqbody]);
                                    uiService.loaderService.hideLoader();
                                    if (response === "Record already exists") {
                                        uiService.toastService.error(LocationMessage.DUPLICATE_ERROR);
                                    } else {
                                        uiService.toastService.success('Saved Successfully');
                                    }
                                    if (this.props.nominationDataStore.selectedData.CurrentLoadsize !== loadSize) {
                                        const requestBody = {
                                            CreatedBy: userID,
                                            ModifiedBy: userID,
                                            DeleteRecords: [],
                                            IsApportionmentDeleted: false,
                                            NominationLoads: [],
                                            OptionType: 'AdjustLoadsWithNewLoadSize',
                                            NominationDataID: event.data.Id,
                                            AdjustmentVolume:  event.data.CurrentNomination,
                                            DataSource: event.data.DataSource,
                                            ShipperType: [],
                                            PlainsVolume: 0,
                                            OthersVolume: 0
                                        };

                                        const responseJson = await nominationDataService
                                            .createUpdateNominationLoadData(requestBody)
                                            const confirmService = new ConfirmService();
                                            confirmService.showConfirmWithCustomComponent(
                                                () => { },
                                                ConfirmWithOK,
                                                { primaryText: 'Load generation is in progress, Click on the Refresh button to view the Loads' } as ConfirmWithOKProps,
                                                'confirmPopup'
                                            );
                                        
                                    }
                                },
                                    toJS(location), toJS(this.baseValueDTO), locationDataService, filterData, raConfig)
                            },
                            '',
                            'Do you want to verify the O/D pairing and setup the planned load size'
                        );
                        this.getNominationData(
                            this.state.selectedRegion,
                            this.state.selectedProduct,
                            this.state.selectedMonth,
                            this.state.selectedYear
                        );
                    } else {
                        const confirmService = new ConfirmService();
                        confirmService.showConfirmWithCustomComponent(
                            () => { },
                            ConfirmWithOK,
                            { primaryText: 'Location is not present. Please add location details from Location Mapping screen' } as ConfirmWithOKProps,
                            'confirmPopup'
                        );
                    }
                }, toJS(locationsArray));
            }
        } else if (!this.load && event.node.group) {
            if (event.column.colId == 'radio') {
                this.setState({ count: 0 });
            }
        } else {
            if (
                !this.load &&
                event.data.IsEdit &&
                (event.column.colId == 'AX_Origin' || event.column.colId == 'AX_Destination')
            ) {
                this.nominationData = this.props.nominationDataStore.nominationData;
                const confirmService = new ConfirmService();
                confirmService.showNominationLocationPopup(location => {
                    this.props.nominationDataStore.updateLocation(event.data, location, event.column.colId);
                    event.data[event.column.colId] = location;
                    this.props.nominationDataStore.updateRow(event.data);
                    this.props.nominationDataStore.nomiData(this.nominationData);
                }, this.operators);
            } else if (this.load) {
                const csvData: any = this.props.nominationDataStore.agGridService.getNodes();
                if (event.column.getColId() !== 'DELETE') {
                    csvData?.rowModel.rowsToDisplay.forEach((x, index) => {
                        // if(event.data.ID === x.data.ID) {
                        //     this.props.nominationDataStore.handleCheckboxClick(x.data, false, "DELETE");
                        //     x.setDataValue('DELETE', true);
                        // } else {
                        if (x.data.hasOwnProperty('DELETE')) {
                            x.setDataValue('DELETE', false);
                            this.props.nominationDataStore.handleCheckboxClick(x.data, true, 'DELETE');
                        }

                        // }
                    });
                }
                csvData?.rowModel.gridApi.refreshCells();
            }
        }
    };
    onCellEditingStarted = (event: CellEditingStartedEvent): void => {
        const row = event.data.Id;
        const col = event.colDef.field || '';
        const value = event.value;
        const {
            nominationDataStore: { cellValueChangeMap }
        } = this.props;
        const isNotEmpty =
            row in cellValueChangeMap && col in cellValueChangeMap[row] && cellValueChangeMap[row][col].initValue;
        if (!isNotEmpty) {
            this.props.nominationDataStore.setValueInChangeMap(row, col, value, value);
        }
    };

    onCellEditingStopped = (event: CellEditingStoppedEvent): void => {
        const row = event.data.Id;
        const col = event.colDef.field || '';
        const value = event.value;
        const {
            nominationDataStore: { cellValueChangeMap, updateRow }
        } = this.props;
        cellValueChangeMap[row][col]['currentValue'] = value;
        updateRow(event.data);
    };

    onCellEditingStartedLoad = (event: CellEditingStartedEvent): void => {
        const row = event.data.Id;
        const col = event.colDef.field || '';
        const value = event.value;
        const {
            nominationDataStore: { cellValueChangeMap }
        } = this.props;
        const isNotEmpty =
            row in cellValueChangeMap && col in cellValueChangeMap[row] && cellValueChangeMap[row][col].initValue;
        if (!isNotEmpty) {
            this.props.nominationDataStore.setValueInChangeMap(row, col, value, value);
        }
    };

    onCellEditingStoppedLoad = (event: CellEditingStoppedEvent): void => {
        const row = event.data.Id;
        const col = event.colDef.field || '';
        const value = event.value;
        const {
            nominationDataStore: { cellValueChangeMap, updateLoadRow }
        } = this.props;
        cellValueChangeMap[row][col]['currentValue'] = value;
        updateLoadRow(event.data);
    };

    private getMessagesGridConfig(): GridOptions {
        const rowData = this.props.nominationDataStore.nominaData;
        const columnDefs = this.values.columnDefs;
        const onCellClicked = this.onCellclicked;
        const onCellEditingStarted = this.onCellEditingStarted;
        const onCellEditingStopped = this.onCellEditingStopped;
        const onFilterChanged = this.handleFilterChange;
        const aggFuncs = this.aggFuncs;
        const broadcastGridParams: AgGridComponentParams = {
            rowData,
            columnDefs,
            onCellEditingStarted,
            onCellEditingStopped,
            onCellClicked,
            onFilterChanged,
            aggFuncs,
            autoGroupColumnDef: {
                headerName: 'Customer Name',
                field: 'CustomerName',
                width: 85,
                cellRendererParams: {
                  filter: true,
                },
                editable: params => {
                    if (!params.node.group && params.data['IsEdit']) {
                        return true;
                    } else return false;
                },
                rememberGroupStateWhenNewData: true,
                cellClass: params => {
                    if (!params.node.group && params.data['IsEdit']) {
                        return 'cell-editable';
                    } else return 'false';
                }
              }
        };

        return agGridService.getGridConfig(broadcastGridParams);
    }

    private getLoadGridConfig(): GridOptions {
        const rowData = this.props.nominationDataStore.nominationLoadData;
        const columnDefs = this.props.nominationDataStore.getLoadColDef();
        const onCellClicked = this.onCellclicked;
        const onCellEditingStarted = this.onCellEditingStartedLoad;
        const onCellEditingStopped = this.onCellEditingStoppedLoad;
        const onFilterChanged = this.handleLoadFilterChange;
        const onRangeSelectionChanged = this.handlerRangeSelectionChanged;
        const broadcastGridParams: AgGridComponentParams = {
            rowData,
            columnDefs,
            onCellClicked,
            onCellEditingStarted,
            onCellEditingStopped,
            onRangeSelectionChanged,
            onFilterChanged
        };

        return agGridService.getGridConfig(broadcastGridParams);
    }

    handlerRangeSelectionChanged = (e: RangeSelectionChangedEvent): void => {
        const csvData: any = this.props.nominationDataStore.agGridService.getNodes();
        const checkArray: any = [];

        if (e.started === false && e.finished === true) {
            var cellRanges: any = e.api.getCellRanges();
            var startRowIndex = cellRanges[0].startRow.rowIndex;
            var endRowIndex = cellRanges[0].endRow.rowIndex;

            csvData?.rowModel.rowsToDisplay.forEach((x, index) => {
                if (
                    !(
                        x.data['LoadStatus'] === undefined ||
                        x.data['LoadStatus'] === 'Manually Deleted ' ||
                        x.data['LoadStatus'] === 'Apportionment Deleted' ||
                        x.data['LoadStatus'] === 'Completed' ||
                        x.data['LoadStatus'] === 'System Deleted' ||
                        x.data['LoadStatus'] === 'Cancelled by driver'
                    ) &&
                    !(x.data['IsDeleted'] && x.data['IsApportionmentDeleted'] && x.data['IsAdjustDeleted'])
                ) {
                    if (index >= startRowIndex && index <= endRowIndex) {
                        this.props.nominationDataStore.handleCheckboxClick(x.data, false, 'DELETE');
                        x.setDataValue('DELETE', true);
                        checkArray.push('true');
                    } else {
                        checkArray.push(x.data['DELETE'] ? 'true' : 'false');
                    }
                }
            });

            csvData?.rowModel.gridApi.refreshCells();

            // console.log(checkArray);
            if (checkArray.indexOf('false') < 0) {
                this.props.nominationDataStore.selectButtonText = 'Deselect All';
            } else {
                this.props.nominationDataStore.selectButtonText = 'Select All';
            }
        }
    };
    handleLoadFilterChange = async (event: FilterChangedEvent): Promise<void> => {
        this.props.nominationDataStore.navigated
            ? !localStorageService.get('NominationLoad_filter')
                ? this.props.nominationDataStore.agGridService.getAllFilters()
                : localStorageService.set('NominationLoad_filter', localStorageService.get('NominationLoad_filter'))
            : localStorageService.set(
                'NominationLoad_filter',
                this.props.nominationDataStore.agGridService.getAllFilters()
            );
        if (Object.keys(this.props.nominationDataStore.agGridService.getAllFilters()).length > 0) {
            this.props.nominationDataStore.navigated = false;
        }
    }
    handleFilterChange = async (event: FilterChangedEvent): Promise<void> => {
        if (!this.load) {
            this.props.nominationDataStore.navigated || this.props.nominationDataStore.selected === true
                ? localStorageService.set('Nomination_filter', localStorageService.get('Nomination_filter'))
                : localStorageService.set(
                    'Nomination_filter', this.props.nominationDataStore.agGridService.getAllFilters()
                );
        } else {
            this.props.nominationDataStore.navigated
                ? !localStorageService.get('NominationLoad_filter')
                    ? this.props.nominationDataStore.agGridService.getAllFilters()
                    : localStorageService.set('NominationLoad_filter', localStorageService.get('NominationLoad_filter'))
                : localStorageService.set(
                    'NominationLoad_filter',
                    this.props.nominationDataStore.agGridService.getAllFilters()
                );
        }
        if (
            !this.load &&
            !this.props.nominationDataStore.selectedData.IsChild &&
            !this.props.nominationDataStore.CustomerName
        ) {
            const filters: any = localStorageService.get('Nomination_filter');
            if (filters && Object.keys(filters) && Object.keys(filters).length > 0) {
                agGridService.getNodes()?.expandAll();
            }
        }
        if (Object.keys(this.props.nominationDataStore.agGridService.getAllFilters()).length > 0) {
            this.props.nominationDataStore.navigated = false;
        }

        let filteredProduct = this.nominationData;

        if (this.state.selectedProduct === 'All') {
            if (this.state.selectedRegion === 'All') {
            } else {
                filteredProduct = this.nominationData.filter(product => {
                    return product.Region === this.state.selectedRegion;
                });
            }
        } else {
            if (accountStore.userRoles.includes('ptssupplierbutanengl')) {
                filteredProduct = this.nominationData.filter(product => {
                    return product.DataSource === this.state.selectedProduct;
                });
                if (this.state.selectedRegion === 'All') {
                } else {
                    filteredProduct = filteredProduct.filter(product => {
                        return product.Region === this.state.selectedRegion;
                    });
                }
            } else {
                filteredProduct = this.nominationData.filter(product => {
                    return product.DataSource === this.state.selectedProduct;
                });
                if (this.state.selectedRegion === 'All') {
                } else {
                    filteredProduct = filteredProduct.filter(product => {
                        return product.Region === this.state.selectedRegion;
                    });
                }
            }
        }

        const filteredData: any = JSON.parse(
            JSON.stringify(this.props.nominationDataStore.calculateCrudeWithCustomerName(filteredProduct))
        );
        const filters: any = agGridService.getAllFilters();
        const VolumeCols = ['NominationVolume', 'CurrentNomination', 'ApportionmentVolume', 'VolsToBeTrucked', 'PendingVolume', 'DispatchedVolume', 'CompletedVolume']
        const data = filteredData.filter(dat =>
            Object.keys(filters).every(k => {
                let pendingVol = (dat['PendingVolume'] != null && dat['PendingVolume'] > 0) ? dat['PendingVolume'] : 0.0
                let dispatchedVol = (dat['DispatchedVolume'] != null && dat['DispatchedVolume'] > 0) ? dat['DispatchedVolume'] : 0.0
                let completedVol = (dat['CompletedVolume'] != null && dat['CompletedVolume'] > 0) ? dat['CompletedVolume'] : 0.0
                let VTTVol = (dat['VolsToBeTrucked'] != null && dat['VolsToBeTrucked'] > 0) ? dat['VolsToBeTrucked'] : 0.0
                
                let TOV = ((pendingVol + dispatchedVol + completedVol <= 0) || (pendingVol === null && dispatchedVol === null && completedVol === null)) ? '0.000'
                : this.roundOff(parseFloat(pendingVol + dispatchedVol + completedVol ))

                let completed = VTTVol + completedVol === 0 ? '0.000' : completedVol === null
                ? '0.000' : VTTVol === 0 || VTTVol === null
                ? pendingVol + dispatchedVol + completedVol === 0
                ? '0.000' : this.roundOff((completedVol /(pendingVol + dispatchedVol + completedVol)) * 100)
                : this.roundOff((completedVol / VTTVol) * 100)
                
                let createdDate = momenttimezone.tz(moment.utc(dat['CreatedDate']), AppConstant.MST_TIME_FORMATTER).format(AppConstant.DATE_TIME_FORMAT)
                let modifiedDate = momenttimezone.tz(moment.utc(dat['ModifiedDate']), AppConstant.MST_TIME_FORMATTER).format(AppConstant.DATE_TIME_FORMAT)

                if (k === 'ag-Grid-AutoColumn') return filters[k].values.includes(dat['CustomerName']);
                else if (VolumeCols.includes(k)) return filters[k].values.includes(this.roundOff(dat[k]));
                else if (k === '0') return filters[k].values.includes(TOV);
                else if (k === '1') return filters[k].values.includes(completed);
                else if (k === 'CreatedDate') return filters[k].values.includes(createdDate);
                else if (k === 'ModifiedDate') return filters[k].values.includes(modifiedDate);
                else return filters[k].values.includes(dat[k]);
            })
        );
        this.props.nominationDataStore.getVolumeSum(data);
    };
}
