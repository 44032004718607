import React, { Component } from 'react';
import { PrinterDataStore } from '../stores/printer-data-store';
import { CellClickedEvent, CellEditingStartedEvent, CellEditingStoppedEvent, GridOptions } from 'ag-grid-community';
import { Container, Row, Col } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { CustomButtonType, CustomButton } from 'shared/components/custom-button/custom-button';
import './_master-data.scss';
import { AgGridComponent } from 'features/common/components/ag-grid-component';
import { AgGridComponentParams } from 'features/common/domain/model';
import { dataStore } from '../../common/stores/index';
import { PrinterSaveLabel } from '../domains/printer-management/printer-enum';
import { RESET, ADD_NEW_ROW, DELETE_ITEM } from '../data_constant';
import { ClearButtonComponent } from 'features/common/components/clear-button-component';
import { localStorageService } from 'shared/services/local-storage-service';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import { AppConstant } from 'app_constant';
import { KeyfobsDataService } from '../services/keyfobs-data-service';
import { KeyfobsDataStore } from '../stores/keyfobs-data-store';
import { keyFobstore } from '../stores';
import { KeyfobFieldName } from '../domains/keyfobs.enum';
import { DispatchPlanPopUpComponent } from 'features/dispatch/dispatch-plan-management/components/dispatchPlan-popup-component';
import moment from 'moment';
import { TrailerManagementCarrierPopupComponent } from './trailer-management-carrier-popup-component';
import { KeyFobManagementPopupComponent } from './keyfob-management-popup-component';

interface PrinterMasterDataProps {
    keyfobsStore: KeyfobsDataStore;
}

@observer
export class KeyfobMasterDataComponent extends Component<PrinterMasterDataProps> {
    data: any = [];
    constructor(props) {
        super(props);
        dataStore.setModuleName('KeyFobManagement');
    }

    componentDidMount() {
        this.props.keyfobsStore.init();
    }

    componentWillUnmount() {
        this.props.keyfobsStore.reset();
    }

    render(): React.ReactNode {
        const {
            keyfobsStore: { isSaveDisabled, isDeleteEnabled, showModal, showCCModal, showLocModal }
        } = this.props;
        return (
            <Container fluid>
                <Row>
                    <Col>
                        <div className="master-data__action">
                            <Row>
                                <Col className="mt-3 mt-sm-0">
                                    {dataStore.checkOperationAccess('Save') && (
                                        <>
                                            <CustomButton
                                                type={CustomButtonType.Submit}
                                                onClick={this.handleSave}
                                                disabled={isSaveDisabled()}
                                            >
                                                SAVE DATA
                                            </CustomButton>
                                            <CustomButton
                                                type={CustomButtonType.Submit}
                                                onClick={this.handleReset}
                                                disabled={isSaveDisabled()}
                                            >
                                                {RESET}
                                            </CustomButton>

                                            <CustomButton type={CustomButtonType.Submit} onClick={this.addNewRow}>
                                                {ADD_NEW_ROW}
                                            </CustomButton>

                                            <CustomButton
                                                type={CustomButtonType.Submit}
                                                onClick={this.deleteitems}
                                                disabled={isDeleteEnabled()}
                                            >
                                                {DELETE_ITEM}
                                            </CustomButton>
                                        </>
                                    )}
                                    <ClearButtonComponent handleRefresh={this.handleRefresh} isExportCSV={true}></ClearButtonComponent>
                                </Col>
                            </Row>
                        </div>
                        {/* <Row>
                                <Col>
                                    <DispatchPlanPopUpComponent
                                        startDate={this.props.keyfobsStore.assignedDate}
                                        onHide={this.handlePopUpClose}
                                        onSelectionChange={this.handleDateChange}
                                        show={showModal}
                                    ></DispatchPlanPopUpComponent>
                                </Col>
                            </Row> */}
                            <Row>
                            <Col>
                                <KeyFobManagementPopupComponent
                                    startDate={this.props.keyfobsStore.assignedDate}
                                    carriers={this.data}
                                    selectedCarrierId={1}
                                    onHide={this.handleCarrierPopUpClose}
                                    onSelectionChange={this.handleCarrierChange}
                                    show={showModal}
                                    showCCModal={showCCModal}
                                    showLocModal={showLocModal}
                                ></KeyFobManagementPopupComponent>
                            </Col>
                        </Row>
                        <AgGridComponent gridConfig={this.getGridConfig()} />
                    </Col>
                </Row>
            </Container>
        );
    }
    componentDidUpdate() {
        const filters = localStorageService.get('KeyfobManagement_filter');
        this.props.keyfobsStore.agGridService.setAllFilters(filters);
    }

    private handleSave = (): void => {
        const { keyfobsStore } = this.props;
        console.log('save')
        keyfobsStore.updatePrinters();
    };

    private handleReset = (): void => {
        const { keyfobsStore } = this.props;
        keyfobsStore.resetPrinterList();
    };

    private addNewRow = (): void => {
        const { keyfobsStore } = this.props;
        keyfobsStore.addRow();
    };

    private deleteitems = (): void => {
        const { keyfobsStore } = this.props;
        keyfobsStore.deleteRows();
    };

    handleRefresh = () => {
        const { keyfobsStore } = this.props;
        if(Object.keys(keyfobsStore.cellValueChangeMap).length === 0) {
            keyfobsStore.init();
            keyfobsStore.dataToBeDeleted = [];
            keyfobsStore.cellValueChangeMap = {}
        } 
        else {
            const confirmService = new ConfirmService();
            confirmService.showConfirmDialog(
                () => {
                    keyfobsStore.init();
                    keyfobsStore.dataToBeDeleted = [];
                    keyfobsStore.cellValueChangeMap = {}
                },
                '',
                AppConstant.REFRESH_CONFIRMATION
            );
        }
    }
    
    private getGridConfig(): GridOptions {
        const {
            keyfobsStore: { printerList, agGridService, getColDef }
        } = this.props;

        const rowData = printerList;
        const columnDefs = getColDef();
        const onCellEditingStarted = this.onCellEditingStarted;
        const onCellEditingStopped = this.onCellEditingStopped;
        const onCellClicked = this.onCellclicked;

        const PrinterMaintenanceGridParams: AgGridComponentParams = {
            rowData,
            columnDefs,
            onCellEditingStarted,
            onCellEditingStopped,
            onCellClicked
        };
        return agGridService.getGridConfig(PrinterMaintenanceGridParams);
    }
    onCellEditingStarted = (event: CellEditingStartedEvent): void => {
        const row = event.data.ID;
        const col = event.colDef.field || '';
        const value = event.value;
        const {
            keyfobsStore: { cellValueChangeMap }
        } = this.props;
        console.log(row, col)
        const isNotEmpty =
            row in cellValueChangeMap && col in cellValueChangeMap[row] && cellValueChangeMap[row][col].initValue;
        if (!isNotEmpty) {
            keyFobstore.setValueInChangeMap(row, col, value, value);
        }
    };
    onCellEditingStopped = (event: CellEditingStoppedEvent): void => {
        try {
            const row = event.data.ID;
            const col = event.colDef.field || '';
            const value = event.value;
            const {
                keyfobsStore: { cellValueChangeMap, updateRow }
            } = this.props;
            cellValueChangeMap[row][col]['currentValue'] = value;
            updateRow(event.data);
        } catch (err) {
            console.log(err);
        }
    };

    onCellclicked = (event: CellClickedEvent): void => {
        const row = event.data.ID;
            const col = event.colDef.field || '';
            const value = event.value;
            const {
                keyfobsStore: { cellValueChangeMap, updateRow }
            } = this.props;

            if (event.column.getColId() === KeyfobFieldName.ASSIGNEDDATE) {
                keyFobstore.assignedDate = event.data.AssignedDate ? event.data.AssignedDate : moment(new Date());
                keyFobstore.showTicketApproverPopUp()
                keyFobstore.approvedCommentTicketModel = event.data
                keyFobstore.approvedColumn = KeyfobFieldName.ASSIGNEDDATE
            }
            if (event.column.getColId() === KeyfobFieldName.CARRIERCOMPANY) {
                this.data = keyFobstore.companyData;
                keyFobstore.showCarrierPopUp()
                keyFobstore.approvedCommentTicketModel = event.data
                keyFobstore.approvedColumn = KeyfobFieldName.CARRIERCOMPANY
            }
            if (event.column.getColId() === KeyfobFieldName.LOCATION) {
                this.data = keyFobstore.locationData;
                keyFobstore.showLocationPopUp()
                keyFobstore.approvedCommentTicketModel = event.data
                keyFobstore.approvedColumn = KeyfobFieldName.LOCATION
            }
    }
    private handlePopUpClose = () => {
        this.props.keyfobsStore.hideTicketApproverPopUp();
    };

    private handleDateChange = (comment: string) => {
        this.props.keyfobsStore.updatePlannedDateTime(moment(comment).format('YYYY-MM-DDTHH:mm:ss'));
        this.props.keyfobsStore.agGridService.refreshGrid();
    };

    private handleCarrierPopUpClose = () => {
        this.props.keyfobsStore.hideCarrierPopUp();
    };

    private handleCarrierChange = (comment: string) => {
        this.props.keyfobsStore.updateCarrierCompany(comment);
        this.props.keyfobsStore.agGridService.refreshGrid();
    };
}
