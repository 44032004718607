import React, { Component } from 'react';
import { GridReadyEvent, ColDef, GridOptions, CellValueChangedEvent, ColumnMovedEvent, FilterChangedEvent, FilterOpenedEvent, ColumnPinnedEvent, ColumnResizedEvent, ColumnVisibleEvent} from 'ag-grid-community';
import { ticketGridService } from '../services/ticket-grid-service';
import { localStorageService } from 'shared/services/local-storage-service';
import { ColumnState } from 'ag-grid-community/dist/lib/columnController/columnController';
import { dataStore } from 'features/common/stores';
import { TicketModel } from '../model/ticket-response-model';
import { DataGrid } from 'shared/components/data-grid/data-grid';
import { accountStore } from 'features/account/stores';
interface TicketListProps {
    ticketListItems: TicketModel[];
    columnDefs: ColDef[];
    onCellValueChanged?: (value: CellValueChangedEvent) => void;
    onFilterChanged?: (value: FilterChangedEvent) => void;
}

export class TicketList extends Component<TicketListProps> {
    render(): React.ReactNode {
        return this.renderContent(this.getGridConfig());
    }

    getGridConfig(): GridOptions {
        const { ticketListItems, columnDefs, onCellValueChanged, onFilterChanged } = this.props;
        const gridOptions: GridOptions = {
            columnDefs,
            rowData: ticketListItems,
            onColumnMoved: this.onColumnMoved,
            onColumnPinned: this.onColumnPinned,
            onColumnResized: this.onColumnResized,
            onColumnVisible: this.onColumnVisible,
            onGridReady: this.handleGridReady,
            onCellValueChanged,
            onFilterChanged,
        };
        const config = ticketGridService.getTicketGridOptions();
        return { ...gridOptions, ...config };
    }

    onColumnMoved = (event: ColumnMovedEvent): void => {
        const { api, columnApi } = event;
        const userId= accountStore.getUserID()
        var columnState = columnApi.getColumnState();
        let moduleName = dataStore.getCurrentModuleName()
        localStorageService.set(moduleName + '_' + userId, columnState);
    }

    onColumnPinned = (event: ColumnPinnedEvent): void => {
        const { columnApi } = event;
        const userId= accountStore.getUserID()
        var columnState = columnApi.getColumnState();
        let moduleName = dataStore.getCurrentModuleName();
        localStorageService.set(moduleName + '_' + userId, columnState);
    }

    onColumnResized = (event: ColumnResizedEvent): void => {
        const { columnApi } = event;
        const userId= accountStore.getUserID()
        var columnState = columnApi.getColumnState();
        let moduleName = dataStore.getCurrentModuleName();
        localStorageService.set(moduleName + '_' + userId, columnState)
    }
    
    onColumnVisible = (event: ColumnVisibleEvent): void => {
        const { columnApi } = event;
        const userId= accountStore.getUserID()
        var columnState = columnApi.getColumnState();
        let moduleName = dataStore.getCurrentModuleName();
        localStorageService.set(moduleName + '_' + userId, columnState)
    }

    handleGridReady = (event: GridReadyEvent) => {
        const { api, columnApi } = event;
        const userId= accountStore.getUserID()
        let moduleName = dataStore.getCurrentModuleName()
        var columnState =  localStorageService.get(moduleName + '_' + userId);
        try {
            if (columnState) {
                let columnArrangement = columnState as ColumnState[];
                columnApi.setColumnState(columnArrangement);
            }
        }
        catch (e) {
            localStorageService.remove(moduleName + '_' + userId);
            console.log('error in getting Column arrangement for module', e);
        }
        console.log(columnApi);
        ticketGridService.setColumnApi(columnApi);
        ticketGridService.setGridApi(api);
    };

    renderContent(options: GridOptions): React.ReactNode {
        return (
            <div>
                <DataGrid gridOption={options} />
            </div>
        );
    }
}
