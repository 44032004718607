import { action, observable, computed, toJS } from 'mobx';
import _, { isEmpty } from 'lodash';
import { AgGridService } from 'features/common/services/ag-grid-service';
import { AccountStore } from 'features/account/stores/account-store';
import { SearchTicketsDataService } from '../services/search-tickets-data-service';
import { searchTicketsColDef } from '../domains/search-tickets-col-def';
import Loader from '../../../shared/decorators/loader-decorator';
import Catch from '../../../shared/decorators/catch-decorator';

import { errorHandler } from '../../../shared/handlers/error-handler';
import { ReportMessage } from '../domains/enum';
import { ToastMessage } from 'shared/components/custom-toast/custom-toast';
import { uiService, UiService } from 'shared/services/ui-service';

export class SearchTicketsDataStore {
    @observable ticketsData: any[] = [];
    @observable startDate: any = '';
    @observable endDate: any = '';
    constructor(
        private searchTicketsDataService: SearchTicketsDataService, 
        public agGridService: AgGridService, 
        public uiService: UiService,
        private accountStore: AccountStore
    ) {}
    
    @Loader
    @action
    @Catch(() => errorHandler(ReportMessage.FAILED_SUBMIT))
    async searchByTicket(ticketNumber): Promise<void> {
        const requestBody = [{
            TicketNumber: ticketNumber.toString()
        }]
        const searchTickets = await this.searchTicketsDataService.getSearchTickets(requestBody[0]);
        this.ticketsData = searchTickets
        if (this.ticketsData.length == 0) {
            uiService.toastService.info(ReportMessage.NO_RECORDS);
        }
    }

    @Loader
    @action
    @Catch(() => errorHandler(ReportMessage.FAILED_SUBMIT))
    async searchByDriver(firstName, lastName, dateType, startDate, endDate): Promise<void> {
        const requestBody = [{
            FirstName: firstName.trim().length === 0 ? null : firstName,
            LastName: lastName.trim().length === 0 ? null : lastName,
            DateType: dateType,
            StartDate: startDate,
            EndDate: endDate
        }]
        const searchTickets = await this.searchTicketsDataService.getSearchTickets(requestBody[0]);
        this.ticketsData = searchTickets
        if (this.ticketsData.length == 0) {
            uiService.toastService.info(ReportMessage.NO_RECORDS);
        }
    }

    getColDef() {
        return searchTicketsColDef;
    }

    @computed
    get DuplicateList(): any {
        return toJS(this.ticketsData);
    }

    @action
    setTicketsData(data: any): void {
        this.ticketsData = data;
    }
}
