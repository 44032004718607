import { UserApprovalHeaderName, UserApprovalFieldName } from './domains/enum';
import { ColDef, ValueFormatterParams } from 'ag-grid-community';
import { CurrentStatusCellRenderer } from './components/cell-renderers/current-status-cell-renderer';
import { dateUtils } from 'shared/services/date-utils';
import { dataStore } from 'features/common/stores';
import { MappedCarrierCellRenderer } from './components/cell-renderers/mapped-carrier-cell-renderer';
import { UserApprovalRadioCellRenderer } from './components/user-approval-radio-cell-renderer'
export const dataTypeOptions = [
    { value: 'Select', label: 'Select' },
    { value: 'UserApproval', label: 'User Approval' }
];

export const defaultSelectedShop = { value: 'Select', label: 'Select' };

export const APPROVE_STATUS = 'APPROVE';
export const UserRole = [
    { value: 'Driver', Name: 'Driver' },
    { value: 'OwnerDriver', Name: 'Owner/Driver' },
    { value: 'CarrierCompanyAdmin', Name: 'Carrier Company Admin' },
    { value: 'CarrierCompanyScheduler', Name: 'Carrier Company Scheduler' },
    { value: 'Dispatcher', Name: 'Thirdparty Dispatcher'}
];
const ADStatus = [
    { value: 'AWAITINGACTIVATION', Name: 'Awaiting Activation' },
    { value: 'APPROVED', Name: 'Approved' },
    { value: 'INACTIVE', Name: 'Inactive' },
    
];
export const UserTypes = ['Carrier Company Scheduler', 'Carrier Company Admin', 'CarrierOwner', 'Owner', 'Driver', 'Owner/Driver', 'Thirdparty Dispatcher']
const plainsTruckingSystem = {
    headerName: UserApprovalHeaderName.PlainsTruckingSystem,
    field: UserApprovalFieldName.PlainsTruckingSystem,
    width: 120,
    cellRendererFramework: CurrentStatusCellRenderer,
    colId: UserApprovalFieldName.PlainsTruckingSystem,
    cellClass: () => (dataStore.checkOperationAccess('PTSApprove') ? 'cell-editable' : '')
};

const plainsSchedulingApp = {
    headerName: UserApprovalHeaderName.PlainsSchedulingApp,
    field: UserApprovalFieldName.PlainsSchedulingApp,
    width: 120,
    cellRendererFramework: CurrentStatusCellRenderer,
    colId: UserApprovalFieldName.PlainsSchedulingApp,
    cellClass: () => (dataStore.checkOperationAccess('PSAApprove') ? 'cell-editable' : '')
};

export const userInfoColDef: ColDef[] = [
    { headerName: UserApprovalHeaderName.ID, field: UserApprovalFieldName.ID, hide: true },
    {
        headerName: UserApprovalHeaderName.Select,
        field: UserApprovalFieldName.Select,
        width: 65,
        cellRendererFramework: UserApprovalRadioCellRenderer,
    },
    {
        headerName: UserApprovalHeaderName.Email,
        field: UserApprovalFieldName.Email,
        width: 65,
        valueGetter: params => {
            if (params.data[UserApprovalFieldName.Email] === null) {
                return '';
            } else {
                return params.data[UserApprovalFieldName.Email];
            }
        }
    },
    {
        headerName: UserApprovalHeaderName.FirstName,
        field: UserApprovalFieldName.FirstName,
        width: 90,
        valueGetter: params => {
            if (params.data[UserApprovalFieldName.FirstName] === null) {
                return '';
            } else {
                return params.data[UserApprovalFieldName.FirstName];
            }
        }
    },
    {
        headerName: UserApprovalHeaderName.LastName,
        field: UserApprovalFieldName.LastName,
        width: 90,
        valueGetter: params => {
            if (params.data[UserApprovalFieldName.LastName] === null) {
                return '';
            } else {
                return params.data[UserApprovalFieldName.LastName];
            }
        }
    },
    {
        headerName: UserApprovalHeaderName.PhoneNumber,
        field: UserApprovalFieldName.PhoneNumber,
        width: 115,
        valueGetter: params => {
            if (params.data[UserApprovalFieldName.PhoneNumber] === null) {
                return '';
            } else {
                return params.data[UserApprovalFieldName.PhoneNumber];
            }
        }
    },
    {
        headerName: UserApprovalHeaderName.CarrierName,
        field: UserApprovalFieldName.CarrierName,
        width: 130,
        valueGetter: params => {
            if (params.data[UserApprovalFieldName.CarrierName] === null) {
                return '';
            } else {
                return params.data[UserApprovalFieldName.CarrierName];
            }
        }
    },
    {
        headerName: UserApprovalHeaderName.DriverType,
        field: UserApprovalFieldName.DriverType,
        width: 105,
        cellEditor: 'agSelectCellEditor',
        colId: UserApprovalFieldName.DriverType,
        editable: () => (dataStore.checkOperationAccess('UserInfoEdit') ? true : false),
        cellClass: () => (dataStore.checkOperationAccess('UserInfoEdit') ? 'cell-editable' : ''),
        valueGetter: params => {
            if (params.data[UserApprovalFieldName.DriverType] === null) {
                return '';
            } else {
                return params.data[UserApprovalFieldName.DriverType];
            }
        }
    },
    {
        headerName: UserApprovalHeaderName.UserType,
        field: UserApprovalFieldName.UserType,
        width: 90,
        valueGetter: params => {
            if (params.data[UserApprovalFieldName.UserType] === null) {
                return '';
            } else {
                return params.data[UserApprovalFieldName.UserType];
            }
        }
    },
    {
        headerName: UserApprovalHeaderName.ADStatus,
        field: UserApprovalFieldName.ADStatus,
        width: 65,
        cellRendererFramework: CurrentStatusCellRenderer,
        colId: UserApprovalFieldName.ADStatus,
    },
    {
        headerName: UserApprovalHeaderName.Facilities,
        field: UserApprovalFieldName.Facilities,
        width: 80,
        valueGetter: params => {
            if (params.data[UserApprovalFieldName.Facilities] === null) {
                return '';
            } else {
                return params.data[UserApprovalFieldName.Facilities];
            }
        }
    },
    {
        headerName: UserApprovalHeaderName.MappedCarrierName,
        field: UserApprovalFieldName.MappedCarrierName,
        width: 115,
        cellRendererFramework: MappedCarrierCellRenderer,
        cellClass: (params) => (dataStore.checkOperationAccess('UserInfoEdit') && (params.data["PSAStatusCode"] === 'APPROVED' || params.data["StatusCode"] === 'APPROVED') && params.data[UserApprovalFieldName.MappedCarrierName] ? 'cell-editable' : ''),
        valueGetter: params => {
            if (params.data[UserApprovalFieldName.MappedCarrierName] === null) {
                return '';
            } else {
                return params.data[UserApprovalFieldName.MappedCarrierName];
            }
        }
    },
    {
        headerName: UserApprovalHeaderName.PSAMappedCarrierName,
        field: UserApprovalFieldName.PSAMappedCarrierName,
        width: 105,
        valueGetter: params => {
            if (params.data[UserApprovalFieldName.PSAMappedCarrierName] === null) {
                return '';
            } else {
                return params.data[UserApprovalFieldName.PSAMappedCarrierName];
            }
        }
    },

    plainsTruckingSystem,
    plainsSchedulingApp,
    {
        headerName: UserApprovalHeaderName.CreatedDate,
        field: UserApprovalFieldName.CreatedDate,
        width: 100,
        valueGetter: params => {
            if (params.data[UserApprovalFieldName.CreatedDate] === null) {
                return '';
            } else {
                return dateUtils.getFormattedDateTime(params.data[UserApprovalFieldName.CreatedDate]);
            }
        }
    },
    {
        headerName: UserApprovalHeaderName.ModifiedDate,
        field: UserApprovalFieldName.ModifiedDate,
        width: 100,
        valueGetter: params => {
            if (params.data[UserApprovalFieldName.ModifiedDate] === null) {
                return '';
            } else {
                return dateUtils.getFormattedDateTime(params.data[UserApprovalFieldName.ModifiedDate]);
            }
        }
    },
    {
        headerName: UserApprovalHeaderName.ModifiedByUserName,
        field: UserApprovalFieldName.ModifiedByUserName,
        width: 95,
        valueGetter: params => {
            if (params.data[UserApprovalFieldName.ModifiedByUserName] === null) {
                return '';
            } else {
                return params.data[UserApprovalFieldName.ModifiedByUserName];
            }
        }
    }
];
