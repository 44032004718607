
import { Component } from 'react';
import React from 'react';
import { observer } from 'mobx-react';
import '../../dispatch/dispatch-plan-management/components/_dispatch-driver-popup.scss';
import { ReactSelect } from 'shared/components/select/select';
import { CustomButton, CustomButtonType } from 'shared/components/custom-button/custom-button';
import { CarrierCompany } from '../domains/model';
import { DataAdapterService } from 'shared/services/data-adapter-service';
import Select from 'react-select';

interface KeyFobManagementProps {
    selectedLocationId: number;
    LocationName: string;
    locations:any
    onHide: () => void;
    onSelectionChange: (location: string) => void;
}

interface KeyFobManagementState {
    selectedLocationId: number;
    selectedLocationName: string;
}

@observer
export class KeyFobManagementLocationComponent extends Component<KeyFobManagementProps, KeyFobManagementState> {
    constructor(props) {
        super(props);
        this.state = {
            selectedLocationId: 0,
            selectedLocationName: ""
        };
    }

    componentDidUpdate() {
        if (this.props.selectedLocationId != this.state.selectedLocationId) {
            this.setState({ selectedLocationId: this.props.selectedLocationId, selectedLocationName: this.state.selectedLocationName });
        }
    }

    mapCarrierToCarrierOption = locations => DataAdapterService.getOptionValues(locations, 'Description', 'ID', false);

    getSelectedShopOption = (id, carriers) => {
        let selectedShopObj = carriers.filter(s => parseInt(s.value) === id)[0];
        return selectedShopObj;
    };

    render(): React.ReactNode {
        const { locations } = this.props;
        const { selectedLocationId, selectedLocationName } = this.state;
        console.log(locations);
        const shopOptions = this.mapCarrierToCarrierOption(locations);
        const selectedShopOption = this.getSelectedShopOption(selectedLocationId, shopOptions);
        return (
            <div className="dispatch-driver-popup__parent">
                <label className="dispatch-driver-popup__label">Location</label>
                <div className={'dispatch-driver-popup__type'}>
                    <Select
                        values={selectedShopOption}
                        placeholder="Select Location"
                        options={shopOptions}
                        onChange={this.handleShopChange}
                        isMulti={false}
                    />
                </div>

                <div className="dispatch-driver-popup__buttongroup mt-10">
                    <CustomButton type={CustomButtonType.Cancel} onClick={this.props.onHide}>
                        Cancel
                    </CustomButton>
                    <CustomButton type={CustomButtonType.Ok} onClick={this.assignCarrier}>
                        Ok
                    </CustomButton>
                </div>
            </div>
        );
    }

    handleShopChange = option => {
        this.setState({ selectedLocationName: option.label, selectedLocationId: option.value });
    }

    assignCarrier = () => {
        this.props.onSelectionChange(this.state.selectedLocationName);
        this.props.onHide();
    };
}
