import { ResponseDataType } from 'shared/types/shared';

import { Config } from 'config';
import { RestApiService } from 'shared/services/rest-api-service';
import { restApiService } from 'shared/services';
import { UserApprovalViewModel, AllCarrierCompany } from '../model/model';
import { UserApprovalRequest, UserApprovalResponse } from '../model/userApprovalResponse';

export class UserApprovalDataService {
    constructor(private restApi: RestApiService) { }

    private getUserApprovalInfo(reqbody: any): Promise<ResponseDataType<UserApprovalResponse[]>> {
        return this.restApi.postWithToken<UserApprovalResponse[]>(Config.userApprovalInfo, reqbody);
    }

    private getFacilities(): Promise<ResponseDataType<AllCarrierCompany[]>> {
        return this.restApi.post<AllCarrierCompany[]>(Config.getFacility);
    }

    private getCarrierCompany(): Promise<ResponseDataType<any>> {
        return this.restApi.post<any[]>(Config.getCarrierCompany);
    }

    private getPSACarrier(): Promise<ResponseDataType<any>> {
        return this.restApi.post<any[]>(Config.getPSACarrier);
    }

    async getUserApprovalViewModel(reqbody: any): Promise<UserApprovalViewModel> {
        const userApprovalInfoPromise = this.getUserApprovalInfo(reqbody);
        const facilitiesPromise = this.getFacilities();
        const carrierCompanyPromise = this.getCarrierCompany();
        const psaCarrierPromise = this.getPSACarrier();
        const [{ data: userApprovalInfo }, { data: facilities }, { data: carrierCompany }, {data: psaCarrier}] = await Promise.all([
            userApprovalInfoPromise,
            facilitiesPromise,
            carrierCompanyPromise,
            psaCarrierPromise,
        ]);
        return {
            userApprovalInfo: userApprovalInfo,
            facilities: facilities,
            carrierCompany: carrierCompany,
            psaCarrier: psaCarrier,
        };
    }
    async updateUserInfo(reqbody: UserApprovalResponse, isPSA: boolean): Promise<any> {
        const url = isPSA ? Config.updateUserPSA : Config.updateUserPTS;
        const { data } = await this.restApi.postWithToken<any>(url, reqbody, {});
        return [data['Data'],isPSA];
    }

    async disapproveUser(reqbody: UserApprovalResponse, isPSA: boolean): Promise<any> {
        const url = isPSA ? Config.disApproveUserPSA : Config.disApproveUserPTS;
        const { data } = await this.restApi.postWithToken<any>(url, reqbody, {});
        return data['Data'];
    }

    async importPSAData(reqbody: UserApprovalResponse): Promise<any> {
        const url = Config.importPSAData;
        const { data } = await this.restApi.postWithToken<any>(url, reqbody, {});
        return data['Data'];
    }

    async updateUserDetails(reqbody: any[]): Promise<any> {
        const url = Config.updateUserDetails;
        const { data } = await this.restApi.postWithToken<any>(url, reqbody, {});
        return data['Result'];
    }

    async removeUserRole(reqbody: any[]): Promise<any> {
        const url = Config.removeUserRole;
        const { data } = await this.restApi.postWithToken<any>(url, reqbody, {});
        return data['Result'];
    }
    
    async getOpenTicketsForDriver(request): Promise<any> {
        return this.restApi.postWithToken<any>(Config.getOpenTicketsForDriver, request, {});
    }

    async createCarrierCompanyInfo(request) {
        return this.restApi.postWithToken<any>(Config.createCarrierCompanyInfo, request, {})
    }

    async deactivateUser(reqbody: any[]): Promise<any> {
        const { data } = await this.restApi.postWithToken<any>(Config.deactivateUser, reqbody, {});
        return data['Result'];
    }
}

export const userApprovalDataService = new UserApprovalDataService(restApiService);
