import React from 'react';
// import './style.scss';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import { uiService } from 'shared/services/ui-service';
import { CustomButton, CustomButtonType } from 'shared/components/custom-button/custom-button';
import { localStorageService } from '../../../../shared/services/local-storage-service';
import { LocationDataService, locationDataService } from 'features/data_management/domains/location-management/location-data-service';
import { dataStore } from 'features/common/stores';
import { Col, Row } from 'react-bootstrap';
import { AgGridComponent } from 'features/common/components/ag-grid-component';
import { GridOptions } from 'ag-grid-community';
import { AgGridComponentParams } from 'features/common/domain/model';
import { DispatchScreenDataStore } from 'features/dispatch/dispatch-screen/stores/dispatch-screen-store';
import { dispatchScreenDataStore } from 'features/dispatch/dispatch-screen/stores';
import { observable, toJS } from 'mobx';
import { DispatchBoardDataStore } from 'features/dispatch/dispatch-board/store/dispatch-board-store';
import { DispatchBoardDataRequest } from 'features/dispatch/dispatch-board/model/model';
import { dispatchBoardDataStore } from 'features/dispatch/dispatch-board/store';
import { ReactDayPickerInput } from 'shared/components/daypicketInput/react-dayPicketDispatchBoard';
import { NominationConfigPopUpComponent } from './nomination-config-popup';
import { DriverConfigPopupComponent } from './driver-config.popup';
import { getDispatchColDef, getDriverColDef, nominationBoardColDef } from './dispatch-screen-col-def';
import { boards, unsavedMsg, ticketType } from '../dispatchScreen-constants';
import { ClearButtonComponent } from 'features/common/components/clear-button-component';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import { ConfirmProps, TicketsDispatchPopUp } from './dispatch-loads-popup';
interface DispatchBoardStates {
    selectedMonth: any;
    selectedYear: any;
    selectedBoard: any;
    locationList: any;
    showLoader: boolean;
    datesOfMonth: any;
    nomiConfigOpen: boolean;
    driverConfigOpen: boolean;
    selectedType: any;
}
interface DispatchBoardProps {
    dispatchBoardDataStore?: DispatchBoardDataStore;
    dispatchScreenDataStore: DispatchScreenDataStore;
}

@inject('dispatchScreenDataStore')
@observer
export class DispatchScreen extends React.Component<DispatchBoardProps, DispatchBoardStates> {
    showLoader: boolean = true;
    locationData: any = [];
    @observable selectedLocation: any = [];
    @observable customOptions: any = [];
    dispatchTicketGrid: any;
    constructor(props) {
        super(props);
        const datesOfMonth = Array.from(
            Array(this.daysInMonth(new Date().getMonth() + 1, new Date().getFullYear())),
            (_, i) => i + 1
        );
        this.state = {
            selectedMonth: new Date().getMonth(),
            selectedYear: new Date().getFullYear(),
            selectedBoard: [],
            locationList: [],
            showLoader: false,
            datesOfMonth: datesOfMonth,
            nomiConfigOpen: false,
            driverConfigOpen: false,
            selectedType: 'All'
        };
        dataStore.setModuleName('DispatchScreen');
    }
    /* eslint-disable */
    async componentDidMount() {
        dispatchScreenDataStore.plannedLoads = [];
        dispatchScreenDataStore.GetMasterData();
    }

    componentDidUpdate(): void {
        dispatchScreenDataStore.agGridService.refreshGrid();
        if (!this.state.nomiConfigOpen && !this.state.driverConfigOpen) {
            dispatchScreenDataStore.agGridService.setAllFilters(localStorageService.get('DispatchScreen_filter'));
        }
    }

    handleClose = () => {
        if (dispatchScreenDataStore.dataToBeDeleted.length > 0 || dispatchScreenDataStore.selectedLocation.length > 0) {
            const confirmService = new ConfirmService();
            confirmService.showConfirmDialog(() => {
                this.setState({
                    nomiConfigOpen: false,
                    driverConfigOpen: false
                });
                dispatchScreenDataStore.dataToBeDeleted = [];
                dispatchScreenDataStore.selectedLocation = [];
                dataStore.setModuleName('DispatchScreen');
                dispatchScreenDataStore.agGridService.setGridApi(this.dispatchTicketGrid?.rowModel.gridApi);
                this.dispatchTicketGrid?.rowModel.gridApi.refreshCells();
            }, '', unsavedMsg)
        } else {
            this.setState({
                nomiConfigOpen: false,
                driverConfigOpen: false
            });
            dispatchScreenDataStore.dataToBeDeleted = [];
            dispatchScreenDataStore.selectedLocation = [];
            dataStore.setModuleName('DispatchScreen');
            dispatchScreenDataStore.agGridService.setGridApi(this.dispatchTicketGrid?.rowModel.gridApi);
            this.dispatchTicketGrid?.rowModel.gridApi.refreshCells();
        }
    };

    handleSave = () => {
        const { dispatchScreenDataStore } = this.props;
        dispatchScreenDataStore?.updateTicketStatus('Save')
    }

    handleDispatch = () => {
        const { dispatchScreenDataStore } = this.props;
        const confirmService = new ConfirmService();
        confirmService.showConfirmWithCustomComponent(
            (date: any) => {
                dispatchScreenDataStore?.updateTicketStatus('Dispatch', date)
            },
            TicketsDispatchPopUp,
            {} as ConfirmProps,
            ''
        )
    }

    handleRefresh = () => {
        if (dispatchScreenDataStore.selectedTickets.length > 0) {
            const confirmService = new ConfirmService();
            confirmService.showConfirmDialog(() => {
                dispatchScreenDataStore.plannedLoads = [];
                dispatchScreenDataStore.GetMasterData();
                dispatchScreenDataStore.selectedTickets = [];
            }, '', unsavedMsg)
        } else {
            dispatchScreenDataStore.plannedLoads = [];
            dispatchScreenDataStore.GetMasterData();
            dispatchScreenDataStore.selectedTickets = [];
        }
    };

    daysInMonth(month, year) {
        return new Date(year, month, 0).getDate();
    }

    handleBoardChange = board => {
        let selectBoard = board.currentTarget.value
        if (dispatchScreenDataStore.selectedTickets.length > 0) {
            const confirmService = new ConfirmService();
            confirmService.showConfirmDialog(() => {
                this.setState({
                    selectedBoard: selectBoard
                });
                dispatchScreenDataStore.selectedBoard = selectBoard;
                if (dispatchScreenDataStore) {
                    dispatchScreenDataStore.plannedLoads = [];
                    dispatchScreenDataStore.GetMasterData();
                    dispatchScreenDataStore.selectedTickets = [];
                }
            }, '', unsavedMsg)
        } else {
            this.setState({
                selectedBoard: selectBoard
            });
            dispatchScreenDataStore.selectedBoard = selectBoard
            if (dispatchScreenDataStore) {
                dispatchScreenDataStore.plannedLoads = [];
                dispatchScreenDataStore.GetMasterData();
            }
        }
    }

    handleTypeChange = type => {
        console.log(type, type.currentTarget.value)
        if (dispatchScreenDataStore.selectedTickets.length > 0) {
            const confirmService = new ConfirmService();
            confirmService.showConfirmDialog(() => {
                this.setState({
                    selectedType: type.currentTarget.value
                });
                dispatchScreenDataStore.selectedType = type.currentTarget.value;
            }, '', unsavedMsg)
        } else {
            this.setState({
                selectedType: type.currentTarget.value
            });
            dispatchScreenDataStore.selectedType = type.currentTarget.value
        }
    }

    handleChangeMonth = month => {
        if (dispatchScreenDataStore.selectedTickets.length > 0) {
            const confirmService = new ConfirmService();
            confirmService.showConfirmDialog(() => {
                this.setState({
                    selectedMonth: moment.months().indexOf(month),
                    datesOfMonth: Array.from(
                        Array(this.daysInMonth(moment.months().indexOf(month) + 1, this.state.selectedYear)),
                        (_, i) => i + 1
                    )
                });
                dispatchScreenDataStore.nominationMonth = moment.months().indexOf(month);
                dispatchScreenDataStore.startDate = moment().year(dispatchScreenDataStore.nominationYear).month(dispatchScreenDataStore.nominationMonth).date(
                    1).format('YYYY-MM-DD')
                dispatchScreenDataStore.endDate = moment().year(dispatchScreenDataStore.nominationYear).month(dispatchScreenDataStore.nominationMonth).date(
                    moment().month(dispatchScreenDataStore.nominationMonth).daysInMonth()).format('YYYY-MM-DD')

                if (dispatchScreenDataStore) {
                    dispatchScreenDataStore.plannedLoads = [];
                    dispatchScreenDataStore.GetMasterData();
                    dispatchScreenDataStore.selectedTickets = [];
                }
            }, '', unsavedMsg)
        } else {
            this.setState({
                selectedMonth: moment.months().indexOf(month),
                datesOfMonth: Array.from(
                    Array(this.daysInMonth(moment.months().indexOf(month) + 1, this.state.selectedYear)),
                    (_, i) => i + 1
                )
            });
            dispatchScreenDataStore.nominationMonth = moment.months().indexOf(month);
            dispatchScreenDataStore.startDate = moment().month(dispatchScreenDataStore.nominationMonth)
                .startOf('month')
                .add(1, 'days')
                .toISOString()
                .slice(0, 10);
            dispatchScreenDataStore.endDate = moment().month(dispatchScreenDataStore.nominationMonth)
                .startOf('month')
                .add(this.daysInMonth(dispatchScreenDataStore.nominationMonth + 1, dispatchScreenDataStore.nominationYear), 'days')
                .toISOString()
                .slice(0, 10);

            if (dispatchScreenDataStore) {
                dispatchScreenDataStore.plannedLoads = [];
                dispatchScreenDataStore.GetMasterData();
            }
        }

    };

    handleChangeYear = year => {
        if (dispatchScreenDataStore.selectedTickets.length > 0) {
            const confirmService = new ConfirmService();
            confirmService.showConfirmDialog(() => {
                this.setState({
                    selectedYear: year,
                    datesOfMonth: Array.from(Array(this.daysInMonth(this.state.selectedMonth + 1, year)), (_, i) => i + 1)
                });
                dispatchScreenDataStore.nominationYear = year;
                if (dispatchScreenDataStore) {
                    dispatchScreenDataStore.plannedLoads = [];
                    dispatchScreenDataStore.GetMasterData();
                    dispatchScreenDataStore.selectedTickets = [];
                }

            }, '', unsavedMsg)
        } else {
            this.setState({
                selectedYear: year,
                datesOfMonth: Array.from(Array(this.daysInMonth(this.state.selectedMonth + 1, year)), (_, i) => i + 1)
            });
            dispatchScreenDataStore.nominationYear = year;
            if (dispatchScreenDataStore) {
                dispatchScreenDataStore.plannedLoads = [];
                dispatchScreenDataStore.GetMasterData();
            }
        }
    };

    async handleNominationConfig() {
        uiService.loaderService.showLoader()
        const locationData = await locationDataService.getLocationData();
        this.setState({ locationList: locationData });
        uiService.loaderService.hideLoader()
        this.setState({ nomiConfigOpen: true });

    }

    async handleDriverConfiguration() {
        this.setState({ driverConfigOpen: true });
    }

    getGridConfig(): GridOptions {
        const numOfDays = new Date(dispatchScreenDataStore.selectedYear, dispatchScreenDataStore.selectedMonth + 1, 0).getDate();
        const rowData = toJS(dispatchScreenDataStore.dispatchBoardTickets);
        const columnDefs = getDispatchColDef();
        const onCellClicked = this.onCellclicked;
        const onFilterChanged = this.handleFilterChange;
        const DuplicateRecordsGridParams: AgGridComponentParams = {
            rowData,
            columnDefs,
            onCellClicked,
            onFilterChanged,
            autoGroupColumnDef: {
                headerName: 'Carrier Company',
                field: 'CompanyName',
                width: 85,
                cellRendererParams: {
                    filter: true
                },
                rememberGroupStateWhenNewData: true
            }
        };
        return dispatchScreenDataStore.agGridService.getGridConfig(DuplicateRecordsGridParams);
    }

    onCellclicked = async event => {
        console.log(event,event.column.colId);
        const startdate = moment(dispatchScreenDataStore.startDate).date() - 1;
        console.log(event,event.column.colId + 1,parseInt(event.column.colId)+1 , startdate, moment(dispatchScreenDataStore.startDate).date(), moment(dispatchScreenDataStore.endDate).date());
        if(parseInt(event.column.colId) + 1 >= moment(dispatchScreenDataStore.startDate).date() && parseInt(event.column.colId) + 1 <= moment(dispatchScreenDataStore.endDate).date())
        {
            if (event.data && event.column.colId !== 'Driver' && event.column.colId !== "ag-Grid-AutoColumn" && ((event.data.DataResponse[event.column.colId].BoardMapped === dispatchScreenDataStore.selectedBoard && event.data.DataResponse[event.column.colId].IsAvailability !== 0) || event.data.DriverID === null))
            {
                console.log(event.data.DataResponse[event.column.colId - startdate].Date, event.data, event.data.DataResponse[event.column.colId - startdate].LoadNumber, event.column.colId - startdate);
                dispatchScreenDataStore.getPendingTickets(event.data.DataResponse[event.column.colId - startdate].Date, event.data, event.data.DataResponse[event.column.colId - startdate].LoadNumber, event.column.colId - startdate);
            }
        }    
    }

    handleFilterChange = event => {
        if (!this.state.nomiConfigOpen && !this.state.driverConfigOpen) {
            console.log(dispatchScreenDataStore.agGridService.getColumns())
            console.log(dataStore.getCurrentModuleName(), dispatchScreenDataStore.agGridService.getAllFilters());
            localStorageService.set(
                'DispatchScreen_filter',
                dispatchScreenDataStore.agGridService.getAllFilters())
        }
    }

    getGridNominationConfig(): GridOptions {
        const rowData = dispatchScreenDataStore.dispatchBoardData;
        const columnDefs = nominationBoardColDef
        const DuplicateRecordsGridParams: AgGridComponentParams = {
            rowData,
            columnDefs,
        };
        return dispatchScreenDataStore.agGridService.getGridConfig(DuplicateRecordsGridParams);
    }

    getGridDriverConfig(): GridOptions {
        const rowData = dispatchScreenDataStore.driverBoardData;
        const columnDefs = getDriverColDef();
        const DuplicateRecordsGridParams: AgGridComponentParams = {
            rowData,
            columnDefs,
        };
        return dispatchScreenDataStore.agGridService.getGridConfig(DuplicateRecordsGridParams);
    }

    render(): React.ReactNode {
        const monthNames = moment.months();
        const { dispatchScreenDataStore } = this.props;
        const {
            selectedMonth,
            selectedYear,
            locationList,
            selectedType
        } = this.state;
        let locationData: any = [];
        const configMonth = dispatchScreenDataStore.raConfig.filter((item: any) => item.Code === 'RAIntegrationMonth');
        const configYear = dispatchScreenDataStore.raConfig.filter((item: any) => item.Code === 'RAIntegrationYear');
        if((this.props.dispatchScreenDataStore.nominationMonth + 1 >= parseInt(configMonth[0]?.Value) && this.props.dispatchScreenDataStore.nominationYear === parseInt(configYear[0]?.Value)) || 
        this.props.dispatchScreenDataStore.nominationYear > parseInt(configYear[0]?.Value))
        {
            locationList.map(location => {
                if(location.RAConsignor != null && location.RAConsignee != null )
                    locationData.push({ Consignor: location.RAConsignor, Consignee: location.RAConsignee, value: `${location.RAConsignor} - ${location.RAConsignee}`, label: `${location.RAConsignor} - ${location.RAConsignee}` });
            });
        }else {
            locationList.map(location => {
                if(location.Consignor != null && location.Consignee != null )
                    locationData.push({ Consignor: location.Consignor, Consignee: location.Consignee, value: `${location.Consignor} - ${location.Consignee}`, label: `${location.Consignor} - ${location.Consignee}` });
            });
        }
        

        const getYear = new Date().getFullYear();
        const years = Array.from(new Array(20), (val, index) => index + getYear);
        console.log('rendered')
        this.dispatchTicketGrid = dispatchScreenDataStore.agGridService.getNodes()
        return <div>
            <div className="form-inline padding-bottom">
                <div className="react-select-container">
                    Month :{' '}
                    <select
                        name="month"
                        value={monthNames[selectedMonth]}
                        onChange={e => this.handleChangeMonth(e.currentTarget.value)}
                        className="react-select-style"
                    >
                        {monthNames.map((month, i) => (
                            <option key={month} value={month}>
                                {month}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="react-select-container">
                    Year :{' '}
                    <select
                        name="year"
                        value={selectedYear}
                        className="react-select-style"
                        onChange={e => this.handleChangeYear(parseInt(e.currentTarget.value, 10))}
                    >
                        {years.map(yearData => (
                            <option key={yearData} value={yearData}>
                                {yearData}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="react-select-container">
                    Board :{' '}
                    <select
                        name="region"
                        value={dispatchScreenDataStore.selectedBoard}
                        className="react-select-style"
                        onChange={this.handleBoardChange}
                    >
                        {boards.map(region => (
                            <option key={region.ID} value={region.Name}>
                                {region.Name}
                            </option>
                        ))}
                    </select>
                </div>

            </div>
            <div className="form-inline padding-bottom">
                <ReactDayPickerInput _dataStore={this.props.dispatchScreenDataStore} isDisabled={false}></ReactDayPickerInput>
                <div className="react-select-container">
                    Select Ticket Type :{' '}
                    <select
                        name="region"
                        value={selectedType}
                        className="react-select-style"
                        onChange={this.handleTypeChange}
                    >
                        {ticketType.map(region => (
                            <option key={region.ID} value={region.Value}>
                                {region.label}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <Row>
                <div className="form-inline padding-bottom" style={{ width: '70%' }}>
                    <CustomButton
                        type={CustomButtonType.Submit}
                        onClick={() =>
                            this.handleNominationConfig()
                        }
                    >
                        {' '}
                        Nomination Configuration{' '}
                    </CustomButton>
                    <CustomButton type={CustomButtonType.Submit} onClick={() =>
                        this.handleDriverConfiguration()
                    }>
                        Driver Configuration
                    </CustomButton>

                    <CustomButton
                        type={CustomButtonType.Submit}
                        onClick={() =>
                            this.handleDispatch()
                        }
                        disabled={dispatchScreenDataStore.selectedTickets.length === 0 && dispatchScreenDataStore.plannedLoads.length === 0}
                    >
                        {' '}
                        Dispatch{' '}
                    </CustomButton>
                    {/* <CustomButton type={CustomButtonType.Submit} disabled={dispatchScreenDataStore.selectedTickets.length === 0} onClick={this.handleSave}>
                        Save
                    </CustomButton> */}
                    <ClearButtonComponent handleRefresh={this.handleRefresh} isExportCSV={true}></ClearButtonComponent>
                </div>
                <div className="padding-bottom" style={{ width: '15%' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ width: '15px', height: '15px', backgroundColor: '#F5F5F5', border: '1px solid grey', marginBottom: '0.5rem' }}> </span>
                        <label style={{ paddingLeft: '5px' }}> Available on Current Board </label>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ width: '15px', height: '15px', backgroundColor: 'orange', border: '1px solid grey', marginBottom: '0.5rem' }}> </span>
                        <label style={{ paddingLeft: '5px' }}> Driver Off Duty on Current Board </label>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ width: '15px', height: '15px', backgroundColor: '#cac5c5', border: '1px solid grey', marginBottom: '0.5rem' }}> </span>
                        <label style={{ paddingLeft: '5px' }}> Disabled on Current Board</label>
                    </div>
                </div>
                <div className="padding-bottom" style={{ width: '15%' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ width: '15px', height: '15px', backgroundColor: 'purple', border: '1px solid grey', marginBottom: '0.5rem' }}> </span>
                        <label style={{ paddingLeft: '5px' }}> Driver/Carrier Assigned not Dispatched </label>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ width: '15px', height: '15px', backgroundColor: 'green', border: '1px solid grey', marginBottom: '0.5rem' }}> </span>
                        <label style={{ paddingLeft: '5px' }}> Dispatched Tickets </label>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ width: '15px', height: '15px', backgroundColor: 'black', border: '1px solid grey', marginBottom: '0.5rem' }}> </span>
                        <label style={{ paddingLeft: '5px' }}> Unsaved/Not  Dispatched Tickets</label>
                    </div>
                </div>
            </Row>
            {   this.state.nomiConfigOpen &&
                    <NominationConfigPopUpComponent
                        dispatchScreenDataStore={dispatchScreenDataStore}
                        month={this.state.selectedMonth}
                        year={this.state.selectedYear}
                        locationData={locationData}
                        handleClose={this.handleClose}
                    />
            }

            {   this.state.driverConfigOpen &&
                    <DriverConfigPopupComponent
                        dispatchScreenDataStore={dispatchScreenDataStore}
                        month={this.state.selectedMonth}
                        year={this.state.selectedYear}
                        handleClose={this.handleClose}
                    />
            }
            <Row>
                <Col>
                    <AgGridComponent gridConfig={this.getGridConfig()} />
                </Col>
            </Row>
        </div>


    }
}
