import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { observer, inject } from 'mobx-react';
import { DataStore } from '../../common/stores/data-store';
import { ReactSelect } from 'shared/components/select/select';
import './_user-approval.scss';
import { DefaultPageComponent } from '../../common/components/default-page-component';
import { FilterKeyForReferenceData } from '../domains/enum';
import { dataTypeOptions } from '../user_approval_constant';
import { UserApprovalComponent } from './user-signup-component';
import { userApprovalStore } from '../stores';
import history from '../../../shared/services/history';
import { PathDataTypeMap } from 'shared/static/data';

interface UserApprovalComponentProps {
    dataStore: DataStore;
}

@inject('dataStore')
@observer
export class UserApprovalMainComponent extends Component<UserApprovalComponentProps> {
    constructor(props) {
        super(props);
        this.props.dataStore.init(dataTypeOptions);
        this.props.dataStore.setModuleName('UserApproval');
    }

    componentWillUnmount() {
        this.props.dataStore.reset();
    }

    render(): React.ReactNode {
        return (
            <Container fluid={true}>
                <Row className="mt-3">
                    <Col>{this.renderSelectedComponent()}</Col>
                </Row>
                <Row className="mt-3">
                    <Col>{this.renderGrid()}</Col>
                </Row>
            </Container>
        );
    }

    renderSelectedComponent(): React.ReactNode {
        const { selectedDataType, dataTypes } = this.props.dataStore;
        let selectedValue: any = {};
        const selectedPathMapping = PathDataTypeMap.filter(
            x => x.path.toLowerCase() === history.location.pathname.toLowerCase()
        );
        if (selectedPathMapping[0]) {
            selectedValue = dataTypeOptions.filter(x => x.value === selectedPathMapping[0].dataTypeValue)[0];
        } else {
            selectedValue = selectedDataType;
        }
        return (
            <form className="form-inline">
                <label className="maintenance-data__label">Select Type</label>
                <div className="form-group maintenance-data__type">
                    <ReactSelect
                        values={selectedValue}
                        placeholder="SelectType"
                        options={dataTypes}
                        onChange={this.handleDataTypeChange}
                        isMulti={false}
                    />
                </div>
            </form>
        );
    }

    renderGrid(): React.ReactNode {
        const { selectedDataType } = this.props.dataStore;
        let selectedValue = '';
        const selectedPathMapping = PathDataTypeMap.filter(
            x => x.path.toLowerCase() === history.location.pathname.toLowerCase()
        );
        if (selectedPathMapping[0]) {
            selectedValue = selectedPathMapping[0].dataTypeValue;
        } else {
            selectedValue = selectedDataType.value;
        }
        if (selectedValue == FilterKeyForReferenceData.Select) {
            return (
                <div>
                    <DefaultPageComponent />
                </div>
            );
        } else if (selectedValue == FilterKeyForReferenceData.UserApproval) {
            return (
                <div>
                    <UserApprovalComponent userApprovalStore={userApprovalStore} />
                </div>
            );
        }
    }

    handleDataTypeChange = option => {
        const selectedPath = PathDataTypeMap.filter(x => x.dataTypeValue.toLowerCase() === option.value.toLowerCase());
        if (selectedPath[0]) {
            history.push(selectedPath[0].path);
        } else {
            history.push('/userApproval');
        }
        this.props.dataStore.setSelectedDataType(option);
    };
}
