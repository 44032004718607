import { action, observable, toJS } from 'mobx';
import Catch from '../../../../shared/decorators/catch-decorator';
import Loader from '../../../../shared/decorators/loader-decorator';
import moment from 'moment';
import { AccountStore } from '../../../account/stores/account-store';
import { dateUtils } from 'shared/services/date-utils';
import { AgGridService } from 'features/common/services/ag-grid-service';
import { DispatchScreenDataService } from '../services/dispatch-screen-data-services';
import { uiService } from 'shared/services/ui-service';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import { errorHandler } from 'shared/handlers/error-handler';
export class DispatchScreenDataStore {
    userID = 0;
    userName = '';

    @observable selectedMonth: any = '';
    @observable selectedYear: any = '';
    @observable dispatchBoardData = [] as any;
    @observable driverBoardData: any = [];
    backupDriverBoardData: any = [];
    @observable nominationMonth = new Date().getMonth();
    @observable nominationYear = new Date().getFullYear();
    @observable selectedBoard = 'North';
    @observable selectedType = 'All';
    @observable dispatchBoardTickets = [] as any;
    @observable pendingTickets = [] as any;
    @observable startDate: any = moment().year(this.nominationYear).month(this.nominationMonth).date(1).format('YYYY-MM-DD')
    @observable endDate: any = moment().year(this.nominationYear).month(this.nominationMonth).date(moment().month(this.nominationMonth).daysInMonth()).format('YYYY-MM-DD')
    @observable selectedLocation: any = [];
    @observable dataToBeDeleted: any = [];
    dataToBeDeletedID: any = [];
    @observable selectedTickets: any = [];
    @observable selectedRow: any = [{ fromDate: '', toDate: '', StartDate: '', EndDate: '', Board: 'North', isEditDisabled: false, isDeleted: false }]
    @observable additionalRow: any = [{ fromDate: '', toDate: '', StartDate: '', EndDate: '', Board: 'North', isEditDisabled: false, isDeleted: false }]
    stateChanged: any = false;
    @observable plannedLoads: any = [];
    raConfig: any = [];
    constructor(private dispatchBoardDataService: DispatchScreenDataService, private accountStore: AccountStore, public agGridService: AgGridService,) {
        this.userID = this.accountStore.getUserID();
        this.userName = this.accountStore.displayName;
    }

    @Loader
    @Catch(() => errorHandler('DispatchBoard Tickets could not be loaded'))
    @action
    async GetMasterData(): Promise<void> {    
        let reqBody = {
            MonthName: moment.months()[this.nominationMonth],
            Year: this.nominationYear,
            StartDate: this.startDate,
            EndDate: this.endDate,
            Board: this.selectedBoard,
            Month: this.nominationMonth + 1
        }
        uiService.loaderService.showLoader();
        const data: any = await this.dispatchBoardDataService.getDriverCCTicketDetails(reqBody);
        this.raConfig = await this.dispatchBoardDataService.getRAConfig();
        this.dispatchBoardTickets = data.data["Data"];
        uiService.loaderService.hideLoader();
        this.getNonDispatchedTickets(this.dispatchBoardTickets);
    }

    getNonDispatchedTickets(dispatchTicketsData) { 
        dispatchTicketsData.forEach(data => {
            data.DataResponse.forEach(date => {
                let loads: any[] = date.LoadNumber ? [... new Set(date.LoadNumber.split(','))] : [];
                let status: string[] = date.Status ? date.Status.split(',') : [];
                loads.forEach((load,ind) => {
                    let outsideBracketMatch = load.match(/^[^\(]+/);
                let outsideBracketValue = outsideBracketMatch ? outsideBracketMatch[0] : null;
                let insideBracketMatch = load.match(/\(([^)]+)\)/);
                let insideBracketValue = insideBracketMatch ? insideBracketMatch[1] : null;
                const str = insideBracketValue === null ? `${outsideBracketValue}` : `${insideBracketValue}`
                    if (status[ind] === 'Pending' || status[ind] === 'Recalled' || status[ind] === 'Cancelled By Driver' || status[ind] === 'Rejected By Driver' ||
                    status[ind] === 'Rejected By Carrier' || status[ind] === 'Cancelled By Carrier' || status[ind] === 'Recalled By Carrier') {
                        this.plannedLoads.push({
                            Date: date.Date,
                            LoadNumber: str,
                            DriverId: data.DriverID,
                            CarrierID: data.DriverID != null ? null : data.CarrierCompanyID,
                            DispatchType: data.DriverID !== null ? 'Driver' : 'Carrier'
                        })
                    }
                })
            })
        })
        console.log(toJS(dispatchTicketsData) );
    }

    @Loader
    @Catch(() => errorHandler('Nomination Config Data could not be loaded'))
    @action
    async getDispatchBoardData(): Promise<void> {
        const reqBody = {
            Month: this.nominationMonth + 1,
            Year: this.nominationYear,
            Board: this.selectedBoard
        }

        const result: any = await this.dispatchBoardDataService.getDispatchBoardMapping(reqBody)
        this.dispatchBoardData = result.data['Data'];
    }

    @Loader
    @Catch(() => errorHandler('Driver Config Data could not be loaded'))
    @action
    async getDriverBoardData(): Promise<void> {
        const reqBody = {
            StartDate: moment().year(this.nominationYear).month(this.nominationMonth).date(1).format('YYYY-MM-DD'),
            EndDate: moment().year(this.nominationYear).month(this.nominationMonth).date(moment().month(this.nominationMonth).daysInMonth()).format('YYYY-MM-DD'),
            Month: moment.months()[this.nominationMonth],
            Year: this.nominationYear,
        }

        const data: any = await this.dispatchBoardDataService.getDriverBoardMapping(reqBody)
        this.driverBoardData = data.data["Data"];
        this.driverBoardData.map(item => {
            if (item.DriverBoardGroup.length > 0) {
                item.DriverBoardGroup.map(list => {
                    list.isEditDisabled = true;
                    list.isDeleted = false;
                    list.DriverID = item.DriverID;
                    list.DriverBoardID = list.MappingID
                })
            }
        })
        this.setDriverDataBackup(this.driverBoardData)
    }

    setDriverDataBackup(driverData){
        this.backupDriverBoardData = this.driverBoardData;
    }
    @Loader
    @action
    async getPendingTickets(selectedDate, eventData, loadNumber, colId): Promise<void> {
        const reqBody = {
            Month: this.nominationMonth + 1,
            Year: this.nominationYear,
            PlannedDateTime: selectedDate,
            Board: this.selectedBoard,
            DriverID: eventData.DriverID,
            CarrierID: eventData.CarrierCompanyID,
            Type: this.selectedType
        }
        const confirmService = new ConfirmService();
        const result: any = await this.dispatchBoardDataService.getPendingTicketDetails(reqBody)
        const assignedtickets: any = await this.dispatchBoardDataService.getAssignedTicketDetails(reqBody);
        this.driverBoardData = result.data['Data'];
        const plannedloads = assignedtickets.data['Data'];
        plannedloads.map(loads => {
            loads.IsDeleted = false;
        })
        // let tickets: any = [];
        // let loadTickets = loadNumber ? loadNumber.split(',') : [];
        // let status = eventData.DataResponse[colId].Status ? eventData.DataResponse[colId].Status.split(',') : []
        // this.selectedTickets.map(list => {
        //     if (eventData.DriverID) {
        //         if (dateUtils.updateFormatDate(list.PlannedDateTime) === dateUtils.updateFormatDate(selectedDate) && (list.DriverId === eventData.DriverID)) {
        //             tickets.push({
        //                 value: list.LoadNumber, label: `${list.LoadNumber} | ${list.ShortNames}`, origin: list.AX_Origin, destination: list.AX_Destination, date: dateUtils.getFormattedMSTDateOnly(list.PlannedDateTime)
        //                 , status: list.Status, shortName: list.ShortNames
        //             })
        //         }
        //     } else {
        //         if (dateUtils.updateFormatDate(list.PlannedDateTime) === dateUtils.updateFormatDate(selectedDate) && (list.CarrierID === eventData.CarrierCompanyID)) {
        //             tickets.push({
        //                 value: list.LoadNumber, label: `${list.LoadNumber} | ${list.ShortNames} `, origin: list.AX_Origin, destination: list.AX_Destination, date: dateUtils.getFormattedMSTDateOnly(list.PlannedDateTime)
        //                 , status: list.Status, shortName: list.ShortNames
        //             })
        //         }
        //     }
        // });
        // loadTickets.map((list, ind) => {
        //     if (status[ind] === 'Pending' || status[ind] === 'Recalled' || status[ind] === 'Cancelled By Driver' || status[ind] === 'Rejected By Driver' ||
        //         status[ind] === 'Rejected By Carrier' || status[ind] === 'Cancelled By Carrier' || status[ind] === 'Recalled By Carrier') {
                
        //         let outsideBracketMatch = list.match(/^[^\(]+/);
        //         let outsideBracketValue = outsideBracketMatch ? outsideBracketMatch[0] : null;
        //         let insideBracketMatch = list.match(/\(([^)]+)\)/);
        //         let insideBracketValue = insideBracketMatch ? insideBracketMatch[1] : null;
        //         const str = insideBracketValue === null ? `${outsideBracketValue}` : `${insideBracketValue}`
        //         let array = this.driverBoardData.filter(i => str === i.LoadNumber)
        //         tickets.push({
        //             value: array[0].LoadNumber, label: `${array[0].LoadNumber} | ${array[0].ShortNames} `, origin: array[0].AX_Origin, destination: array[0].AX_Destination, date: dateUtils.getFormattedMSTDateOnly(selectedDate)
        //             , status: status[ind], shortName: array[0].ShortNames
        //         })
        //     }
        // })

        // let pendingTickets: any = []
        // if (this.selectedTickets.length > 0) {
        //     const ticketsArray = Array.from(new Set(this.selectedTickets.map((item: any) => {
        //         if (dateUtils.updateFormatDate(item.PlannedDateTime) !== dateUtils.updateFormatDate(selectedDate) ||
        //             (item.CarrierID !== eventData.CarrierCompanyID) ||
        //             (item.DriverId !== eventData.DriverID)) {
        //             return item.LoadNumber
        //         }
        //     })));
        //     const array = this.driverBoardData.filter(i => !ticketsArray.includes(i.LoadNumber))
        //     pendingTickets = array;

        // } else {
        //     pendingTickets = this.driverBoardData
        // }

        if (this.driverBoardData.length > 0 || plannedloads.length > 0) {
        confirmService.showPlannedLoadsPopup(async (loads) => {
            let plannedLoads: any = []
            loads.map(load => {
                if(load.LoadNumber && load.LoadNumber != '' && load.IsEdit){
                    plannedLoads.push({
                        DriverID: load.IsDeleted ? null : eventData.DriverID !== null ? eventData.DriverID : null ,
                        ModifiedBy: this.userID,
                        ButtonName: load.IsRecall ? 'Recall' : 'SaveData',
                        LoadNumber: load.LoadNumber,
                        DispatcherComments: load.DispatcherComments,
                        DispatcherNotes: "",
                        PlannedDateTime: moment.utc(moment(selectedDate).add(23, 'hours').add(59, 'minutes')).format('YYYY-MM-DDTHH:mm:ss'),
                        IsAdhoc: false,
                        DispatchType: eventData.DriverID !== null ? 'Driver' : 'Carrier',
                        CarrierID: load.IsDeleted ? null : eventData.DriverID !== null ? null : eventData.CarrierCompanyID ,
                        Priority: 3
                    })
                }
            })

            let reqParams = {
                                ModifiedBy: this.userID,
                                IsAdhoc: false,
                                dispatchPlanRequest: plannedLoads
                            }
                            const result: any = await this.dispatchBoardDataService.updateDispatchPlanStatus(reqParams);
                            if (result.StatusCode === 200) {
                                uiService.loaderService.showLoader();
                                this.plannedLoads = [];
                                await this.GetMasterData();
                                this.selectedLocation = [];
                                this.selectedTickets = [];
                                uiService.loaderService.hideLoader();
                                uiService.toastService.success('Records Saved Successfully');
                            }

        }, toJS(plannedloads), this.driverBoardData);
        } else {
            uiService.toastService.error('No Pending Tickets available for dispatch');
        }
        // if (this.driverBoardData.length > 0 || tickets.length > 0) {
        //     confirmService.showLocationAXPopup(async (loads) => {
        //         let loadsArray = Array.from(new Set(loads.map((item: any) => {
        //             { return item.value }
        //         })));
        //         let loadstatus: string[] = []
        //         loads.map(x => {
        //             loadstatus.push(x.status);
        //         })
        //         let plannedLoads: any = []
        //         loadTickets.map((loadnum, ind) => {
        //             let outsideBracketMatch = loadnum.match(/^[^\(]+/);
        //         let outsideBracketValue = outsideBracketMatch ? outsideBracketMatch[0] : null;
        //         let insideBracketMatch = loadnum.match(/\(([^)]+)\)/);
        //         let insideBracketValue = insideBracketMatch ? insideBracketMatch[1] : null;
        //         const str = insideBracketValue === null ? `${outsideBracketValue}` : `${insideBracketValue}`
        //         let array = this.driverBoardData.filter(i => str === i.LoadNumber)
        //             if (!loadsArray.includes(array[0].LoadNumber)) {
        //                 if (status[ind] === 'Pending' || status[ind] === 'Recalled' || status[ind] === 'Cancelled By Driver' || status[ind] === 'Rejected By Driver' ||
        //                     status[ind] === 'Rejected By Carrier' || status[ind] === 'Cancelled By Carrier' || status[ind] === 'Recalled By Carrier') {
        //                     plannedLoads.push({
        //                         DriverID: null,
        //                         ModifiedBy: this.userID,
        //                         ButtonName: 'SaveData',
        //                         LoadNumber: array[0].LoadNumber,
        //                         DispatcherComments: "",
        //                         DispatcherNotes: "",
        //                         PlannedDateTime: moment.utc(moment(selectedDate).add(23, 'hours').add(59, 'minutes')).format('YYYY-MM-DDTHH:mm:ss'),
        //                         IsAdhoc: false,
        //                         DispatchType: eventData.DriverID !== null ? 'Driver' : 'Carrier',
        //                         CarrierID: null,
        //                         Priority: 3
        //                     })
        //                 }
        //             }
        //         })
        //         tickets.map(x => {
        //             if (!loadsArray.includes(x.value)) {
        //                 this.selectedTickets.filter((item, i) => {
        //                     if (item.LoadNumber === x.value) {
        //                         this.selectedTickets.splice(i, 1)
        //                     }
        //                 });
        //                 loadTickets.filter((item, i) => {
        //                     if (item === x.value) {
        //                         loadTickets.splice(i, 1);
        //                         status.splice(i, 1);
        //                     }
        //                 })
        //             } else {
        //                 loadTickets.filter((item, i) => {
        //                     if (item === x.value) {
        //                         loadTickets.splice(i, 1);
        //                         status.splice(i, 1);
        //                     }
        //                 })

        //             }
        //         })

        //         let ticketNumber: any = '';
        //         let loadStatus: any = '';
        //         if (loadsArray.length > 0) {
        //             if (loadNumber === null) {
        //                 ticketNumber = loadsArray.join(',')
        //                 loadStatus = loadstatus.join(',')
        //             } else {
        //                 if (loadTickets.length > 0) {
        //                     ticketNumber = loadTickets.join(',') + ',' + loadsArray.join(',');
        //                     loadStatus = status.join(',') + ',' + loadstatus.join(',')
        //                 }
        //                 else {
        //                     ticketNumber = loadsArray.join(',');
        //                     loadStatus = loadstatus.join(',')
        //                 }
        //             }
        //         } else {
        //             ticketNumber = loadTickets.join(',')
        //             loadStatus = status.join(',')
        //         }
        //         if (eventData.DriverID) {
        //             this.dispatchBoardTickets.forEach(ind => {
        //                 if (ind.DriverID && ind.DriverID === eventData.DriverID) {
        //                     ind.DataResponse.forEach((date, i) => {
        //                         if (date.Date === selectedDate) {
        //                             date.LoadNumber = ticketNumber;
        //                             date.Status = loadStatus;
        //                         }
        //                     })
        //                 }
        //             })
        //         } else {
        //             this.dispatchBoardTickets.forEach(ind => {
        //                 if (!ind.Driver && ind.CarrierCompanyID === eventData.CarrierCompanyID) {
        //                     ind.DataResponse.forEach((date, i) => {
        //                         if (date.Date === selectedDate) {
        //                             date.LoadNumber = ticketNumber;
        //                             date.Status = loadStatus;
        //                         }
        //                     })
        //                 }
        //             })
        //         }
        //         var selectTickets = Array.from(new Set(this.selectedTickets.map((item: any) => item.LoadNumber)));
        //         loads.forEach(ind => {
        //             if (!selectTickets.includes(ind.value) && ind.status === 'Added') {
        //                 this.selectedTickets.push({
        //                     DriverId: eventData.DriverID,
        //                     LoadNumber: ind.value,
        //                     PlannedDateTime: selectedDate,
        //                     DispatchType: eventData.DriverID !== null ? 'Driver' : 'Carrier',
        //                     CarrierID: eventData.DriverID !== null ? null : eventData.CarrierCompanyID,
        //                     AX_Origin: ind.origin,
        //                     AX_Destination: ind.destination,
        //                     Status: 'Added'
        //                 })
        //             }
        //         })
        //         if (plannedLoads.length > 0) {
        //             let reqParams = {
        //                 ModifiedBy: this.userID,
        //                 IsAdhoc: false,
        //                 dispatchPlanRequest: plannedLoads
        //             }
        //             const result: any = this.dispatchBoardDataService.updateDispatchPlanStatus(reqParams);
        //         }
        //     }, toJS(pendingTickets), true, tickets);
        // } else {
        //     uiService.toastService.error('No Pending Tickets available for dispatch');
        // }
    }

    @Loader
    @Catch(() => errorHandler('Data could not be dispatched/saved. Please try again later'))
    @action
    async updateTicketStatus(type, date?) {
        let reqBody: any = [];
        this.userID = this.accountStore.getUserID();
        if(type === 'Dispatch' && this.selectedTickets.length > 0) {
            this.selectedTickets.forEach(async ind => {
                if(dateUtils.updateFormatDate(ind.PlannedDateTime) >= dateUtils.updateFormatDate(date.startDate) && dateUtils.updateFormatDate(ind.PlannedDateTime) <= dateUtils.updateFormatDate(date.endDate)){
                    reqBody.push({
                        DriverID: ind.DriverId,
                        ModifiedBy: this.userID,
                        ButtonName: type === 'Dispatch' ? ind.DriverId === null ? 'CarrierDispatchLoad' : 'DispatchLoad' : 'SaveData',
                        LoadNumber: ind.LoadNumber,
                        // DispatcherComments: "",
                        DispatcherNotes: "",
                        PlannedDateTime: moment.utc(moment(ind.PlannedDateTime).add(23, 'hours').add(59, 'minutes')).format('YYYY-MM-DDTHH:mm:ss'),
                        IsAdhoc: false,
                        DispatchType: ind.DispatchType,
                        CarrierID: ind.CarrierID,
                        Priority: 3
                    })
                }
            })
        } else if (this.selectedTickets.length > 0) {
            this.selectedTickets.forEach(async ind => {
                reqBody.push({
                    DriverID: ind.DriverId,
                    ModifiedBy: this.userID,
                    ButtonName: 'SaveData',
                    LoadNumber: ind.LoadNumber,
                    // DispatcherComments: "",
                    DispatcherNotes: "",
                    PlannedDateTime: moment.utc(moment(ind.PlannedDateTime).add(23, 'hours').add(59, 'minutes')).format('YYYY-MM-DDTHH:mm:ss'),
                    IsAdhoc: false,
                    DispatchType: ind.DispatchType,
                    CarrierID: ind.CarrierID,
                    Priority: 3
                })
            })
        }
        if(type === 'Dispatch' && this.plannedLoads.length > 0) {
            this.plannedLoads.forEach(ind => {
                if(dateUtils.updateFormatDate(ind.Date) >= dateUtils.updateFormatDate(date.startDate) && dateUtils.updateFormatDate(ind.Date) <= dateUtils.updateFormatDate(date.endDate)){
                    reqBody.push({
                        DriverID: ind.DriverId,
                        ModifiedBy: this.userID,
                        ButtonName: ind.DriverId === null ? 'CarrierDispatchLoad' : 'DispatchLoad',
                        LoadNumber: ind.LoadNumber,
                        // DispatcherComments: "",
                        DispatcherNotes: "",
                        PlannedDateTime: moment.utc(moment(ind.Date).add(23, 'hours').add(59, 'minutes')).format('YYYY-MM-DDTHH:mm:ss'),
                        IsAdhoc: false,
                        DispatchType: ind.DispatchType,
                        CarrierID: ind.CarrierID,
                        Priority: 3
                    })
                }
            })
        }
        

        let reqParams = {
            ModifiedBy: this.userID,
            IsAdhoc: false,
            dispatchPlanRequest: reqBody
        }
        if(reqBody.length> 0) {
            const result: any = await this.dispatchBoardDataService.updateDispatchPlanStatus(reqParams);
            if (result.StatusCode === 200) {
                uiService.loaderService.showLoader();
                this.plannedLoads = [];
                await this.GetMasterData();
                this.selectedLocation = [];
                this.selectedTickets = [];
                uiService.loaderService.hideLoader();
                uiService.toastService.success('Records Saved Successfully');
            }
        }
    }

    @Loader
    @action
    async getRangeResult(startDate, endDate): Promise<void> {
        this.startDate = startDate;
        this.endDate = endDate;
        this.plannedLoads = [];
        await this.GetMasterData();
    }

    @Loader
    @action
    async updateDispatchBoard(reqBody): Promise<void> {
        const result: any = await this.dispatchBoardDataService.updateDispatchBoardMapping(reqBody);
        console.log(result, result.data, result.data.Message);
        if (result.data.StatusCode === 200) {
            uiService.loaderService.showLoader();
            await this.getDispatchBoardData();
            this.selectedLocation = [];
            uiService.loaderService.hideLoader();
            uiService.toastService.success('Records Saved Successfully');
        } else if (result.data.StatusCode !== 200) {
            uiService.toastService.error(result.data.Message);
        }
    }

    @Loader
    @action
    async updateDriverBoard(reqBody): Promise<void> {
        const result: any = await this.dispatchBoardDataService.updateDriverBoardMapping(reqBody);
        if (result.data.StatusCode === 200) {
            uiService.loaderService.showLoader();
            await this.getDriverBoardData();
            this.selectedLocation = [];
            this.dataToBeDeleted = [];
            this.dataToBeDeletedID = [];
            this.selectedRow = [{ fromDate: '', toDate: '', StartDate: '', EndDate: '', Board: 'North', isEditDisabled: false, isDeleted: false }];
            this.additionalRow = [{ fromDate: '', toDate: '', StartDate: '', EndDate: '', Board: 'North', isEditDisabled: false, isDeleted: false }];
            uiService.loaderService.hideLoader();
            uiService.toastService.success('Records Saved Successfully');
        } else if (result.data.StatusCode !== 200) {
            uiService.toastService.error(result.data.Message);
        }
    }

    handleCheckboxClick(location: any, value: any, colName: string): void {
        switch (colName) {
            case 'IsDeleted':
                location.IsDeleted = !value;
                break;
            default:
                break;
        }
        if (location.IsDeleted) {
            this.dataToBeDeleted.push(location);
            this.dataToBeDeletedID.push(location.ID);
        } else {
            this.dataToBeDeleted = this.dataToBeDeleted.filter(a => a.ID !== location.ID);
            this.dataToBeDeletedID = this.dataToBeDeletedID.filter(a => a !== location.ID);
        }
    }

    handleDriverCheckboxClick(location: any, value: any, colName: string): void {
        switch (colName) {
            case 'IsSelected':
                location.IsSelected = !value;
                break;
            default:
                break;
        }
        if (location.IsSelected) {
            this.dataToBeDeleted.push(location);
            this.dataToBeDeletedID.push(location.DriverID);
            if (this.dataToBeDeletedID.length === 1) {
                let result = this.driverBoardData.filter(x => x.DriverID === this.dataToBeDeletedID[0])
                this.selectedRow = result[0].DriverBoardGroup.length > 0 ? result[0].DriverBoardGroup : [{ fromDate: '', toDate: '', StartDate: '', EndDate: '', Board: 'North', isEditDisabled: false, isDeleted: false }]
            } else if (this.dataToBeDeletedID.length > 1) {
                this.selectedRow = []
                this.driverBoardData.map(x => {
                    if (this.dataToBeDeletedID.includes(x.DriverID)) {
                        this.selectedRow = [...this.selectedRow, ...x.DriverBoardGroup]
                    }
                })
            }
        } else {
            this.dataToBeDeleted = this.dataToBeDeleted.filter(a => a.DriverID !== location.DriverID);
            this.dataToBeDeletedID = this.dataToBeDeletedID.filter(a => a !== location.DriverID);
            if (this.dataToBeDeletedID.length === 1) {
                let result = this.driverBoardData.filter(x => x.DriverID === this.dataToBeDeletedID[0])
                this.selectedRow = result[0].DriverBoardGroup.length > 0 ? result[0].DriverBoardGroup : [{ fromDate: '', toDate: '', StartDate: '', EndDate: '', Board: 'North', isEditDisabled: false, isDeleted: false }]
            } else {
                this.selectedRow = [{ fromDate: '', toDate: '', StartDate: '', EndDate: '', Board: 'North', isEditDisabled: false, isDeleted: false }]
            }
        }
    }

    @Loader
    @action
    async deleteRecords() {
        let userID = this.accountStore.getUserID();
        if (!userID || userID === 0) {
            await this.accountStore.getLoggedInUserDetailsIfUserIdZero(this.accountStore.userName).then(() => {
                userID = this.accountStore.getUserID();
            });
            if (!userID || userID === 0) {
                return;
            }
        }

        this.dataToBeDeleted.forEach(ind => {
            ind.CreatedBy = this.userID;
            ind.BoardMapped = this.selectedBoard;
        })

        const response: any = await this.dispatchBoardDataService.updateDispatchBoardMapping(this.dataToBeDeleted);
        if (response.data.StatusCode === 200) {
            uiService.toastService.success('Records Updated Successfully');
            this.getDispatchBoardData();
            this.dataToBeDeleted = [];
            this.dataToBeDeletedID = [];
        } else if (response.data.StatusCode !== 200) {
            uiService.toastService.error(response.data.Message);
        }
    }

    filterDriverBoardData(board){
        const arrayID: number[] = [];
        let driverData: any = []
        if(board === 'All'){
            driverData = this.backupDriverBoardData;
            this.driverBoardData = driverData;
        } else {
            this.backupDriverBoardData.forEach(x => {
                let flag = 0;
                x.DataResponse.forEach(y => {
                    if(y.Board === board && flag === 0){
                        arrayID.push(x.DriverID);
                        flag = 1
                    }
                })
            })
            driverData = this.backupDriverBoardData.filter(item => {
                return arrayID.includes(item.DriverID)
            });
            this.driverBoardData = driverData;
        }
    }
}

