import React, { Component } from 'react';
import { GridOptions, FilterChangedEvent } from 'ag-grid-community';
import crypto from 'crypto';
import Select, { components, ActionMeta, ValueType } from 'react-select';
import { Container, Row, Col } from 'react-bootstrap';
import { observer } from 'mobx-react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { uiService } from 'shared/services/ui-service';
import './_reportmanagement.scss';
import moment from 'moment';
import momenttimezone from 'moment-timezone';
import { AppConstant } from 'app_constant';
import { CustomButtonType, CustomButton } from 'shared/components/custom-button/custom-button';
import { AgGridComponent } from 'features/common/components/ag-grid-component';
import { AgGridComponentParams } from 'features/common/domain/model';
import { CustomerReportFieldName, ReportsButton, TitlePDF } from '../domains/enum';
import { dataStore } from 'features/common/stores';
import { commaFormat, reportticketStatus } from '../reports_constants';
import { ClearButtonComponent } from 'features/common/components/clear-button-component';
import { CustomerReportStore } from '../stores/customerreport-data-store';
import * as XLSX from 'xlsx';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import { localStorageService } from 'shared/services/local-storage-service';
import { ReactDayPickerInput } from 'shared/components/daypicketInput/react-daypickerInput';
import { ConfirmProps, CustomerReportScheduleEmailPopup } from './customerReportSchedulePopup';
import * as FileSaver from 'file-saver';
import { CommonUtils } from 'shared/services/common-utils';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
/* eslint-disable */
interface CustomerReportProps {
    customerReportStore: CustomerReportStore;
}

@observer
export class CustomerReportComponent extends Component<CustomerReportProps> {
    startDate: any;
    endDate: any;
    from: any = moment()
        .subtract(6, 'days')
        .toDate();
    to: any = moment().toDate();
    toDate: any;
    data: any = [];
    fromDate: any;
    constructor(props) {
        super(props);
        this.state = { actVolSum: 0, actVolAvg: 0 };
        dataStore.setModuleName('CustomerReport');
    }

    componentDidMount() {
        const { customerReportStore } = this.props;
        customerReportStore.init();
    }
    getTickets = () => {
        const { customerReportStore } = this.props;
        customerReportStore.getRangeResult(
            this.startDate.toISOString().slice(0, 10),
            this.endDate.toISOString().slice(0, 10)
        );
    };

    render(): React.ReactNode {
        const {
            customerReportStore: { isDisabled }, customerReportStore
        } = this.props;
        return (
            <Container fluid>
                <Row>
                    <Col>
                        <div className="master-data__action">
                            <Row>
                                <Col className="mt-3 mt-sm-0">
                                    {dataStore.checkOperationAccess('Save') &&
                                    <>
                                    <CustomButton
                                        type={CustomButtonType.Submit}
                                        onClick={this.sendrepo}
                                        disabled={(customerReportStore.selectLeaseOperator.length > 0 || customerReportStore.selectConsignee.length > 0) 
                                            && customerReportStore.customerReport.length > 0 ? false : true}
                                    >
                                        Send Report
                                    </CustomButton>
                                    <CustomButton
                                        type={CustomButtonType.Submit}
                                        onClick={this.openScheduledReportEmailPopUp}
                                        disabled={
                                            customerReportStore.selectLeaseOperator.length > 0 || customerReportStore.selectConsignee.length > 0  ? false : true
                                        }
                                    >
                                        Schedule
                                    </CustomButton>
                                    </> }
                                    <ClearButtonComponent handleRefresh={this.handleRefresh} isExportCSV={true} disabled={isDisabled()}></ClearButtonComponent>
                                </Col>
                            </Row>
                        </div>
                        <div>
                        <ReactDayPickerInput _dataStore={this.props.customerReportStore} showFirstDay={true}></ReactDayPickerInput>
                            <div className="form-inline padding-bottom">
                                Status :{' '}
                                <div className="reportmanagement__type">
                                    <Select
                                        value={customerReportStore.selectedOption}
                                        closeMenuOnSelect={false}
                                        closeMenuOnScroll={true}
                                        placeholder="Select Status"
                                        options={reportticketStatus}
                                        onChange={this.handleDataTypeChange}
                                        isMulti={true}
                                    />
                                </div>{' '}
                                Consignor Name :{' '}
                                <div className="reportmanagement__consignee">
                                    <Select
                                        value={customerReportStore.selectLeaseOperator}
                                        placeholder="Select Consignor"
                                        closeMenuOnSelect={false}
                                        closeMenuOnScroll={true}
                                        options={this.props.customerReportStore.leaseOperatorName}
                                        onChange={this.handleConsignorChange}
                                        isMulti={true}
                                    />
                                </div>{' '}
                                Consignee Name :{' '}
                                <div className="reportmanagement__consignee">
                                    <Select
                                        value={customerReportStore.selectConsignee}
                                        placeholder="Select Consignee"
                                        closeMenuOnSelect={false}
                                        closeMenuOnScroll={true}
                                        options={this.props.customerReportStore.consigneeName}
                                        onChange={this.handleConsigneeChange}
                                        isMulti={true}
                                    />
                                </div>
                            </div>
                        </div>
                        <AgGridComponent gridConfig={this.getGridConfig()} />
                            <div className="customer-report">
                                <Row>
                                    <Col className="mt-3 mt-sm-0">
                                        Volume Delivered Sum : {commaFormat(this.props.customerReportStore.actVolSum)}
                                    </Col>
                                    <Col className="mt-3 mt-sm-0">
                                        Total Waiting Time: {commaFormat(this.props.customerReportStore.waitingTime)}
                                    </Col>
                                    <Col className="mt-3 mt-sm-0">
                                        Total Working Time: {commaFormat(this.props.customerReportStore.workingTime)}
                                    </Col>
                                </Row>
                            </div>
                    </Col>
                </Row>
            </Container>
        );
    }

    componentDidUpdate(prevState, prevProps) {
        if (prevState.customerReportStore.actVolSum != prevProps.actVolSum) {
            const filters = localStorageService.get('CustomerReport_filter');
            this.props.customerReportStore.agGridService.setAllFilters(filters);
        }
    }

    private sendrepo = (): void => {
        const { customerReportStore } = this.props;
        const csvData: any = customerReportStore.agGridService.getNodes();
        const rows: any = [];
        csvData.rowModel.rowsToDisplay.forEach(x => {
            rows.push(x.data);
        });
        let data = JSON.parse(JSON.stringify(rows));
        const fields = [
            'ID',
            'LastPDSStatus',
            'CancellationReason',
            'Year',
            'HazardID',
            'Consignor24Phone2',
            'EmptyResidueLastContained',
            'ConsignorName',
            'ConsignorID',
            'Consignor24Phone',
            'ConsignorERPPlanNumber',
            'LoadTemperature',
            'UnLoadTemperature',
            'TankNumber',
            'ObsGravity',
            'ObsTemp',
            'TankSize',
            'InquiryID',
            'TrailerMileage',
            'ConsignorLSD',
            'ConsigneeLSD',
            'TankHeight',
            'GrossVolume',
            'LastTicketStatus',
            'UpdatedBy',
            'UpdatedDate',
            'TrailerStatus',
            'TrailerMappedToCompany',
            'EstimatedVolume',
            'SplitLoad',
            'LastPMTStatus',
            'ConsigneeField',
            'ConsignorField',
            'Consignor',
            'Consignee',
            'CompletedTime',
            'TicketTime',
            'StartTime',
            'TicketSource',
            'TractorMappedToCompany'
        ];

        for (let i = 0; i < data.length; i++) {
            for (const key in data[i]) {
                if (fields.indexOf(key) !== -1) delete data[i][key];
            }
            data[i]['TicketCreationDate'] =
            data[i]['TicketDate'] === null
                ? ''
                : momenttimezone
                    .tz(moment.utc(data[i]['TicketDate']), AppConstant.MST_TIME_FORMATTER)
                    .format(AppConstant.DATE_FORMAT);
            data[i]['AcceptedDate'] =
            data[i]['AcceptedDate'] === null
                ? ''
                : momenttimezone
                    .tz(moment.utc(data[i]['AcceptedDate']), AppConstant.MST_TIME_FORMATTER)
                    .format(AppConstant.DATE_TIME_FORMAT);
            data[i]['TicketDate'] =
                data[i]['TicketDate'] === null
                    ? ''
                    : momenttimezone
                        .tz(moment.utc(data[i]['TicketDate']), AppConstant.MST_TIME_FORMATTER)
                        .format('HH:mm:ss');
            data[i]['LoadDate'] =
                (data[i]['LoadDateTime'] === null || data[i]['LoadDateTime'] === undefined)
                    ? ''
                    : momenttimezone
                        .tz(moment.utc(data[i]['LoadDateTime']), AppConstant.MST_TIME_FORMATTER)
                        .format(AppConstant.DATE_FORMAT);
            data[i]['LoadTime'] =
                (data[i]['LoadDateTime'] === null || data[i]['LoadDateTime'] === undefined)
                    ? ''
                    : momenttimezone
                        .tz(moment.utc(data[i]['LoadDateTime']), AppConstant.MST_TIME_FORMATTER)
                        .format('HH:mm:ss');
            data[i]['StartDate'] =
                data[i]['StartDateTime'] === null
                    ? ''
                    : momenttimezone
                        .tz(moment.utc(data[i]['StartDateTime']), AppConstant.MST_TIME_FORMATTER)
                        .format(AppConstant.DATE_FORMAT);
            data[i]['StartDateTime'] =
                data[i]['StartDateTime'] === null
                    ? ''
                    : momenttimezone
                        .tz(moment.utc(data[i]['StartDateTime']), AppConstant.MST_TIME_FORMATTER)
                        .format('HH:mm:ss');
            data[i]['CompletedDate'] =
            data[i]['CompletedDateTime'] === null
                ? ''
                : momenttimezone
                    .tz(moment.utc(data[i]['CompletedDateTime']), AppConstant.MST_TIME_FORMATTER)
                    .format(AppConstant.DATE_FORMAT);
            data[i]['CompletedDateTime'] =
            data[i]['CompletedDateTime'] === null
                    ? ''
                    : momenttimezone
                        .tz(moment.utc(data[i]['CompletedDateTime']), AppConstant.MST_TIME_FORMATTER)
                        .format('HH:mm:ss');
            data[i]['EndDate'] =
            data[i]['EndDate'] === null
                    ? ''
                    : momenttimezone
                        .tz(moment.utc(data[i]['EndDate']), AppConstant.MST_TIME_FORMATTER)
                        .format(AppConstant.DATE_TIME_FORMAT);
            delete data[i]['LoadDateTime']
        }
        let sum = 0;
        let report: any;
        const roundOff = value => {
            return (Math.round((value + Number.EPSILON) * 1000) / 1000).toFixed(3);
        };
        for (report in data) {
            sum += data[report].VolumeDelivered;
            data[report].VolumeDelivered = roundOff(data[report].VolumeDelivered);
            data[report].ConsignorERPPhoneNumber = data[report].ConsignorERPPhoneNumber!= null ? data[report].ConsignorERPPhoneNumber.toString().replace(CommonUtils.getCellPhoneRegex(), "($1) $2 $3"): null
        }
        const selectedStartDate = customerReportStore.startDate;
        const selectedEndDate = customerReportStore.endDate;

        const fileName = 'customerReport' + new Date().getTime() + '.xlsx';
        const confirmService = new ConfirmService();
        const selectedLeaseOperator = customerReportStore.selectLeaseOperator.map((item: any) => item.value);
        const selectedConsignee = customerReportStore.selectConsignee.map((item: any) => item.value);
        const fieldNames = "TicketCreationDate,TicketDate,LoadDate,LoadTime,CompletedDate,CompletedDateTime,TicketNumber,TicketType,LeaseOperatorName,DriverName,Region,Trailer,VolumeDelivered,Origin,Destination,Product,WorkingTime,WorkingTimeReason,WaitingTime,WaitingTimeReason,Comments,ShipperTicketBOL,RelatedVolumeTicket,RoadBan,Via,TicketStatus,ApproverComment,BSW,Density,GrossWeight,TareWeight,NetWeight,MeterFactor,MeterNumber,OpenMeterReading,CloseMeterReading,PowerUnitIdentifier,Axles,StartDate,StartDateTime,EndDate,UomID,RunTickeScaleTicketNumber,CreatedBy,AuthorizedBy,ConsignorAddress,ConsignorERPPhoneNumber,ConsigneeID"
        const excelFields = "Ticket Created Date/Ticket Planned Date,Ticket Time,Load Date,Load Time,Completed Date,Completed Time,Ticket Number,Ticket Type,Carrier Company,Driver Name,Region,Trailer,Volume Delivered,Origin,Destination,Product,Working Time,Working Time Reason,Waiting Time,Waiting Time Reason,Comments,Shipper Ticket/BOL,Related Volume Ticket,Road Ban,Road Via,Status,Approver Comment,BSW,Density,Gross Weight,Tare Weight,Net Weight,Meter Factor,Meter Number,Open Meter Reading,Close Meter Reading,Power Unit Identifier,Axles,Start Date,Start Time,End Date,Units,Run Ticket/Scale Ticket,Created By,Authorized By,Consignor Address,Consignor ERP PhoneNumber,Consignee ID"
        
        confirmService.showEmailPopup(email => {
            const reqbody = {
                ExcelFields: excelFields,
                Fields: fieldNames,
                FromEmailID: this.props.customerReportStore.usermail,
                FileName: fileName,
                ToEmailID: email,
                FromDate: selectedStartDate,
                ToDate: selectedEndDate,
                CustomerReportConsginors: selectedLeaseOperator.toString(),
                CustomerReportConsginees: selectedConsignee.toString(),
                CCReportjson: data,
                VolumeDeliveredSum: roundOff(sum)
            };
            customerReportStore.sendreport(reqbody);
        });
    };

    private openScheduledReportEmailPopUp = async (): Promise<void> => {
        const { customerReportStore } = this.props;
        const selectedLeaseOperator = customerReportStore.selectLeaseOperator.map((item: any) => item.value);
        const reqbody = {
            ConsignorName: selectedLeaseOperator.toString()
        };
        // await customerReportStore.getEmailConfiguration(reqbody);

        this.sendScheduledData();
    };
    private sendScheduledData() {
        const { customerReportStore } = this.props;
        const fields = [
            'TicketCreationDate',
            'TicketTime',
            'LoadDate',
            'LoadTime',
            'CompletedDate',
            'CompletedTime',
            'TicketNumber',
            'TicketType',
            'LeaseOperatorName',
            'DriverName',
            'Region',
            'Trailer',
            'VolumeDelivered',
            'Origin',
            'Destination',
            'Product',
            'WorkingTime',
            'WorkingTimeReason',
            'WaitingTime',
            'WaitingTimeReason',
            'Comments',
            'ShipperTicketBOL',
            'RelatedVolumeTicket',
            'RoadBan',
            'Via',
            'TicketStatus',
            'ApproverComment',
            'BSW',
            'Density',
            'GrossWeight',
            'TareWeight',
            'NetWeight',
            'MeterFactor',
            'MeterNumber',
            'OpenMeterReading',
            'CloseMeterReading',
            'PowerUnitIdentifier',
            'Axles',
            'StartDate',
            'StartTime',
            'EndDate',
            'UomID',
            'RunTickeScaleTicketNumber',
            'CreatedBy',
            'AuthorizedBy',
            'ConsignorAddress',
            'ConsignorERPPhoneNumber',
            'LastPMTStatus',
            'UpdatedBy',
            'UpdatedDate',
            'ConsignorName',
            'EstimatedVolume', 
        ];
        const status = [
            'New',
            'Approved',
            'Not Approved',
            'Hold',
            'Cancelled',
            'Completed'
        ];
        const confirmService = new ConfirmService();
        const fileName = 'customerReport' + new Date().getTime() + '.xlsx';
        const currentDate = moment(new Date().toISOString()).format('YYYY-MM-DDTHH:mm:ss');
        const selectedStartDate = customerReportStore.startDate;
        const selectedEndDate = customerReportStore.endDate;

        confirmService.showConfirmWithCustomComponent(
            async data => {
                const reqbody = data;
                let userID = this.props.customerReportStore.userID;
                if (!userID || userID === 0 ) {
                    await this.props.customerReportStore.accountStore.getLoggedInUserDetailsIfUserIdZero(this.props.customerReportStore.accountStore.userName).then(() => {
                        userID = this.props.customerReportStore.accountStore.getUserID();
                    });
                    if (!userID || userID === 0 ) {
                        return;
                    }
                }
                reqbody.CreatedBy = userID;
                reqbody.ScreenName = fileName;
                const ConsignorNameIDs = customerReportStore.customerReport.map((item: any) => {return {ConsignorName: item.Origin, ID: item.ConsignorID}});
                const ConsigneeNameIDs = customerReportStore.customerReport.map((item: any) => {return {ConsigneeName: item.Destination, ID: item.ConsigneeID}});
                const selectedConsigneeNameIDs = ConsigneeNameIDs.length > 0 && customerReportStore.selectConsignee.length > 0 ? ConsigneeNameIDs.filter((data) => !customerReportStore.selectConsignee.includes({value: data.ConsigneeName, label: data.ConsigneeName})) : [];
                const selectedConsignorNameIDs = ConsignorNameIDs.length > 0 && customerReportStore.selectLeaseOperator.length > 0 ? ConsignorNameIDs.filter((data) => !customerReportStore.selectLeaseOperator.includes({value: data.ConsignorName, label: data.ConsignorName})) : [];
                reqbody.CnsrName = selectedConsignorNameIDs.length > 0 ? Array.from(new Set(selectedConsignorNameIDs.map((item: any) => item.ID))).join(',') : null;
                reqbody.CnsnName = selectedConsigneeNameIDs.length > 0 ? Array.from(new Set(selectedConsigneeNameIDs.map((item: any) => item.ID))).join(',') : null;
                if (data.Frequency === 'Adhoc') {
                    reqbody.StartDate = selectedStartDate;
                    reqbody.EndDate = selectedEndDate;
                }
                customerReportStore.createCustomerReport(reqbody);
            },
            CustomerReportScheduleEmailPopup,
            {
                status: status,
                fields: fields,
                selectedLeaseOperator: customerReportStore.selectLeaseOperator,
                selectedConsignee: customerReportStore.selectConsignee,
                emailConfiguration: customerReportStore.emailConfiguration,
                module: 'CustomerReport'
            } as ConfirmProps,
            ''
        );
    }

    handleDataTypeChange = option => {
        const { customerReportStore } = this.props;
        let status: any = [];
        if (option && option.length > 0) {
            status = Array.from(new Set(option.map((item: any) => item.value)));
            if (status.includes('all') && option.length > 1) {
                option.forEach((ele, index) => {
                    if (ele.value == 'all') option.splice(index, 1);
                });
            }
            this.setState((customerReportStore.selectedOption = option));
        } else {
            this.setState((customerReportStore.selectedOption = [{ value: 'all', label: 'All' }]));
            status = Array.from(new Set(reportticketStatus.map((item: any) => item.value)));
        }
        customerReportStore.statusFiltered(status, customerReportStore.selectLeaseOperator, customerReportStore.selectConsignee,false);
        let dropdown: any = localStorageService.get('CustomerReport_Dropdown')
        localStorageService.set('CustomerReport_Dropdown', [{'TicketStatus': customerReportStore.selectedOption, 'Consignor': dropdown[0].Consignor, 'Consignee': dropdown[0].Consignee}])
    };
    handleConsignorChange = option => {
        const { customerReportStore } = this.props;
        let selectoption: any = [];
        if (option && option.length > 0) {
            selectoption = Array.from(new Set(option.map((item: any) => item.value)));
            if (selectoption.includes('select') && option.length > 1) {
                option.forEach((ele, index) => {
                    if (ele.value == 'select') option.splice(index, 1);
                });
            }
            this.setState((customerReportStore.selectLeaseOperator = option));
        } else {
            this.setState((customerReportStore.selectLeaseOperator = []));
            selectoption = Array.from(
                new Set(customerReportStore.leaseOperatorName.map((item: any) => item.value))
            );
            if(customerReportStore.selectConsignee.length > 0) {
                customerReportStore.consigneeName = customerReportStore.backupConsigneeDropdown
            }
        }

        customerReportStore.consignorChange = true;
        if(customerReportStore.selectConsignee.length === 0) customerReportStore.isMaster = true;
        customerReportStore.handleFiltered(customerReportStore.selectLeaseOperator,customerReportStore.selectConsignee);
       if(customerReportStore.selectConsignee.length === 0) {
        customerReportStore.consigneeName = [];
        customerReportStore.consignee = Array.from(new Set(customerReportStore.customerReport.map((item: any) => item.Destination)));
        customerReportStore.consignee.forEach(name => {
            customerReportStore.consigneeName.push({ value: name, label: name });
        });}
        let dropdown: any = localStorageService.get('CustomerReport_Dropdown')
        localStorageService.set('CustomerReport_Dropdown',[{'TicketStatus': dropdown[0].TicketStatus, 'Consignor': customerReportStore.selectLeaseOperator, 'Consignee': dropdown[0].Consignee}])
    };

    handleRefresh = () => {
        const { customerReportStore } = this.props;
        if(customerReportStore.startdate!= '' && customerReportStore.enddate!= ''){
            customerReportStore.getRangeResult(customerReportStore.startdate,customerReportStore.enddate)
        }
        else{
            customerReportStore.init();
        }
    }

    handleConsigneeChange = option => {
        const { customerReportStore } = this.props;
        let selectoption: any = [];
        if (option && option.length > 0) {
            selectoption = Array.from(new Set(option.map((item: any) => item.value)));
            if (selectoption.includes('select') && option.length > 1) {
                option.forEach((ele, index) => {
                    if (ele.value == 'select') option.splice(index, 1);
                });
            }
            this.setState((customerReportStore.selectConsignee = option));
        } else {
            this.setState((customerReportStore.selectConsignee = []));
            selectoption = Array.from(
                new Set(customerReportStore.consigneeName.map((item: any) => item.value))
            );
            if(customerReportStore.selectLeaseOperator.length > 0) {
                customerReportStore.leaseOperatorName = customerReportStore.backupConsignorDropdown
            }
        }
        customerReportStore.consigneeChange = true;
        if(customerReportStore.selectLeaseOperator.length === 0) customerReportStore.isMaster = true;
        customerReportStore.handleFiltered(customerReportStore.selectLeaseOperator,customerReportStore.selectConsignee);
        if(customerReportStore.selectLeaseOperator.length === 0) {
            customerReportStore.leaseOperatorName = [];
            customerReportStore.leaseoperator = Array.from(new Set(customerReportStore.customerReport.map((item: any) => item.Origin)));
            customerReportStore.leaseoperator.forEach(name => {
                customerReportStore.leaseOperatorName.push({ value: name, label: name });
            });}
            let dropdown: any = localStorageService.get('CustomerReport_Dropdown')
            localStorageService.set('CustomerReport_Dropdown',[{'TicketStatus': dropdown[0].TicketStatus, 'Consignor': dropdown[0].Consignor, 'Consignee': customerReportStore.selectConsignee}])
    }
    private exportCsv = () => {
        const csvData: any = this.props.customerReportStore.agGridService.getNodes();
        let data: any = [];
        csvData?.rowModel.rowsToDisplay.forEach(x => {
            data.push(x.data);
        });
        const fields = [
            'ID',
            'TicketTime',
            'Origin',
            'LastPMTStatus',
            'LastTicketStatus',
            'UpdatedBy',
            'UpdatedDate',
            'TrailerStatus',
            'TrailerMappedToCompany',
            'CancellationReason',
            'Year',
            'Consignor24Phone2',
            'EmptyResidueLastContained',
            'ConsignorName',
            'ConsignorID',
            'Consignor24Phone',
            'ConsignorERPPlanNumber',
            'ConsignorERPPhoneNumber',
            'HazardID',
            'LoadTemperature',
            'UnLoadTemperature',
            'SplitLoad',
            'StartTime',
            'ConsignorLSD',
            'ConsigneeLSD',
            'TankHeight',
            'GrossVolume',
            'ConsigneeField',
            'ConsignorField',
            'Consignor',
            'Consignee',
            'TicketSource',
            'CompletedTime',
            'TractorMappedToCompany',
            'LoadDateTime'
        ];
        for (let i = 0; i < data.length; i++) {
            for (const key in data[i]) {
                if (fields.indexOf(key) !== -1) delete data[i][key];
            }
            data[i]['TicketDate'] =
                data[i]['TicketDate'] === null
                    ? ''
                    : momenttimezone
                        .tz(moment.utc(data[i]['TicketDate']), AppConstant.MST_TIME_FORMATTER)
                        .format('HH:mm:ss');
            data[i]['StartDateTime'] =
                data[i]['StartDateTime'] === null
                    ? ''
                    : momenttimezone
                        .tz(moment.utc(data[i]['StartDateTime']), AppConstant.MST_TIME_FORMATTER)
                        .format('HH:mm:ss');
            data[i]['CompletedDateTime'] =
            data[i]['CompletedDateTime'] === null
                    ? ''
                    : momenttimezone
                        .tz(moment.utc(data[i]['CompletedDateTime']), AppConstant.MST_TIME_FORMATTER)
                        .format('HH:mm:ss');
        }
        let str = JSON.stringify(data);
        str = str.replace(/\"Via\":/g, '"Road Via":');
        str = str.replace(/\"LeaseOperatorName\":/g, '"Carrier Company":');
        str = str.replace(/\"UomID\":/g, '"Units":');
        str = str.replace(/\"RunTickeScaleTicketNumber\":/g, '"Run Ticket/Scale Ticket":');
        str = str.replace(/\"StartDateTime\":/g, '"Start Time":');
        str = str.replace(/\"CompletedDateTime\":/g, '"Completed Time":');
        str = str.replace(/\"TicketDate\":/g, '"Ticket Time":');
        const ws = XLSX.utils.json_to_sheet(JSON.parse(str));
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const exceldata: Blob = new Blob([excelBuffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(exceldata, TitlePDF.CustomerReport);
    };

    onFilterChanged = (event: FilterChangedEvent): void => {
        const griddata: any = this.props.customerReportStore.agGridService.getNodes();
        localStorageService.set('CustomerReport_filter', this.props.customerReportStore.agGridService.getAllFilters());
        const data: any = [];
        griddata.rowModel.rowsToDisplay.forEach(x => {
            data.push(x.data);
        });
        if(this.props.customerReportStore.clearSelection == true){
            this.props.customerReportStore.selectedOption = [{ value: 'all', label: 'All' }]
            this.setState((
                this.props.customerReportStore.selectLeaseOperator = [],
                this.props.customerReportStore.selectConsignee = []
            ))
            this.props.customerReportStore.customerReport = this.props.customerReportStore.dailyBackupReport
            this.props.customerReportStore.clearSelection = false;
        }
        this.props.customerReportStore.sumavgReport(data);
    };

    private getGridConfig(): GridOptions {
        const {
            customerReportStore: { DuplicateList, agGridService }
        } = this.props;
        const { customerReportStore } = this.props;

        const rowData = DuplicateList;
        const columnDefs = customerReportStore.getColDef();
        const onFilterChanged = this.onFilterChanged;
        const CustomerReportGridParams: AgGridComponentParams = {
            rowData,
            columnDefs,
            onFilterChanged
        };
        return agGridService.getGridConfig(CustomerReportGridParams);
    }
}
