/* eslint-disable @typescript-eslint/no-explicit-any */
const apiClientID = (window as any).hostName.API_CLIENT_ID;
const tenantID = (window as any).hostName.TENANT_ID;
const WebAppServerUrl = (window as any).hostName.WEB_APP_HOST;
const msalEndpoint = (window as any).hostName.MSAL_ENDPOINT;
const FunctionAppHostName = (window as any).hostName.FUNCTION_APP_HOST;
const functionAppKey = (window as any).hostName.FUNCTION_APP_KEY;
const psaWebUrl = (window as any).hostName.PSA_WEB_URL;
const deployDate = (window as any).hostName.PDS_DEPLOY_DATE;
const buildInfo = (window as any).hostName.BUILD_INFO;
export const Config = {
    apiClientID,
    tenantID,
    msalEndpoint,
    functionAppKey,
    psaWebUrl,
    buildInfo,
    deployDate,
    redirectURL: `${WebAppServerUrl}/login`,
    getInspectionDefects: `${FunctionAppHostName}getInspectionDefectsWithVersion?code=${functionAppKey}`,
    getPrePostChecklist: `${FunctionAppHostName}GetPrePostChecklist?code=${functionAppKey}`,
    saveCompanyRegionMapping: `${FunctionAppHostName}CreateCompanyRegionMapping?code=${functionAppKey}`,
    getCompanyRegionMapping: `${FunctionAppHostName}GetCompanyRegionMapping?code=${functionAppKey}`,
    saveCompanyMapping: `${FunctionAppHostName}CompanyMapping?code=${functionAppKey}`,
    getCarrierCompanyMappingDriverTrailer: `${FunctionAppHostName}GetCarrierCompanyMappingDriverTrailer?code=${functionAppKey}`,
    updateTrailerMaintenances: `${FunctionAppHostName}UpdateTrailerMaintenances?code=${functionAppKey}`,
    getShopInfo: `${FunctionAppHostName}GetShop?code=${functionAppKey}`,
    getBaseValueByClass: `${FunctionAppHostName}GetConfigValueByClass?code=${functionAppKey}`,
    getTrailerMaintenanceData: `${FunctionAppHostName}GetMaintenanceForAllTrailers?code=${functionAppKey}`,
    getUser: `${FunctionAppHostName}GetUser?code=${functionAppKey}`,
    getLoginUser: `${FunctionAppHostName}GetLoginUser?code=${functionAppKey}`,
    getInspectionDefectsImages: `${FunctionAppHostName}GetlistofInspectionTicketImage?code=${functionAppKey}`,
    getTrailerData: `${FunctionAppHostName}GetTrailersByVersion?code=${functionAppKey}`,
    getTrailer: `${FunctionAppHostName}GetTrailersByVersionSP?code=${functionAppKey}`,
    updateTrailers: `${FunctionAppHostName}UpdateTrailers?code=${functionAppKey}`,
    getPrinterData: `${FunctionAppHostName}GetPrinterInfo?code=${functionAppKey}`,
    updatePrinter: `${FunctionAppHostName}UpdatePrinterInfo?code=${functionAppKey}`,
    createUser: `${FunctionAppHostName}CreateUser?code=${functionAppKey}`,
    getFacility: `${FunctionAppHostName}GetFacility?code=${functionAppKey}`,
    getTabletInfoList: `${FunctionAppHostName}GetTabletList?code=${functionAppKey}`,

    getInspectionHistoryInfoList: `${FunctionAppHostName}GetInspectionTicketHistory?code=${functionAppKey}`,
    updateInspectionHistoryInfos: `${FunctionAppHostName}UpdateInspectionTickets?code=${functionAppKey}`,

    updateTabletInfoList: `${FunctionAppHostName}updatedTablet?code=${functionAppKey}`,
    getVolumeTicket: `${FunctionAppHostName}GetVolumeTicket?code=${functionAppKey}`,
    updateVolumeTicket: `${FunctionAppHostName}updateVolumeTicket?code=${functionAppKey}`,
    getTractors: `${FunctionAppHostName}GetTractors?code=${functionAppKey}`,
    updateTractor: `${FunctionAppHostName}UpdateTractor?code=${functionAppKey}`,
    createTractor: `${FunctionAppHostName}CreateTractor?code=${functionAppKey}`,
    deleteTractor: `${FunctionAppHostName}DeleteTractor?code=${functionAppKey}`,
    getShopInfoList: `${FunctionAppHostName}GetAllShop?code=${functionAppKey}`,
    updateShopInfoList: `${FunctionAppHostName}CreateUpdateShop?code=${functionAppKey}`,
    updateUserStatusForApproval: `${FunctionAppHostName}UpdateUserStatusForVerifiedUser?code=${functionAppKey}`,
    userApprovalInfo: `${FunctionAppHostName}GetUserApprovalInfo?code=${functionAppKey}`,
    getCarrierCompany: `${FunctionAppHostName}GetCarrierCompany?code=${functionAppKey}`,
    updateUserPSA: `${FunctionAppHostName}UpdateUserRequestPSA?code=${functionAppKey}`,
    updateUserPTS: `${FunctionAppHostName}UpdateUserRequestPTS?code=${functionAppKey}`,
    getCarrierCompanyData: `${FunctionAppHostName}GetcarrierCompanyInfo?code=${functionAppKey}`,
    getRACarrierCompanies: `${FunctionAppHostName}GetRACarrierCompanies?code=${functionAppKey}`,
    updateVendorData: `${FunctionAppHostName}UpdateCarrierCompanyMapping?code=${functionAppKey}`,
    getTicket: `${FunctionAppHostName}GetTicket?code=${functionAppKey}`,
    deletePrinter: `${FunctionAppHostName}DeletePrinter?code=${functionAppKey}`,
    updateTicketStatus: `${FunctionAppHostName}UpdateTicketStatus?code=${functionAppKey}`,
    getDrivers: `${FunctionAppHostName}GetDriverManagementSP?code=${functionAppKey}`,
    updateDrivers: `${FunctionAppHostName}UpdateDrivers?code=${functionAppKey}`,
    getPSACarrier: `${FunctionAppHostName}GetPSACarrier?code=${functionAppKey}`,
    disApproveUserPSA: `${FunctionAppHostName}DisapproveUserPSA?code=${functionAppKey}`,
    disApproveUserPTS: `${FunctionAppHostName}DisapproveUserPTS?code=${functionAppKey}`,
    importPSAData: `${FunctionAppHostName}ImportPSAData?code=${functionAppKey}`,
    deactivateUser: `${FunctionAppHostName}DeactivateUser?code=${functionAppKey}`,
    getDailyReport: `${FunctionAppHostName}GetDailyReport?code=${functionAppKey}`,
    getMasterReport: `${FunctionAppHostName}GetMasterTicketReport?code=${functionAppKey}`,
    getPSACarrierWithDispatcher: `${FunctionAppHostName}GetPSACarrierWithDispatcher?code=${functionAppKey}`,
    getUserToApproveForDispatcher: `${FunctionAppHostName}GetUserDetailForDispatcher?code=${functionAppKey}`,
    UpdateUserStatusByDispatcher: `${FunctionAppHostName}UpdateUserStatusByDispatcher?code=${functionAppKey}`,
    getLeaseOperator: `${FunctionAppHostName}GetLeaseOperatorReport?code=${functionAppKey}`,
    sendReport: `${FunctionAppHostName}SendHourlyReportEmail?code=${functionAppKey}`,
    getHourlyReport: `${FunctionAppHostName}GetHourlyReport?code=${functionAppKey}`,
    sendCustomerReport: `${FunctionAppHostName}SendCustomerReportEmail?code=${functionAppKey}`,
    sendBroadCast: `${FunctionAppHostName}SendBroadcastMessage?code=${functionAppKey}`,
    sendDirectMessage: `${FunctionAppHostName}sendDirectMessage?code=${functionAppKey}`,
    getDriverCompanyMapping: `${FunctionAppHostName}GetDriverCompanyMappings?code=${functionAppKey}`,
    getAllMessages: `${FunctionAppHostName}GetAllMessagesPDS?code=${functionAppKey}`,
    updateReadMessage: `${FunctionAppHostName}UpdateIsReadMessage?code=${functionAppKey}`,
    getBroadcastCount: `${FunctionAppHostName}GetBroadcastCount?code=${functionAppKey}`,
    getOperator: `${FunctionAppHostName}GetOperator?code=${functionAppKey}`,
    sendOperator: `${FunctionAppHostName}CreateUpdateLocationData?code=${functionAppKey}`,
    getLocationScreenData: `${FunctionAppHostName}GetLocationScreenData?code=${functionAppKey}`,
    uploadLocationExcel: `${FunctionAppHostName}UploadLocationExcel?code=${functionAppKey}`,
    sendLocationMapping: `${FunctionAppHostName}CreateLocationMappingInfo?code=${functionAppKey}`,
    getLocationMapingScreenData: `${FunctionAppHostName}GetLocationMappingInfo?code=${functionAppKey}`,
    createTrailerMaintenance: `${FunctionAppHostName}CreateTrailerMaintenance?code=${functionAppKey}`,
    createUpdateCapacityPlan: `${FunctionAppHostName}CreateUpdateCapacityPlan?code=${functionAppKey}`,
    getCapacityPlanningExternal: `${FunctionAppHostName}GetCapacityPlanningExternal?code=${functionAppKey}`,
    getCapacityPlanningInternal: `${FunctionAppHostName}GetCapacityPlanningInternal?code=${functionAppKey}`,
    getDispatcher: `${FunctionAppHostName}GetAllDispatchers?code=${functionAppKey}`,
    getCCUser: `${FunctionAppHostName}GetRegionCCUsersList?code=${functionAppKey}`,
    createCustomerReport: `${FunctionAppHostName}CreateCustomerReport?code=${functionAppKey}`,
    getEmailConfiguration: `${FunctionAppHostName}GetEmailConfigurationSP?code=${functionAppKey}`,
    getDispatchPlanData:`${FunctionAppHostName}GetDisptachPlanData?code=${functionAppKey}`,
    getDriverForDispatchPlan:`${FunctionAppHostName}GetDriversForDispatch?code=${functionAppKey}`,
    updateDispatchPlanStatus:`${FunctionAppHostName}UpdateDispatchPlanStatus?code=${functionAppKey}`,
    getRejectedLoadSP: `${FunctionAppHostName}GetRejectedLoadSP?code=${functionAppKey}`,
    getNomination: `${FunctionAppHostName}GetNominationData?code=${functionAppKey}`,
    uploadNominationExcel: `${FunctionAppHostName}UploadNominationExcel?code=${functionAppKey}`,
    getNominationLoad: `${FunctionAppHostName}GetNominationLoadData?code=${functionAppKey}`,
    createUpdateNominationData: `${FunctionAppHostName}CreateUpdateNominationData?code=${functionAppKey}`,
    createUpdateNominationLoadData: `${FunctionAppHostName}CreateUpdateNominationLoadData?code=${functionAppKey}`,
    getApportionmentData: `${FunctionAppHostName}GetApportionmentScreen?code=${functionAppKey}`,
    createUpdateApportionmentData: `${FunctionAppHostName}CreateUpdateApportionmentScreen?code=${functionAppKey}`,
    getLocationMappingInfo: `${FunctionAppHostName}GetLocationMappingInfo?code=${functionAppKey}`,
    GetOTESReport: `${FunctionAppHostName}GetOTESReport?code=${functionAppKey}`,
    createUpdateHazardAssessment: `${FunctionAppHostName}CreateUpdateHazardAssessment?code=${functionAppKey}`,
    getHazardAssessmentReport: `${FunctionAppHostName}GetHazardAssessmentReport?code=${functionAppKey}`,
    getPastDueForTrailers: `${FunctionAppHostName}GetPastDueforTrailers?code=${functionAppKey}`,
    createUpdatePastOverdueforTrailers: `${FunctionAppHostName}CreateUpdatePastOverdueforTrailers?code=${functionAppKey}`,
    updateUserDetails: `${FunctionAppHostName}UpdateUserDetails?code=${functionAppKey}`,
    getOpenTicketsForDriver: `${FunctionAppHostName}GetOpenTicketsForDriver?code=${functionAppKey}`,
    getUpcomingCertifications: `${FunctionAppHostName}GetUpcomingCertifications?code=${functionAppKey}`,
    removeUserRole: `${FunctionAppHostName}RemoveUserRole?code=${functionAppKey}`,
    getCustomerReport: `${FunctionAppHostName}GetCustomerReportSP?code=${functionAppKey}`,
    getSearchTickets: `${FunctionAppHostName}GetSearchTickets?code=${functionAppKey}`,
    getDispatchDetails: `${FunctionAppHostName}GetDispatchTicketsForMasterReport?code=${functionAppKey}`,
    getInquiries: `${FunctionAppHostName}GetInquiries?code=${functionAppKey}`,
    getProduct: `${FunctionAppHostName}GetProduct?code=${functionAppKey}`,
    getUom: `${FunctionAppHostName}GetUom?code=${functionAppKey}`,
    createCarrierCompanyInfo: `${FunctionAppHostName}CreateCarrierCompanyInfo?code=${functionAppKey}`,
    getDispatchBoardDetails: `${FunctionAppHostName}GetDispatchBoardDetails?code=${functionAppKey}`,
    getDispatchBoardMapping: `${FunctionAppHostName}GetDispatchBoardMapping?code=${functionAppKey}`,
    getDriverBoardMapping: `${FunctionAppHostName}GetDriverBoardMapping?code=${functionAppKey}`,
    getDriverCCTicketDetails: `${FunctionAppHostName}GetDriverCCTicketDetails?code=${functionAppKey}`,
    getPendingTicketDetails: `${FunctionAppHostName}GetPendingTicketDetails?code=${functionAppKey}`,
    createUpdateDispatchBoardMapping: `${FunctionAppHostName}CreateUpdateDispatchBoardMapping?code=${functionAppKey}`,
    getKeyfobsData: `${FunctionAppHostName}GetKeyFobManagementData?code=${functionAppKey}`,
    createupdateKeyfobs: `${FunctionAppHostName}CreateUpdateKeyFobData?code=${functionAppKey}`,
    getAssignedTicketDetails: `${FunctionAppHostName}GetAssignedTicketDetails?code=${functionAppKey}`
};
