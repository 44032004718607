export const dataTypeOptions = [
    { value: 'Select', label: 'Select' },
    { value: 'DailyReport', label: 'Daily Report' },
    { value: 'MasterTicketReport', label: 'Master Ticket Report' },
    { value: 'HourlyReport', label: 'Hourly Report' },
    { value: 'LeaseOperatorReport', label: 'Carrier Company Report' },
    { value: 'CustomerReport', label: 'Customer Report' },
    { value: 'RejectedLoads', label: 'Rejected Loads' },
    { value: 'ScheduledReports', label: 'Scheduled Reports' },
    { value: 'UpcomingCertificationReport', label: 'Upcoming Certifications Report'},
    { value: 'SearchTickets', label: 'Search Tickets' }
];

export const statusType = [
    { value: 'Driver Accepted', label: 'Driver Accepted' },
    { value: 'Enroute', label: 'Enroute' },
    { value: 'Loaded', label: 'Loaded' },
    { value: 'Completed', label: 'Completed' },
    { value: 'Cancelled', label: 'Cancelled' },
    { value: 'System Completed', label: 'System Completed' }
];

export const MasterTicketstatusType = [
    { value: 'New', label: 'New' },
    { value: 'Approved', label: 'Approved' },
    { value: 'Not Approved', label: 'Not Approved' },
    { value: 'Hold', label: 'Hold' },
    { value: 'Drafted', label: 'Drafted' },
    { value: 'Driver Dispatched', label: 'Driver Dispatched' },
    { value: 'Carrier Assigned', label: 'Carrier Assigned' },
    { value: 'Driver Accepted', label: 'Driver Accepted' },
    { value: 'Carrier Accepted', label: 'Carrier Accepted' },
    { value: 'Enroute', label: 'Enroute' },
    { value: 'Loaded', label: 'Loaded' },
    { value: 'Recalled', label: 'Recalled' },
    { value: 'Recalled By Carrier', label: 'Recalled By Carrier' },
    { value: 'Completed', label: 'Completed' },
    { value: 'Cancelled', label: 'Cancelled' },
    { value: 'System Completed', label: 'System Completed' },
    { value: 'Dispatch_Pending', label: 'Dispatch_Pending' },
    { value: 'Recalled_Pending', label: 'Recalled_Pending' },
    { value: 'Cancelled_Pending', label: 'Cancelled_Pending' },
    { value: 'Rejected By Driver', label: 'Rejected By Driver' },
    { value: 'Rejected By Carrier', label: 'Rejected By Carrier' },
    { value: 'Cancelled By Driver', label: 'Cancelled By Driver' },
    { value: 'Cancelled By Carrier', label: 'Cancelled By Carrier' },
];

export const ticketType = [
    { value: 'All', label: 'All' },
    { value: 'Volume', label: 'Volume'},
    { value: 'Hourly', label: 'Hourly'}
]

export const searchByType = [
    { value: 'TicketNumber', label: 'Ticket Number' },
    { value: 'DriverDetails', label: 'Driver Details' }
];

export const dateTypeArray = [
    {value: 'LoadDate', label: 'Load Date'},
    {value: 'CreatedDate', label: 'Created Date'},
    {value: 'CompletedDate', label: 'Completed Date'}
];

export const ticketStatus = [
    { value: 'New', label: 'New' },
    { value: 'Driver Dispatched', label: 'Driver Dispatched/Dispatch Pending' },
    { value: 'Carrier Assigned', label: 'Carrier Assigned' },
    { value: 'Driver Accepted', label: 'Driver Accepted' },
    { value: 'Carrier Accepted', label: 'Carrier Accepted' },
    { value: 'Enroute', label: 'Enroute' },
    { value: 'Loaded', label: 'Loaded' },
    { value: 'Approved', label: 'Approved' },
    { value: 'Hold', label: 'Hold' },
    { value: 'Recalled By Carrier', label: 'Recalled By Carrier' },
    { value: 'Completed', label: 'Completed' },
    { value: 'System Completed', label: 'System Completed' }
];

export const reportticketStatus = [
    { value: 'New', label: 'New' },
    { value: 'Approved', label: 'Approved' },
    { value: 'Not Approved', label: 'Not Approved' },
    { value: 'Hold', label: 'Hold' },
    { value: 'Cancelled', label: 'Cancelled' },
    { value: 'Completed', label: 'Completed' },
    { value: 'System Completed', label: 'System Completed' }
];

export const customerReportStatus = [
    { value: 'All', label: 'All' },
    { value: 'New', label: 'New' },
    { value: 'Approved', label: 'Approved' },
    { value: 'Not Approved', label: 'Not Approved' },
    { value: 'Hold', label: 'Hold' },
    { value: 'Cancelled', label: 'Cancelled' },
    { value: 'Completed', label: 'Completed' }
];

export const CCUserRoles = [
    'PTSOwnerOperator',
    'PTSCarrierCompanyScheduler',
    'PTSCarrierOwner',
    'PTSCarrierCompanyAdmin',
    'ptsthirdpartydispatcher'
];

export const commaFormat = (number) => {
    return number != null && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const isFloat = (n) => {
    return (n - Math.floor(n)) !== 0
}

export const RejectedLoadsStatus = [
    { value: 'Rejected By Driver', label: 'Rejected By Driver' },
    { value: 'Cancelled By Driver', label: 'Cancelled By Driver' },
    { value: 'Rejected By Carrier', label: 'Rejected By Carrier' },
    { value: 'Cancelled By Carrier', label: 'Cancelled By Carrier' }
]