import { restApiService } from 'shared/services';
import { Config } from 'config';
import { RestApiService } from 'shared/services/rest-api-service';
import { RejectedLoadResponse, RejectedDateRange } from '../domains/model';
import { TabletResponse } from 'features/data_management/domains/tablet-modal-response';

export class SearchTicketsDataService {
    constructor(private restApi: RestApiService) {}

    async getSearchTickets(reqbody: any): Promise<any> {
        const { data } = await this.restApi.postWithToken<any>(Config.getSearchTickets, reqbody, {});
        return data['Data'];
    }
}

export const searchTicketsDataService = new SearchTicketsDataService(restApiService);
