import React, { Component } from 'react';
import { CarrierFacilityPopUpComponent } from './carrier-facility-popup-component';
import { UserApprovalDataStore } from '../stores/user-approval-data-store';
import { CellEditingStartedEvent, CellEditingStoppedEvent, GridApi, GridOptions, RowClickedEvent } from 'ag-grid-community';
import { Container, Row, Col } from 'react-bootstrap';
import { observer } from 'mobx-react';
import './_user-signup.scss';
import { AgGridComponentParams } from 'features/common/domain/model';
import { AgGridComponent } from 'features/common/components/ag-grid-component';
import { CommonUtils } from 'shared/services/common-utils';
import { CustomButton, CustomButtonType } from 'shared/components/custom-button/custom-button';
import { dataStore } from '../../common/stores/index';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import { ClearButtonComponent } from 'features/common/components/clear-button-component';
import { localStorageService } from 'shared/services/local-storage-service';
import { userApprovalStore } from '../stores';
import { UserRole, UserTypes } from '../user_approval_constant';
import { AppConstant } from 'app_constant';
import { accountStore } from 'features/account/stores';

interface UserApprovalProps {
    userApprovalStore: UserApprovalDataStore;
}

interface UserApprovalState {
    userInfo: any;
    newRole: string;
}

@observer
export class UserApprovalComponent extends Component<UserApprovalProps, UserApprovalState> {
    api: GridApi | undefined;
    private rowID = 0;
    constructor(props) {
        super(props);
        this.state = {
            userInfo: {},
            newRole: ''
        };
    }

    componentDidMount() {
        this.props.userApprovalStore.init();
    }

    importPSAData = () => {
        const confirmService = new ConfirmService();
        confirmService.showConfirmDialog(
            () => this.props.userApprovalStore.importPSAData({}),
            '',
            'Are you sure you want to import PSA data? This will import all carriers, facilites and user ?'
        );
    };

    render(): React.ReactNode {
        const {
            userApprovalStore: { isSaveDisabled, showModal, showCarriers, userSelected, approvedRole, isDeleteEnabled, deleteTablet }
        } = this.props;
        const { userInfo, newRole } = this.state;
        const allFacility = CommonUtils.dictionaryToObj(this.props.userApprovalStore.allFacilities);
        return (
            <> <Container fluid>
                <Row>
                    <Col>
                        <div className="master-data__action">
                        {(dataStore.checkOperationAccess('Save') || dataStore.getCurrentModuleName() === 'AssignedTickets') && (
                                <>
                                <CustomButton
                                type={CustomButtonType.Submit}
                                onClick={this.handleSave}
                                disabled={isSaveDisabled()}
                                > Save </CustomButton>
                                <CustomButton
                                type={CustomButtonType.Submit}
                                onClick={this.handleReset}
                                disabled={isSaveDisabled()}
                                > Reset </CustomButton>
                                </>
                                )}
                            {dataStore.checkOperationAccess('ImportPSAData') && (
                                <CustomButton type={CustomButtonType.Submit} onClick={this.importPSAData}>
                                    Import PSA Data
                                </CustomButton>
                            )}
                            <ClearButtonComponent handleRefresh={this.handleRefresh} isExportCSV={true}></ClearButtonComponent>
                            <><CustomButton type={CustomButtonType.Submit} onClick={this.handleADUpdate} disabled={!userSelected || userSelected?.DriverType === 'Non Plains Driver'}>
                                AD Group Update
                            </CustomButton></>
                            <><CustomButton type={CustomButtonType.Submit} onClick={this.handleAddCCName} disabled={!accountStore.userRoles.includes('ptssysadmin')}>
                                ADD CARRIER COMPANY
                            </CustomButton></>
                            { this.props.userApprovalStore.emailId.includes('@plains.com') && <CustomButton
                                                type={CustomButtonType.Submit}
                                                onClick={this.deleteTablet}
                                                disabled={isDeleteEnabled()}
                                            >
                                                Deactivate
                                            </CustomButton>
                            }
                        </div>
                        {/* disabled={!accountStore.userRoles.includes('ptssysadmin')} */}
                        <AgGridComponent gridConfig={this.getGridConfig()} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {showModal && this.state.userInfo ? (
                            <CarrierFacilityPopUpComponent
                                onHide={this.handlePopUpClose}
                                listCarrier={this.props.userApprovalStore.allCarrierCompany}
                                listFacility={allFacility}
                                userInfo={this.state.userInfo}
                                saveUser={this.props.userApprovalStore.updateUser.bind(this.props.userApprovalStore)}
                                show={this.props.userApprovalStore.showModal}
                                listPSACarrier={this.props.userApprovalStore.allPSACarrier}
                            ></CarrierFacilityPopUpComponent>
                        ) : null}
                        {showCarriers && this.state.userInfo ? (
                            <CarrierFacilityPopUpComponent
                                onHide={this.handlePopUpClose}
                                listCarrier={this.props.userApprovalStore.allCarrierCompany}
                                listFacility={allFacility}
                                userInfo={this.state.userInfo}
                                saveUser={this.props.userApprovalStore.updateUser.bind(this.props.userApprovalStore)}
                                show={this.props.userApprovalStore.showCarriers}
                                listPSACarrier={this.props.userApprovalStore.allPSACarrier}
                            ></CarrierFacilityPopUpComponent>
                        ) : null}
                    </Col>
                </Row>
            </Container>
            {(this.props.userApprovalStore.showADGroupAlert === 'add' || this.props.userApprovalStore.showADGroupAlert === 'update') &&
                <div className = 'alertBox'> 
                <div className = 'error-message' style={{marginTop: '40vh', width: '700px'}}>
                <div>
                    <div style={{padding: 5}}><b>{userInfo.LoginName}</b> has been Approved for <b>
                        {this.props.userApprovalStore.showADGroupAlert === 'add' ? approvedRole : newRole} </b>.<br/> 
                        1. Note: It can take 1-2 hours for the automated process to provision access for user <b>{userInfo.LoginName}</b>.<br/>
                        2. Once the AD Status has a check mark the user can access the system provided they have set up their Microsoft Authentication.
                    </div> 
                </div>
                <div style={{paddingBottom: 10,paddingLeft: 265}}>
                <CustomButton type={CustomButtonType.Ok} onClick={()=> {
                this.props.userApprovalStore.showADGroupAlert = '';
                this.props.userApprovalStore.approvedRole = '';
                }}> OK </CustomButton> </div>
                </div></div>     
                } </>
        );
    }

    componentDidUpdate() {
        const filters = localStorageService.get('UserApproval_filter');
        this.props.userApprovalStore.agGridService.setAllFilters(filters);
    }

    private handlePopUpClose = (): void => {
        console.log(this.props.userApprovalStore.showModal);
        this.props.userApprovalStore.setShowModal(false);
        this.props.userApprovalStore.setCarriersModal(false);
    };

    private handleSave = (): void => {
        const { userApprovalStore } = this.props;
        userApprovalStore.updateUserDetails();
    };

    private handleReset = (): void => {
        this.props.userApprovalStore.resetUserInfo();
    };

    deleteTablet = (): void => {
        this.props.userApprovalStore.deleteTablet(this.props.userApprovalStore.userSelected);
    }

    private handleADUpdate = (): void => {
        const confirmService = new ConfirmService();
        const { userInfo } = this.state;
        confirmService.showAdUpdatePopup(async role => {
            console.log(role);
            this.setState({newRole: role})
            if (role === 'Driver') {
                userInfo.IsCarrierCompany = false;
                userInfo.IsOwnerOperator = false;
                userInfo.IsCarrierCompanyAdmin = false;
                userInfo.IsCarrierCompanyScheduler = false;
                userInfo.IsDriver = true;
                userInfo.IsThirdPartyUser = false;
            } else if (role === 'Owner/Driver') {
                userInfo.IsCarrierCompany = false;
                userInfo.IsOwnerOperator = true;
                userInfo.IsCarrierCompanyAdmin = false;
                userInfo.IsCarrierCompanyScheduler = false;
                userInfo.IsDriver = true;
                userInfo.IsThirdPartyUser = false;
            }
            else if (role === 'Carrier Company Admin') {
                console.log('Admin');
                userInfo.IsCarrierCompany = false;
                userInfo.IsOwnerOperator = false;
                userInfo.IsCarrierCompanyAdmin = true;
                userInfo.IsCarrierCompanyScheduler = false;
                userInfo.IsDriver = false;
                userInfo.IsThirdPartyUser = false;
            } else if (role === 'Carrier Company Scheduler') {
                console.log('Scheduler');
                userInfo.IsCarrierCompany = false;
                userInfo.IsOwnerOperator = false;
                userInfo.IsCarrierCompanyAdmin = false;
                userInfo.IsCarrierCompanyScheduler = true;
                userInfo.IsDriver = false;
                userInfo.IsThirdPartyUser = false;
            } else if (role === 'Thirdparty Dispatcher') {
                userInfo.IsCarrierCompany = false;
                userInfo.IsOwnerOperator = false;
                userInfo.IsCarrierCompanyAdmin = false;
                userInfo.IsCarrierCompanyScheduler = false;
                userInfo.IsDriver = false;
                userInfo.IsThirdPartyUser = true;
            }
            console.log(userInfo);
            console.log('add clicked')
            if (userInfo.UserType) {
                await this.props.userApprovalStore.deleteUserADGroupDetails({...userInfo, NewUserType: role}, false);
                const updatedData: any = this.props.userApprovalStore.userApprovalInfo.find(x=>x.ID === userInfo.ID)
                userInfo.StatusID = updatedData.StatusID
                userInfo.StatusCode = updatedData.StatusCode
            }
            await this.props.userApprovalStore.updateUserADGroupDetails({...userInfo, NewUserType: role})
            // this.props.saveUser(userInfo);
        },
        role => {
            console.log(role);
            if (role === 'Driver') {
                userInfo.IsCarrierCompany = false;
                userInfo.IsOwnerOperator = false;
                userInfo.IsCarrierCompanyAdmin = false;
                userInfo.IsCarrierCompanyScheduler = false;
                userInfo.IsDriver = true;
                userInfo.IsThirdPartyUser = false;
            } else if (role === 'Owner/Driver') {
                userInfo.IsCarrierCompany = false;
                userInfo.IsOwnerOperator = true;
                userInfo.IsCarrierCompanyAdmin = false;
                userInfo.IsCarrierCompanyScheduler = false;
                userInfo.IsDriver = true;
                userInfo.IsThirdPartyUser = false;
            }
            else if (role === 'Carrier Company Admin') {
                console.log('Admin');
                userInfo.IsCarrierCompany = false;
                userInfo.IsOwnerOperator = false;
                userInfo.IsCarrierCompanyAdmin = true;
                userInfo.IsCarrierCompanyScheduler = false;
                userInfo.IsDriver = false;
                userInfo.IsThirdPartyUser = false;
            } else if (role === 'Carrier Company Scheduler') {
                console.log('Scheduler');
                userInfo.IsCarrierCompany = false;
                userInfo.IsOwnerOperator = false;
                userInfo.IsCarrierCompanyAdmin = false;
                userInfo.IsCarrierCompanyScheduler = true;
                userInfo.IsDriver = false;
                userInfo.IsThirdPartyUser = false;
            } else if (role === 'Thirdparty Dispatcher') {
                userInfo.IsCarrierCompany = false;
                userInfo.IsOwnerOperator = false;
                userInfo.IsCarrierCompanyAdmin = false;
                userInfo.IsCarrierCompanyScheduler = false;
                userInfo.IsDriver = false;
                userInfo.IsThirdPartyUser = true;
            }
            this.props.userApprovalStore.deleteUserADGroupDetails({...userInfo, NewUserType: role}, true);
        },
        UserRole, 
        userInfo.UserType,
        userInfo
    );
    }

    handleRefresh = () => {
        const { userApprovalStore } = this.props;
        if(Object.keys(userApprovalStore.cellValueChangeMap).length === 0) {
            userApprovalStore.init();
            userApprovalStore.cellValueChangeMap = {}
        } 
        else {
            const confirmService = new ConfirmService();
            confirmService.showConfirmDialog(
                () => {
                    userApprovalStore.init();
                    userApprovalStore.cellValueChangeMap = {}
                },
                '',
                AppConstant.REFRESH_CONFIRMATION
            );
        }
    }

    handleAddCCName = () => {
        const { userApprovalStore } = this.props;
        const confirmService = new ConfirmService();
        confirmService.showEmailPopup(ccname => {
            const reqbody = {
                CompanyName: ccname
            };
            userApprovalStore.createCarrierCompany(reqbody);
        },
        {},
        false,
        false,true);
    }

    onRowClicked = (event: RowClickedEvent): void => {
        const { userApprovalStore } = this.props;
        const { ID } = event.node.data;
        this.rowID = ID;
        const userData = userApprovalStore.userApprovalInfo.find(x => x.ID === ID);
        this.setState({ userInfo: userData });
    };

    onCellEditingStarted = (event: CellEditingStartedEvent): void => {
        const row = event.data.ID;
        const col = event.colDef.field || '';
        const value = event.value;
        const {
            userApprovalStore: { cellValueChangeMap }
        } = this.props;
        const isNotEmpty =
            row in cellValueChangeMap && col in cellValueChangeMap[row] && cellValueChangeMap[row][col].initValue;
        if (!isNotEmpty) {
            userApprovalStore.setValueInChangeMap(row, col, value, value);
        }
    };
    onCellEditingStopped = (event: CellEditingStoppedEvent): void => {
       const row = event.data.ID;
        const col = event.colDef.field || '';
        const value = event.value;
        const {
            userApprovalStore: { cellValueChangeMap, updateRow }
        } = this.props;
        cellValueChangeMap[row][col]['currentValue'] = value;
        updateRow(event.data);
    };
    private getGridConfig(): GridOptions {
        const {
            userApprovalStore: { userApprovalInfo, agGridService, getColDef }
        } = this.props;

        const columnDefs = getColDef();
        const onRowClicked = this.onRowClicked;
        const onCellEditingStarted = this.onCellEditingStarted;
        const onCellEditingStopped = this.onCellEditingStopped;
        // const onCellClicked = this.onCellclicked;
        const rowData = userApprovalInfo;
        const userApprovalGridParams: AgGridComponentParams = {
            rowData,
            columnDefs,
            onRowClicked,
            onCellEditingStarted,
            onCellEditingStopped
        };
        return agGridService.getGridConfig(userApprovalGridParams);
    }

    // onCellclicked = event => {
    //     if (event.column.colId == 'SELECTED') {
    //         if (!event.data.IsSelected) {
    //             event.data.IsSelected = !event.data.IsSelected;
    //         } else {
    //             event.data.IsSelected = !event.data.IsSelected;
    //         }
    //     }
    // };
}
