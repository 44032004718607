// import { CustomerReportHeaderName, CustomerReportFieldName, TicketVolOrHourly, TicketStatus } from '../constants/ticket-enum';
import { CustomerReportHeaderName, CustomerReportFieldName } from './enum';
import { TextAreaCellRenderer } from 'shared/components/cell-renderers/text-area-cell-renderer';
import { NumericCellRenderer } from 'shared/components/cell-renderers/numeric-cell-renderer';
import { AlphaNumericCellRenderer } from 'shared/components/cell-renderers/alpha-numeric-cell-renderer';
import { dateUtils } from 'shared/services/date-utils';
import { ColDef, ValueFormatterParams } from 'ag-grid-community';
import moment from 'moment';
import momenttimezone from 'moment-timezone';
import { AppConstant } from 'app_constant';
import { commaFormat, isFloat } from '../reports_constants';
import { CommonUtils } from 'shared/services/common-utils';

const roundOff = value => {
    return (Math.round((value + Number.EPSILON) * 1000) / 1000).toFixed(3);
};

export const Customerreport: ColDef[] = [
    {
        headerName: CustomerReportHeaderName.TICKETDATE,
        field: CustomerReportFieldName.TICKETDATETIME,
        width: 175,
        valueGetter: params => {
            if (params.data[CustomerReportFieldName.TICKETDATETIME] === null) {
                return '';
            } else {
                return dateUtils.getFormattedDateOnly(params.data[CustomerReportFieldName.TICKETDATETIME]);
            }
        }
    },
    {
        headerName: CustomerReportHeaderName.TICKETTIME,
        field: CustomerReportFieldName.TICKETDATETIME,
        width: 70,
        valueGetter: params => {
            if (params.data[CustomerReportFieldName.TICKETDATETIME] === null) {
                return '';
            } else {
                return dateUtils.getFormattedTimeOnly(params.data[CustomerReportFieldName.TICKETDATETIME]);
            }
        }
    },
    {
        headerName: CustomerReportHeaderName.TICKETACCEPTEDDATE,
        field: CustomerReportFieldName.TICKETACCEPTEDDATE,
        width: 130,
        valueGetter: params => {
            if (params.data[CustomerReportFieldName.TICKETACCEPTEDDATE] === null) {
                return '';
            } else {
                return momenttimezone.tz(moment.utc(params.data[CustomerReportFieldName.TICKETACCEPTEDDATE]),AppConstant.MST_TIME_FORMATTER).format(AppConstant.DATE_TIME_FORMAT);
            }
        }
    },
    {
        headerName: CustomerReportHeaderName.LOADDATE,
        field: CustomerReportFieldName.LOADDATETIME,
        width: 100,
        valueGetter: params => {
            if (params.data[CustomerReportFieldName.LOADDATETIME] === null) {
                return '';
            } else {
                return dateUtils.getFormattedDateOnly(params.data[CustomerReportFieldName.LOADDATETIME]);
            }
        }
    },
    {
        headerName: CustomerReportHeaderName.LOADTIME,
        field: CustomerReportFieldName.LOADDATETIME,
        width: 95,
        valueGetter: params => {
            if (params.data[CustomerReportFieldName.LOADDATETIME] === null) {
                return '';
            } else {
                //removing the conversion of UTC to MST as we are receiving the time as MST
                return dateUtils.getFormattedTimeOnly(params.data[CustomerReportFieldName.LOADDATETIME]);
            }
        }
    },
    {
        headerName: CustomerReportHeaderName.COMPLETEDDATE,
        field: CustomerReportFieldName.COMPLETEDDATE,
        width: 105,
        valueGetter: params => {
                return params.data[CustomerReportFieldName.COMPLETEDDATE]
                    ? dateUtils.getFormattedDateOnly(params.data[CustomerReportFieldName.COMPLETEDDATETIME])
                    : '';
            }
    },
    {
        headerName: CustomerReportHeaderName.COMPLETEDTIME,
        field: CustomerReportFieldName.COMPLETEDTIME,
        width: 105,
        valueGetter: params => {
                return params.data[CustomerReportFieldName.COMPLETEDTIME]
                    ? dateUtils.getFormattedTimeOnly(params.data[CustomerReportFieldName.COMPLETEDDATETIME])
                    : '';
            }
    },
    {
        headerName: CustomerReportHeaderName.TICKETNUMBER,
        field: CustomerReportFieldName.TICKETNUMBER,
        width: 80,
        valueGetter: params => {
            if (params.data[CustomerReportFieldName.TICKETNUMBER] === null) {
                return '';
            } else {
                return params.data[CustomerReportFieldName.TICKETNUMBER];
            }
        }
    },
    {
        headerName: CustomerReportHeaderName.TYPE,
        field: CustomerReportFieldName.TYPE,
        width: 60,
        valueGetter: params => {
            if (params.data[CustomerReportFieldName.TYPE] === null) {
                return '';
            } else {
                return params.data[CustomerReportFieldName.TYPE];
            }
        }
    },
    {
        headerName: CustomerReportHeaderName.LEASEOPERATOR,
        field: CustomerReportFieldName.LEASEOPERATOR,
        width: 150,
        valueGetter: params => {
            if (params.data[CustomerReportFieldName.LEASEOPERATOR] === null || params.data[CustomerReportFieldName.LEASEOPERATOR] === 'Dummy Test') {
                return '';
            } else {
                return params.data[CustomerReportFieldName.LEASEOPERATOR];
            }
        }
    },
    {
        headerName: CustomerReportHeaderName.DRIVERNAME,
        field: CustomerReportFieldName.DRIVERNAME,
        width: 120,
        valueGetter: params => {
            if (params.data[CustomerReportFieldName.DRIVERNAME] === null || params.data[CustomerReportFieldName.DRIVERNAME] === 'Dummy Test' || params.data[CustomerReportFieldName.DRIVERNAME].trim() === 'SysUser') {
                return '';
            } else {
                return params.data[CustomerReportFieldName.DRIVERNAME];
            }
        }
    },
    {
        headerName: CustomerReportHeaderName.REGION,
        field: CustomerReportFieldName.REGION,
        width: 80,
        valueGetter: params => {
            if (params.data[CustomerReportFieldName.REGION] === null) {
                return '';
            } else {
                return params.data[CustomerReportFieldName.REGION];
            }
        }
    },
    {
        headerName: CustomerReportHeaderName.TRAILER,
        field: CustomerReportFieldName.TRAILER,
        width: 80,
        valueGetter: params => {
            if (params.data[CustomerReportFieldName.TRAILER] === null) {
                return '';
            } else {
                return params.data[CustomerReportFieldName.TRAILER];
            }
        }
    },
    {
        headerName: CustomerReportHeaderName.VOLUMEDELIVERED,
        field: CustomerReportFieldName.VOLUMEDELIVERED,
        width: 95,
        filter: 'agNumberColumnFilter',
        cellClass: 'decimal-format',
        valueGetter: params => {
            if (params.data[CustomerReportFieldName.VOLUMEDELIVERED] === null) {
                return '';
            } else {
                return roundOff(params.data[CustomerReportFieldName.VOLUMEDELIVERED]);
            }
        },
        valueFormatter: params => {
            return commaFormat(params.value)
        }
    },
    {
        headerName: CustomerReportHeaderName.ORIGIN,
        field: CustomerReportFieldName.ORIGIN,
        width: 100,
        valueGetter: params => {
            if (params.data[CustomerReportFieldName.ORIGIN] === null) {
                return '';
            } else {
                return params.data[CustomerReportFieldName.ORIGIN];
            }
        }
    },
    {
        headerName: CustomerReportHeaderName.DESTINATION,
        field: CustomerReportFieldName.DESTINATION,
        width: 100,
        valueGetter: params => {
            if (params.data[CustomerReportFieldName.DESTINATION] === null) {
                return '';
            } else {
                return params.data[CustomerReportFieldName.DESTINATION];
            }
        }
    },
    {
        headerName: CustomerReportHeaderName.Product,
        field: CustomerReportFieldName.PRODUCT,
        width: 90,
        valueGetter: params => {
            if (params.data[CustomerReportFieldName.PRODUCT] === null) {
                return '';
            } else {
                return params.data[CustomerReportFieldName.PRODUCT];
            }
        }
    },
    {
        headerName: CustomerReportHeaderName.WORKINGTIME,
        field: CustomerReportFieldName.WORKINGTIME,
        colId: CustomerReportFieldName.WORKINGTIME,
        width: 90,
        cellClass: params =>{
            if(isFloat(params.data[CustomerReportFieldName.WORKINGTIME])) return 'comma-format'
            else return 'work-wait'
        },
        filter: 'agNumberColumnFilter',
        valueGetter: params => {
            if (params.data[CustomerReportFieldName.WORKINGTIME] === null) {
                return '';
            } else {
                return params.data[CustomerReportFieldName.WORKINGTIME];
            }
        },
        valueFormatter: params => {
            return commaFormat(params.value)
        }
    },
    {
        headerName: CustomerReportHeaderName.WORKINGTIMEREASON,
        field: CustomerReportFieldName.WORKINGTIMEREASON,
        colId: CustomerReportFieldName.WORKINGTIMEREASON,
        width: 120,
        valueGetter: params => {
            if (params.data[CustomerReportFieldName.WORKINGTIMEREASON] === null) {
                return '';
            } else {
                return params.data[CustomerReportFieldName.WORKINGTIMEREASON];
            }
        }
    },
    {
        headerName: CustomerReportHeaderName.WAITINGTIME,
        field: CustomerReportFieldName.WAITINGTIME,
        colId: CustomerReportFieldName.WAITINGTIME,
        width: 80,
        cellClass: params => {
            if(isFloat(params.data[CustomerReportFieldName.WAITINGTIME])) return 'comma-format'
            else return 'work-wait'
        },
        filter: 'agNumberColumnFilter',
        valueGetter: params => {
            if (params.data[CustomerReportFieldName.WAITINGTIME] === null) {
                return '';
            } else {
                return params.data[CustomerReportFieldName.WAITINGTIME];
            }
        },
        valueFormatter: params => {
            return commaFormat(params.value)
        }
    },
    {
        headerName: CustomerReportHeaderName.WAITINGTIMEREASON,
        field: CustomerReportFieldName.WAITINGTIMEREASON,
        colId: CustomerReportFieldName.WAITINGTIMEREASON,
        width: 110,
        valueGetter: params => {
            if (params.data[CustomerReportFieldName.WAITINGTIMEREASON] === null) {
                return '';
            } else {
                return params.data[CustomerReportFieldName.WAITINGTIMEREASON];
            }
        }
    },
    {
        headerName: CustomerReportHeaderName.COMMENT,
        field: CustomerReportFieldName.COMMENT,
        colId: CustomerReportFieldName.COMMENT,
        width: 100,
        cellRendererFramework: TextAreaCellRenderer,
        cellRendererParams: {
            isDisabled: (item, value) => true
        },
        valueGetter: params => {
            if (params.data[CustomerReportFieldName.COMMENT] === null) {
                return '';
            } else {
                return params.data[CustomerReportFieldName.COMMENT];
            }
        }
    },
    {
        headerName: CustomerReportHeaderName.SHIPPERTICKET,
        field: CustomerReportFieldName.SHIPPERTICKET,
        width: 110,
        valueGetter: params => {
            if (params.data[CustomerReportFieldName.SHIPPERTICKET] === null) {
                return '';
            } else {
                return params.data[CustomerReportFieldName.SHIPPERTICKET];
            }
        }
    },
    {
        headerName: CustomerReportHeaderName.RELATEDVOLTICKET,
        field: CustomerReportFieldName.RELATEDVOLUMETICKET,
        width: 125,
        valueGetter: params => {
            if (params.data[CustomerReportFieldName.RELATEDVOLUMETICKET] === null) {
                return '';
            } else {
                return params.data[CustomerReportFieldName.RELATEDVOLUMETICKET];
            }
        }
    },
    {
        headerName: CustomerReportHeaderName.ROADBAN,
        field: CustomerReportFieldName.ROADBAN,
        width: 90,
        valueGetter: params => {
            if (params.data[CustomerReportFieldName.ROADBAN] === null) {
                return '';
            } else {
                return params.data[CustomerReportFieldName.ROADBAN];
            }
        }
    },

    {
        headerName: CustomerReportHeaderName.VIA,
        field: CustomerReportFieldName.VIA,
        width: 85,
        valueGetter: params => {
            if (params.data[CustomerReportFieldName.VIA] === null) {
                return '';
            } else {
                return params.data[CustomerReportFieldName.VIA];
            }
        }
    },
    {
        headerName: CustomerReportHeaderName.STATUS,
        colId: CustomerReportFieldName.STATUS,
        field: CustomerReportFieldName.STATUS,
        width: 75,
        valueGetter: params => {
            if (params.data[CustomerReportFieldName.STATUS] === null) {
                return '';
            } else {
                return params.data[CustomerReportFieldName.STATUS];
            }
        }
    },
    {
        field: CustomerReportFieldName.APPROVERCOMMENT,
        headerName: CustomerReportHeaderName.APPROVERCOMMENT,
        colId: CustomerReportFieldName.APPROVERCOMMENT,
        width: 100,
        cellRendererFramework: TextAreaCellRenderer,
        cellRendererParams: {
            isDisabled: (item, value) => true
        },
        valueGetter: params => {
            if (params.data[CustomerReportFieldName.APPROVERCOMMENT] === null) {
                return '';
            } else {
                return params.data[CustomerReportFieldName.APPROVERCOMMENT];
            }
        }
    },
    {
        headerName: CustomerReportHeaderName.BSW,
        field: CustomerReportFieldName.BSW,
        width: 60,
        valueGetter: params => {
            if (params.data[CustomerReportFieldName.BSW] === null) {
                return '';
            } else {
                return params.data[CustomerReportFieldName.BSW];
            }
        }
    },
    {
        headerName: CustomerReportHeaderName.DENSITY,
        field: CustomerReportFieldName.DENSITY,
        width: 80,
        valueGetter: params => {
            if (params.data[CustomerReportFieldName.DENSITY] === null) {
                return '';
            } else {
                return params.data[CustomerReportFieldName.DENSITY];
            }
        }
    },
    {
        headerName: CustomerReportHeaderName.GROSSWEIGHT,
        field: CustomerReportFieldName.GROSSWEIGHT,
        width: 75,
        valueGetter: params => {
            if (params.data[CustomerReportFieldName.GROSSWEIGHT] === null) {
                return '';
            } else {
                return params.data[CustomerReportFieldName.GROSSWEIGHT];
            }
        }
    },
    {
        headerName: CustomerReportHeaderName.TAREWEIGHT,
        field: CustomerReportFieldName.TAREWEIGHT,
        width: 75,
        valueGetter: params => {
            if (params.data[CustomerReportFieldName.TAREWEIGHT] === null) {
                return '';
            } else {
                return params.data[CustomerReportFieldName.TAREWEIGHT];
            }
        }
    },
    {
        headerName: CustomerReportHeaderName.NETWEIGHT,
        field: CustomerReportFieldName.NETWEIGHT,
        width: 75,
        valueGetter: params => {
            if (params.data[CustomerReportFieldName.NETWEIGHT] === null) {
                return '';
            } else {
                return params.data[CustomerReportFieldName.NETWEIGHT];
            }
        }
    },
    {
        headerName: CustomerReportHeaderName.METERFACTOR,
        field: CustomerReportFieldName.METERFACTOR,
        width: 80,
        valueGetter: params => {
            if (params.data[CustomerReportFieldName.METERFACTOR] === null) {
                return '';
            } else {
                return params.data[CustomerReportFieldName.METERFACTOR];
            }
        }
    },
    {
        headerName: CustomerReportHeaderName.METERNUMBER,
        field: CustomerReportFieldName.METERNUMBER,
        width: 80,
        valueGetter: params => {
            if (params.data[CustomerReportFieldName.METERNUMBER] === null) {
                return '';
            } else {
                return params.data[CustomerReportFieldName.METERNUMBER];
            }
        }
    },
    {
        headerName: CustomerReportHeaderName.OPENMETERREADING,
        field: CustomerReportFieldName.OPENMETERREADING,
        width: 110,
        valueGetter: params => {
            if (params.data[CustomerReportFieldName.OPENMETERREADING] === null) {
                return '';
            } else {
                return params.data[CustomerReportFieldName.OPENMETERREADING];
            }
        }
    },
    {
        headerName: CustomerReportHeaderName.CLOSEMETERREADING,
        field: CustomerReportFieldName.CLOSEMETERREADING,
        width: 115,
        valueGetter: params => {
            if (params.data[CustomerReportFieldName.CLOSEMETERREADING] === null) {
                return '';
            } else {
                return params.data[CustomerReportFieldName.CLOSEMETERREADING];
            }
        }
    },
    {
        headerName: CustomerReportHeaderName.POWERUNITID,
        field: CustomerReportFieldName.POWERUNITID,
        width: 105,
        valueGetter: params => {
            if (params.data[CustomerReportFieldName.POWERUNITID] === null) {
                return '';
            } else {
                return params.data[CustomerReportFieldName.POWERUNITID];
            }
        }
    },
    {
        headerName: CustomerReportHeaderName.AXLES,
        field: CustomerReportFieldName.AXLES,
        width: 85,
        valueGetter: params => {
            if (params.data[CustomerReportFieldName.AXLES] === null) {
                return '';
            } else {
                return params.data[CustomerReportFieldName.AXLES];
            }
        }
    },
    {
        headerName: CustomerReportHeaderName.STARTDATE,
        field: CustomerReportFieldName.STARTDATETIME,
        width: 75,
        valueGetter: params => {
            if (params.data[CustomerReportFieldName.STARTDATETIME] === null) {
                return '';
            } else {
                return dateUtils.getFormattedDateOnly(params.data[CustomerReportFieldName.STARTDATETIME]);
            }
        }
    },
    {
        headerName: CustomerReportHeaderName.STARTTIME,
        field: CustomerReportFieldName.STARTDATETIME,
        width: 70,
        valueGetter: params => {
            if (params.data[CustomerReportFieldName.STARTDATETIME] === null) {
                return '';
            } else {
                return dateUtils.getFormattedTimeOnly(params.data[CustomerReportFieldName.STARTDATETIME]);
            }
        }
    },
    {
        headerName: CustomerReportHeaderName.ENDDATE,
        field: CustomerReportFieldName.ENDDATE,
        width: 100,
        valueGetter: params => {
            if (params.data[CustomerReportFieldName.ENDDATE] === null) {
                return '';
            } else {
                return dateUtils.getFormattedDateTime(params.data[CustomerReportFieldName.ENDDATE]);
            }
        }
    },
    {
        headerName: CustomerReportHeaderName.UNITS,
        field: CustomerReportFieldName.UNITS,
        width: 65,
        valueGetter: params => {
            if (params.data[CustomerReportFieldName.UNITS] === null) {
                return '';
            } else {
                return params.data[CustomerReportFieldName.UNITS];
            }
        }
    },
    {
        headerName: CustomerReportHeaderName.RUNTICKET,
        field: CustomerReportFieldName.RUNTICKET,
        width: 150,
        valueGetter: params => {
            if (params.data[CustomerReportFieldName.RUNTICKET] === null) {
                return '';
            } else {
                return params.data[CustomerReportFieldName.RUNTICKET];
            }
        }
    },
    {
        headerName: CustomerReportHeaderName.CREATEDBY,
        field: CustomerReportFieldName.CREATEDBY,
        width: 105,
        valueGetter: params => {
            if (params.data[CustomerReportFieldName.CREATEDBY] === null) {
                return '';
            } else {
                return params.data[CustomerReportFieldName.CREATEDBY];
            }
        }
    },
    {
        headerName: CustomerReportHeaderName.AUTHORIZEDBY,
        field: CustomerReportFieldName.AUTHORIZEDBY,
        width: 110,
        valueGetter: params => {
            if (params.data[CustomerReportFieldName.AUTHORIZEDBY] === null) {
                return '';
            } else {
                return params.data[CustomerReportFieldName.AUTHORIZEDBY];
            }
        }
    },
    {
        headerName: CustomerReportHeaderName.CONSIGNERADDRESS,
        field: CustomerReportFieldName.CONSIGNERADDRESS,
        width: 105,
        valueGetter: params => {
            if (params.data[CustomerReportFieldName.CONSIGNERADDRESS] === null) {
                return '';
            } else {
                return params.data[CustomerReportFieldName.CONSIGNERADDRESS];
            }
        }
    },
    {
        headerName: CustomerReportHeaderName.CONSIGNEORNUMBER,
        field: CustomerReportFieldName.CONSIGNEORNUMBER,
        width: 130,
        valueGetter: params => {
            if (params.data[CustomerReportFieldName.CONSIGNEORNUMBER] === null || params.data[CustomerReportFieldName.CONSIGNEORNUMBER] == 0) {
                return '';
            } else {
                return params.data[CustomerReportFieldName.CONSIGNEORNUMBER].toString().replace(CommonUtils.getCellPhoneRegex(), "($1) $2 $3");
            }
        }
    },
    {
        headerName: CustomerReportHeaderName.CONSIGNORERPPLANNUMBER,
        field: CustomerReportFieldName.CONSIGNORERPPLANNUMBER,
        width: 130,
        valueGetter: params => {
            if (params.data[CustomerReportFieldName.CONSIGNORERPPLANNUMBER] === null) {
                return '';
            } else {
                return params.data[CustomerReportFieldName.CONSIGNORERPPLANNUMBER];
            }
        }
    }
];
